import React, { useEffect, useState } from "react"
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  Divider,
  Switch,
  message,
  Upload,
  Image,
  Radio,
  Select
} from "antd"
import { LeftOutlined, InboxOutlined } from "@ant-design/icons"
import { UserText } from "../../../../styledComponents"
import { useNavigate } from "react-router-dom"
import { addItem, addUser, fetchCategories } from "../../../../api/DataService"
import { CategoryOptions } from "../../../../constants/ConstantOption"
const { Dragger } = Upload

const AddItem = () => {
  const [form] = Form.useForm()
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    fetchCategories().then(({ data: res }) => {
      if (res?.status === 200) {
        const categories = res?.data.map(({ id, name }) => ({
          label: name,
          value: id
        }))
        setCategories(categories)
      }
    })
  }, [])

  const handleSubmit = values => {
    const formData = new FormData()
    image && formData.append("image", image)
    formData.append("name", values.name)
    formData.append("category", values.category)
    addItem(formData).then(({ data: res, error }) => {
      if (res?.status === 201) {
        message.success(res?.message)
        navigate("/item-listing")
        return
      }

      if (error) {
        Object.entries(error).forEach(([key, value]) => {
          message.error(value)
        })
      }
    })
  }

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload(file) {
      const fileType = file.type
      if (
        fileType !== "image/png" &&
        fileType !== "image/jpg" &&
        fileType !== "image/jpeg"
      ) {
        message.error("File should be of type PNG, JPG, or JPEG")
        return
      }
      setImage(file)
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    }
  }

  return (
    <div className="w-100" style={{ margin: 0 }}>
      <Breadcrumb className="m-2 mx-3" style={{ margin: 0 }}>
        <Breadcrumb.Item>Items</Breadcrumb.Item>
        <Breadcrumb.Item>Add New Item</Breadcrumb.Item>
      </Breadcrumb>
      <Divider style={{ margin: 0 }} />
      <div>
        <div className="d-flex align-items-center m-3 gap-2">
          <LeftOutlined onClick={() => navigate("/item-listing")} />
          <h4 style={{ margin: 0 }}>Add New Item</h4>
        </div>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <div
            className="d-flex flex-column gap-3 p-4"
            style={{ backgroundColor: "#F7F8F9" }}
          >
            <div className="d-flex">
              <div style={{ flexBasis: "40%" }}>
                <h5>Item Details</h5>
                <UserText>Add details about the Items</UserText>
              </div>
              <div
                style={{ flexBasis: "50%" }}
                className="d-flex flex-column gap-3"
              >
                <Form.Item
                  name="image"
                  label="Upload Picture"
                  style={{ margin: 0 }}
                >
                  <Dragger {...props} className="w-100">
                    {image ? (
                      <Image
                        src={URL.createObjectURL(image)}
                        style={{ maxWidth: "249px", maxHeight: "140px" }}
                        preview={false}
                      />
                    ) : (
                      <>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <div className="ant-upload-text">
                          Click or drag file to this area to upload
                        </div>
                        <div className="ant-upload-hint">
                          Support for a single file upload. Can be PNG, JPEG,
                          JPG
                        </div>
                      </>
                    )}
                  </Dragger>
                </Form.Item>
                <Form.Item
                  name="category"
                  label="Item Category"
                  style={{ margin: 0 }}
                  rules={[
                    { required: true, message: "Please select your category!" }
                  ]}
                >
                  <Select placeholder="Select Category" options={categories}/>
                </Form.Item>
                <Form.Item
                  name="name"
                  label="Item Name"
                  style={{ margin: 0 }}
                  rules={[
                    { required: true, message: "Please input your name!" }
                  ]}
                >
                  <Input placeholder="Enter name" maxLength={255}/>
                </Form.Item>
              </div>
            </div>
          </div>
          <Form.Item
            className="d-flex justify-content-end m-2"
            style={{ margin: 0 }}
          >
            <Button
              className="ms-2"
              type="default"
              htmlType="button"
              onClick={() => navigate("/item-listing")}
            >
              Cancel
            </Button>
            <Button className="ms-2" type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default AddItem
