import { Form, Image, InputNumber, Select, Space, message } from "antd"
import { navigate, useNavigate } from "react-router-dom"
import { Input, Button } from "antd"
import { UserText } from "../../../styledComponents"
import { editUser, getSingleUser, items } from "../../../api/DataService"
import DynamicFieldPrefCard from "../../../components/DynamicFieldPrefCard"
import Dragger from "antd/es/upload/Dragger"
import { InboxOutlined } from "@ant-design/icons"
import { useContext, useEffect, useState } from "react"
import {
  Countries,
  getCountryLabelAndCode
} from "../../../constants/CountryCodes"
import GlobalContext from "../../../components/GlobalContext"

const ProfileSetting = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { setProfilePicture } = useContext(GlobalContext)
  const [image, setImage] = useState(null)
  const [selectCountryCode, setSelectCountryCode] =
    useState("United States_US_1")
  const updatedCountryCode = Countries.map(country => ({
    label: country.value,
    value: `${country.label}_${country.value}_${country.phoneCode}`
  }))
  const userInfo = JSON.parse(localStorage.getItem("user"))

  const handleSubmit = values => {
    const formData = new FormData()
    image &&
      typeof image == "object" &&
      formData.append("profile_picture", image)
    values.name && formData.append("name", values.name)
    values.designation && formData.append("designation", values.designation)
    values.phone_number &&
      formData.append(
        "phone_number",
        `${selectCountryCode.split("_")[1]}_${selectCountryCode.split("_")[2]}_${values.phone_number}`
      )
    values.organization_name &&
      formData.append("organization_name", values.organization_name)
    formData.append("role", userInfo.role)
    formData.append("is_active", true)

    editUser(userInfo.id, formData).then(({ data: res, error }) => {
      if (res?.status === 200) {
        message.success(res?.message)
        localStorage.setItem("user", JSON.stringify(res?.data?.user))
        return
      }

      if (error) {
        Object.entries(error).forEach(([key, value]) => {
          if (key === "message") message.error(value)
        })
      }
    })

    getSingleUser(userInfo?.id).then(({ data: res }) => {
      setProfilePicture(res?.data?.profile_picture)
    })
  }

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload(file) {
      const fileType = file.type
      if (
        fileType !== "image/png" &&
        fileType !== "image/jpg" &&
        fileType !== "image/jpeg"
      ) {
        message.error("File should be of type PNG, JPG, or JPEG")
        return
      }
      setImage(file)
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    }
  }

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user"))
    getSingleUser(userInfo?.id).then(({ data: res }) => {
      setSelectCountryCode(
        getCountryLabelAndCode(
          res?.data?.phone_number?.split("_")[0] || "US",
          res?.data?.phone_number?.split("_")[1] || "1"
        )
      )
      form.setFieldsValue({
        profile_picture: res?.data?.profile_picture,
        name: res?.data?.name,
        designation: res?.data?.designation,
        phone_number: res?.data?.phone_number?.split("_")[2],
        organization_name: res?.data?.organization_name
      })
      setImage(res?.data?.profile_picture)
    })
  }, [])

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          profile_picture: "",
          name: "",
          designation: "",
          phone_number: "",
          organization_name: ""
        }}
      >
        <div
          className="d-flex flex-column gap-3 p-4"
          style={{ backgroundColor: "#F7F8F9" }}
        >
          <div className="d-flex mb-4">
            <div style={{ flexBasis: "40%" }}>
              <h5>Profile Settings</h5>
              <UserText>Add details about yourself</UserText>
            </div>
            <div
              style={{ flexBasis: "50%" }}
              className="d-flex flex-column gap-3"
            >
              <Form.Item
                name="profile_picture"
                label="Upload Picture (Optional)"
                style={{ margin: 0 }}
              >
                <Dragger {...props} className="w-100">
                  {typeof image === "string" ? (
                    <Image
                      src={image}
                      style={{ maxWidth: "249px", maxHeight: "140px" }}
                      preview={false}
                    />
                  ) : image && typeof image === "object" ? (
                    <Image
                      src={URL.createObjectURL(image)}
                      style={{ maxWidth: "249px", maxHeight: "140px" }}
                      preview={false}
                    />
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <div className="ant-upload-text">
                        Click or drag file to this area to upload
                      </div>
                      <div className="ant-upload-hint">
                        Support for a single file upload. Can be PNG, JPEG, JPG
                      </div>
                    </>
                  )}
                </Dragger>
              </Form.Item>
              <Form.Item
                name="name"
                label="Full Name"
                style={{ margin: 0 }}
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input placeholder="Enter name" maxLength={255}/>
              </Form.Item>
              <Form.Item
                name="designation"
                label="Professional Title"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Please input professional title!"
                  }
                ]}
              >
                <Input placeholder="Enter professional title" maxLength={255}/>
              </Form.Item>
              <Form.Item
                name="phone_number"
                label="Phone Number"
                style={{ margin: 0 }}
              >
                <InputNumber
                  addonBefore={
                    <Select
                      value={selectCountryCode}
                      options={updatedCountryCode}
                      style={{ width: 100 }}
                      onChange={e => setSelectCountryCode(e)}
                      showSearch
                    />
                  }
                  placeholder="Enter your phone number"
                  type="number"
                  controls={false}
                  className="w-100"
                />
              </Form.Item>
              <Form.Item
                name="organization_name"
                label="Organization Name"
                style={{ margin: 0 }}
              >
                <Input placeholder="Enter organization name" maxLength={255}/>
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item
          className="d-flex justify-content-end m-2"
          style={{ margin: 0 }}
        >
          <Button className="ms-2" type="primary" htmlType="submit">
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ProfileSetting
