import { Breadcrumb, Button, Divider, Image } from "antd"
import {
  LeftOutlined,
  DownloadOutlined,
  EditOutlined,
  MoreOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons"
import { Heading, PrefCardHeading } from "../styledComponents"
import { detailColumns } from "../constants/Headers"
import CustomTable from "../components/CustomTable"
import DefaulImage from "../assets/DefaultImage.png"
import { SubHeading } from "./nurse/generalCard/UserProfileDetail"

const PDFFileBulk = ({ targetRef, prefCardData }) => {
  return (
    <div
      className="w-100 mt-5"
      ref={targetRef}
      style={{ position: "fixed", top: "120vh" }}
    >
      {prefCardData?.map((data, index) => (
        <div key={index}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center m-3 gap-2">
              <h4 style={{ margin: 0 }}>{data?.description}</h4>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ flexBasis: "10%" }}>
              <Image
                className="rounded-circle px-3"
                src={data?.owner_image || DefaulImage}
                preview={false}
              />
            </div>
            <div className="d-flex" style={{ flexBasis: "90%" }}>
              <div style={{ flexBasis: "50%" }}>
                <PrefCardHeading>
                  {data?.owner_designation || "---"}
                </PrefCardHeading>
                <div className="mb-3">{data?.owner_name || "---"}</div>
                <PrefCardHeading>Glove Size</PrefCardHeading>
                <div>{data?.glove_size || "---"}</div>
              </div>
              <div style={{ flexBasis: "50%" }}>
                <PrefCardHeading>Position</PrefCardHeading>
                <div className="mb-3">{data?.position || "---"}</div>
                <PrefCardHeading>Created On</PrefCardHeading>
                <div>{data?.create_at || "---"}</div>
              </div>
            </div>
          </div>
          <div className="bg-light mt-3">
            <CustomTable
              tableHeading="Equipments"
              columns={detailColumns("equipment_name")}
              data={data?.equipments}
            />
            <CustomTable
              tableHeading="Instruments"
              columns={detailColumns("instrument_name")}
              data={data?.instruments}
            />
            <CustomTable
              tableHeading="Supplies"
              columns={detailColumns("supply_name")}
              data={data?.supplies}
            />
            <CustomTable
              tableHeading="Medications"
              columns={detailColumns("medication_name")}
              data={data?.medications}
            />
            <CustomTable
              tableHeading="Suture"
              columns={detailColumns("suture_name")}
              data={data?.sutures}
            />
            <CustomTable
              tableHeading="Dressings"
              columns={detailColumns("dressing_name")}
              data={data?.dressings}
            />
            <CustomTable
              tableHeading="Special Requirements"
              columns={detailColumns("special_requirement_name")}
              data={data?.special_requirements}
            />
            <div className="p-3">
              <Heading className="bg-white p-3">Notes</Heading>
              <SubHeading
                className="p-3"
                style={{ backgroundColor: "#F5F8FE" }}
              >
                Description
              </SubHeading>
              <div className="bg-white p-3">{data?.notes}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PDFFileBulk
