import { Form, InputNumber } from "antd"
import styled from "styled-components"

export const Text = styled.div`
  color: ${props => props.color || "inherit"};
  font-family: Inter, Arial;
  font-size: 14px;
  font-weight: ${props => props.fontWeight || 500};
  line-height: 20px;
  text-align: left;
`

export const Heading = styled.h1`
  font-size: 24px;
`

export const Description = styled.p`
  font-family: Inter, Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${props => props.color || "#FFFFFF"};
`

export const Card = styled.div`
  background: ${props => props.bgColor || "transparent"};
  color: white;
`

export const Header = styled.header`
  background: url(${props => props.bgColor}) no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  display: block;
  @media screen and (max-width: 768px) {
    display: ${props => props.hideHeader || "block"};
  }
`

export const BlurOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  padding: 40px;
  gap: 10px;
  border-radius: 20px;
  position: absolute;
  bottom: 50px;
  margin: 20px;
`

export const AuthDescription = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #1b2b4b;
  margin: ${props => props.margin || "initial"};
`

export const AuthFormItem = styled(Form.Item)`
  & label {
    font-family: Inter;
    font-size: 12px !important;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #2f3e5b !important;
  }
`

export const PrefCardHeading = styled.div`
  font-family: Inter;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #2f3e5b !important;
`

export const AuthField = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
  color: #47546d;
  margin: 0;
`

export const UserText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #47546d;
  margin: 0;
`

export const CapsuleTab = styled.div`
  display: inline-block;
  background-color: ${props => props.bgColor || "transparent"};
  color: ${props => props.color || "#000000"};
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`

export const PackageHeading = styled.div`
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #1b2b4b;
`

export const PackageTopHeading = styled.div`
  font-family: Inter, Arial;
  font-size: 16px;
  font-weight: ${props => props.fontWeight || 600};
  line-height: 20px;
  letter-spacing: 0em;
  color: #2968dc;
`
export const PackageDescription = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #646f84;
`
export const PackageCapsule = styled.div`
  width: 71px;
  height: 24px;
  padding: 0px 8px 2px 8px;
  gap: 0px;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  opacity: 0px;
`
export const PackageName = styled.div`
  font-family: Inter;
  font-size: 48px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
`
export const PackageDetail = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`
export const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: center;
  }
  .ant-input-number-group-addon {
    padding: 0;
  }
`
export const NotificationWraper = styled.div`
  height: 70vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #BBB;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
