import DeleteModal from "../../../../components/DeleteModal"
import { Button, Card, Dropdown, Input, Pagination, message } from "antd"
import {
  PlusOutlined,
  SearchOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons"
import { Link, useNavigate } from "react-router-dom"
import { getTemplateList } from "../../../../api/DataService"
import { deleteMyPreferenceCard } from "../../../../api/DataService"
import { useEffect, useState } from "react"
import { AuthDescription, UserText } from "../../../../styledComponents"
import styled from "styled-components"

const TemplateListing = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [listData, setListData] = useState([])
  const [loader, setLoader] = useState(false)
  const [deleteModalValues, setDeleteModalValues] = useState()
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)

  const items = (item) => [
    {
      label: (
        <div onClick={() => navigate("/edit-template", { state: item.id })} className="d-flex gap-2 me-4">
          <EditOutlined />
          Edit
        </div>
      ),
      key: "0"
    },
    {
      label: (
        <div style={{color: "#D92D20"}} onClick={() => {setIsModalOpen(true); setDeleteModalValues(item);}} className="d-flex gap-2 me-4">
          <DeleteOutlined />
          Delete
        </div>
      ),
      key: "1"
    }
  ]

  useEffect(() => {
    let timeoutId
    const delay = 500

    const fetchData = () => {
      setLoader(true)
      getTemplateList(searchText, currentPage).then(({ data: res, error }) => {
        setListData(res?.data?.results)
        setCount(res?.data?.count)
        setLoader(false)
        if(error) message.error(error?.message)
      })
    }

    const debounceFetchData = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(fetchData, delay)
    }

    debounceFetchData()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchText])

  const deleteMyPreferenceCardHandler = () => {
    deleteMyPreferenceCard([deleteModalValues.id]).then(
      ({ data: res }) => {
        message.success("Preference Card deleted successfully")
        setIsModalOpen(false)
        getTemplateList(searchText).then(({ data: res }) => {
          setListData(res?.data?.results)
          setCount(res?.data?.count)
        })
      }
    )
  }

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        deleteHandler={deleteMyPreferenceCardHandler}
        headingText="Are you sure, you want to Delete this record?"
        descText="By deleting this data, you’ll no longer be able to access it"
      />
      <div className="m-4 flex-grow-1">
        <h3>Templates</h3>
        <UserText className="mb-4">
          Ready to elevate your work with the best saved ready to use templates.
        </UserText>
        <div className="d-flex justify-content-between mb-3">
          <Input
            placeholder="Search Templates..."
            className="w-50"
            prefix={<SearchOutlined />}
            onChange={e => setSearchText(e.target.value)}
            allowClear
          />
        </div>
        <div className="d-flex gap-3 flex-wrap">
          {listData?.map(item => (
            <Card style={{ minWidth: "378px" }}>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <TemplateDate>{item.create_at}</TemplateDate>
                  <div className="d-flex gap-2 align-items-center">
                    <UseCardText
                      onClick={() =>
                        navigate("/use-template", { state: item.id })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Use as Card
                    </UseCardText>
                    <Dropdown
                      menu={{
                        items: items(item)
                      }}
                      trigger={["click"]}
                    >
                      <Button
                        className="d-flex align-items-center border-0"
                        onClick={() => setIsDropDownOpen(prev => !prev)}
                      >
                        <MoreOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                </div>
                <TemplateHeading>{item.draft_name}</TemplateHeading>
                <TemplateSubHeading>{item.procedure_name}</TemplateSubHeading>
                <TemplateDescription>{item.description}</TemplateDescription>
              </div>
            </Card>
          ))}
        </div>
        <Pagination
          className="text-end mt-3"
          total={count}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={10}
          onChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  )
}

export const UseCardText = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #1d57c5;
`

export const TemplateDate = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #646f84;
`

export const TemplateHeading = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0d1e40;
`

export const TemplateSubHeading = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #2f3e5b;
`

export const TemplateDescription = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646f84;
`
export default TemplateListing
