import React, { useEffect, useState } from "react"
import DeleteModal from "../../../../components/DeleteModal"
import { useNavigate } from "react-router-dom"
import {
  deleteItem,
  fetchCategories,
  getItemList
} from "../../../../api/DataService"
import { Button, Pagination, Select, Table, message, Input } from "antd"
import { PlusOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons"
import { itemListingColumns } from "../../../../constants/Headers"
import { CategoryOptions } from "../../../../constants/ConstantOption"

const ItemListing = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [category, setCategory] = useState("")
  const [listData, setListData] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [deleteModalValues, setDeleteModalValues] = useState()
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)

  useEffect(() => {
    let timeoutId
    const delay = 500

    const fetchData = () => {
      setLoading(true)
      getItemList(searchText, category, currentPage).then(({ data: res, error }) => {
        setListData(res?.data?.results)
        setCount(res?.data?.count)
        setLoading(false)
        if(error) message.error(error?.message)
      })
    }

    const debounceFetchData = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(fetchData, delay)
    }

    debounceFetchData()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchText, category, currentPage])

  useEffect(() => {
    fetchCategories().then(({ data: res }) => {
      if (res?.status === 200) {
        const categories = res?.data.map(({ id, name }) => ({
          label: name,
          value: id
        }))
        setCategories(categories)
      }
    })
  }, [])

  const handleEdit = record => {
    navigate("/edit-item", { state: record })
  }

  const handleDelete = record => {
    setDeleteModalValues(record)
    setIsModalOpen(true)
  }

  const deleteItemHandler = deleteRecordIds => {
    deleteItem(deleteRecordIds).then(({ data: res, error }) => {
      setIsModalOpen(false)
      if(error) {
        message.error(error.message || error.detail)
        return
      }
      message.success("Item deleted successfully")
      getItemList(searchText, category, currentPage).then(({ data: res }) => {
        setListData(res?.data?.results)
        setCount(res?.data?.count)
      })
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        deleteHandler={() =>
          selectedRowKeys.length > 0
            ? deleteItemHandler(selectedRowKeys)
            : deleteItemHandler([deleteModalValues.id])
        }
        headingText="Are you sure, you want to Delete this record?"
        descText="By deleting this data, you’ll no longer be able to access it"
      />
      <div className="m-4 flex-grow-1">
        <h3 className="mb-4">Items</h3>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex gap-3">
            <Input
              placeholder="Search list..."
              prefix={<SearchOutlined />}
              onChange={e => setSearchText(e.target.value)}
              allowClear
            />
            <Select
              className="w-100"
              placeholder="Select Item..."
              options={categories}
              onChange={e => setCategory(e)}
              allowClear
            />
          </div>
          <div className="d-flex gap-2">
            {selectedRowKeys.length > 0 && (
              <Button
                size="medium"
                icon={<DeleteOutlined />}
                style={{ color: "#D92D20" }}
                onClick={() => setIsModalOpen(true)}
              >
                Delete All
              </Button>
            )}
            <Button
              size="medium"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/add-item")}
            >
              Add a New Item
            </Button>
          </div>
        </div>
        <Table
          columns={itemListingColumns(handleEdit, handleDelete)}
          dataSource={listData}
          pagination={false}
          loading={loading}
          rowSelection={rowSelection}
          rowKey="id"
        />
        <Pagination
          className="text-end mt-3"
          total={count}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={10}
          onChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  )
}

export default ItemListing
