import React from "react"
import { Input, InputNumber, Button } from "antd"
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { StyledInputNumber } from "../styledComponents"

const QuantityInput = ({ value = 1, onChange }) => {
  const handleIncrement = () => {
    onChange(value < 1 ? 1 : value + 1)
  }

  const handleDecrement = () => {
    onChange(value > 1 ? value - 1 : value)
  }

  return (
    <>
      <StyledInputNumber
        className="bg-white rounded-1"
        value={value}
        onChange={onChange}
        addonBefore={
          <div
            onClick={handleIncrement}
            style={{ padding: "0px 11px 0px 11px" }}
          >
            <PlusOutlined style={{ width: "16px" }} />
          </div>
        }
        addonAfter={
          <div
            onClick={handleDecrement}
            style={{ padding: "0px 11px 0px 11px" }}
          >
            <MinusOutlined style={{ width: "16px" }} />
          </div>
        }
        controls={false}
      />
    </>
  )
}

export default QuantityInput
