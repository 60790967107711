export const settingTabMenu = [
  {
    label: "Profile Settings",
    key: "profile"
  },
  {
    label: "Password & Security",
    key: "password"
  },
  {
    label: "Notifications",
    key: "notifications"
  }
]
