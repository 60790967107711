import React from "react"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

const TermsAndConditions = () => {
  return (
    <div className="m-5 p-5">
      <div>
        <div className="mb-4">
          <strong>
            <h2 className="fw-bold">TERMS AND CONDITIONS</h2>
          </strong>
        </div>
        <div className="text-muted mb-5">
          <strong>Last updated </strong>
          <strong>May 22, 2024</strong>
        </div>
        <div>
          <h5 classsName="fw-bold">
            <strong>AGREEMENT TO OUR LEGAL TERMS</strong>
          </h5>
        </div>
      </div>
      <div className="text-muted mb-3">
        <div data-custom-class="body_text">
          We are Surgisync ("<strong>Company</strong>," "<strong>we</strong>
          ," "<strong>us</strong>," "<strong>our</strong>"), a company
          registered in Texas, United States at 9902 Briar Patch, San Antonio
          <span data-custom-class="body_text">
            <span data-custom-class="body_text">, TX 78254</span>
          </span>
          .
        </div>
      </div>
      <div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We operate the website{" "}
          <a
            className="text-decoration-none"
            href="https://eg-app-46443-staging.botics.co"
            target="_blank"
            rel="noopener"
            data-custom-class="link"
          >
            https://eg-app-46443-staging.botics.co
          </a>{" "}
          (the "<strong>Site</strong>"), as well as any other related products
          and services that refer or link to these legal terms (the "
          <strong>Legal Terms</strong>") (collectively, the "
          <strong>Services</strong>").
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          You can contact us by phone at (+1)2108853614, email at
          efrengarcia@hotmail.com, or by mail to 9902 Briar Patch, San Antonio,
          TX 78254,&nbsp;United States.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity ("
          <strong>you</strong>"), and Surgisync, concerning your access to and
          use of the Services. You agree that by accessing the Services, you
          have read, understood, and agreed to be bound by all of these Legal
          Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
          EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
          USE IMMEDIATELY.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated
          herein by reference. We reserve the right, in our sole discretion, to
          make changes or modifications to these Legal Terms at any time and for
          any reason. We will alert you about any changes by updating the "Last
          updated" date of these Legal Terms, and you waive any right to receive
          specific notice of each such change. It is your responsibility to
          periodically review these Legal Terms to stay informed of updates. You
          will be subject to, and will be deemed to have been made aware of and
          to have accepted, the changes in any revised Legal Terms by your
          continued use of the Services after the date such revised Legal Terms
          are posted.
        </div>
      </div>
      <div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          The Services are intended for users who are at least 18 years old.
          Persons under the age of 18 are not permitted to use or register for
          the Services.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We recommend that you print a copy of these Legal Terms for your
          records.
        </div>
        <div className="mt-5 mb-4">
          <h5 classsName="fw-bold">
            <strong>TABLE OF CONTENTS</strong>
          </h5>
        </div>
        <div className="mb-1">
          <a className="text-decoration-none" href="#services">
            <span data-custom-class="link">
              <span data-custom-class="body_text">1. OUR SERVICES</span>
            </span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#ip"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">
              2. INTELLECTUAL PROPERTY RIGHTS
            </span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#userreps"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">3. USER REPRESENTATIONS</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#userreg"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">4. USER REGISTRATION</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#subscriptions"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">5. SUBSCRIPTIONS</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#prohibited"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">6. PROHIBITED ACTIVITIES</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#ugc"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">
              7. USER GENERATED CONTRIBUTIONS
            </span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#license"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">8. CONTRIBUTION LICENSE</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#advertisers"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">9. ADVERTISERS</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#sitemanage"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">10. SERVICES MANAGEMENT</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#copyrightyes"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">
              11. COPYRIGHT INFRINGEMENTS
            </span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#terms"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">12. TERM AND TERMINATION</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#modifications"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">
              13. MODIFICATIONS AND INTERRUPTIONS
            </span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#law"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">14. GOVERNING LAW</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#disputes"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">15. DISPUTE RESOLUTION</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#corrections"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">16. CORRECTIONS</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#disclaimer"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">17. DISCLAIMER</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#liability"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">
              18. LIMITATIONS OF LIABILITY
            </span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#indemnification"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">19. INDEMNIFICATION</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#userdata"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">20. USER DATA</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#electronic"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">
              21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#california"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">
              22. CALIFORNIA USERS AND RESIDENTS
            </span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#misc"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">23. MISCELLANEOUS</span>
          </a>
        </div>
        <div className="mb-1">
          <a
            className="text-decoration-none"
            href="#contact"
            data-custom-class="link"
          >
            <span data-custom-class="body_text">24. CONTACT US</span>
          </a>
        </div>
      </div>
      <div>
        <div className="mt-5 mb-4" id="services">
          <h5 classsName="fw-bold">
            <strong>1. OUR SERVICES</strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. Accordingly, those persons who
          choose to access the Services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws,
          if and to the extent local laws are applicable.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          The Services are not tailored to comply with industry-specific
          regulations (Health Insurance Portability and Accountability Act
          (HIPAA), Federal Information Security Management Act (FISMA), etc.),
          so if your interactions would be subjected to such laws, you may not
          use the Services. You may not use the Services in a way that would
          violate the Gramm-Leach-Bliley Act (GLBA).
        </div>
      </div>
      <div className="mt-5 mb-4" id="ip">
        <h5 classsName="fw-bold">
          <strong>2. INTELLECTUAL PROPERTY RIGHTS</strong>
        </h5>
      </div>
      <div>
        <div data-custom-class="heading_2" className="mt-4 mb-3">
          <h5 className="fw-bold">Our intellectual property</h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We are the owner or the licensee of all intellectual property rights
          in our Services, including all source code, databases, functionality,
          software, website designs, audio, video, text, photographs, and
          graphics in the Services (collectively, the "Content"), as well as the
          trademarks, service marks, and logos contained therein (the "Marks").
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          Our Content and Marks are protected by copyright and trademark laws
          (and various other intellectual property rights and unfair competition
          laws) and treaties in the United States and around the world.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          The Content and Marks are provided in or through the Services "AS IS"
          for your personal, non-commercial use or internal business purpose
          only.
        </div>
        <div data-custom-class="heading_2" className="mt-4 mb-3">
          <h5 className="fw-bold">Your use of our Services</h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          Subject to your compliance with these Legal Terms, including the "
          <a
            className="text-decoration-none"
            href="#prohibited"
            data-custom-class="link"
          >
            PROHIBITED ACTIVITIES
          </a>
          " section below, we grant you a non-exclusive, non-transferable,
          revocable license to:
        </div>
        <ul>
          <li data-custom-class="body_text">access the Services; and</li>
          <li data-custom-class="body_text">
            download or print a copy of any portion of the Content to which you
            have properly gained access.
          </li>
        </ul>
        <div data-custom-class="body_text" className="text-muted mb-3">
          solely for your personal, non-commercial use or internal business
          purpose.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          Except as set out in this section or elsewhere in our Legal Terms, no
          part of the Services and no Content or Marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          If you wish to make any use of the Services, Content, or Marks other
          than as set out in this section or elsewhere in our Legal Terms,
          please address your request to: efrengarcia@hotmail.com. If we ever
          grant you the permission to post, reproduce, or publicly display any
          part of our Services or Content, you must identify us as the owners or
          licensors of the Services, Content, or Marks and ensure that any
          copyright or proprietary notice appears or is visible on posting,
          reproducing, or displaying our Content.
        </div>
      </div>
      <div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We reserve all rights not expressly granted to you in and to the
          Services, Content, and Marks.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          Any breach of these Intellectual Property Rights will constitute a
          material breach of our Legal Terms and your right to use our Services
          will terminate immediately.
        </div>
        <div data-custom-class="heading_2" className="mt-4 mb-3">
          <h5 className="fw-bold">Your submissions and contributions</h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          Please review this section and the "
          <a
            className="text-decoration-none"
            href="#prohibited"
            data-custom-class="link"
          >
            PROHIBITED ACTIVITIES
          </a>
          " section carefully prior to using our Services to understand the (a)
          rights you give us and (b) obligations you have when you post or
          upload any content through the Services.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          <strong>Submissions:</strong> By directly sending us any question,
          comment, suggestion, idea, feedback, or other information about the
          Services ("Submissions"), you agree to assign to us all intellectual
          property rights in such Submission. You agree that we shall own this
          Submission and be entitled to its unrestricted use and dissemination
          for any lawful purpose, commercial or otherwise, without
          acknowledgment or compensation to you.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          <strong>Contributions:</strong> The Services may invite you to chat,
          contribute to, or participate in blogs, message boards, online forums,
          and other functionality during which you may create, submit, post,
          display, transmit, publish, distribute, or broadcast content and
          materials to us or through the Services, including but not limited to
          text, writings, video, audio, photographs, music, graphics, comments,
          reviews, rating suggestions, personal information, or other material
          ("Contributions"). Any Submission that is publicly posted shall also
          be treated as a Contribution.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          You understand that Contributions may be viewable by other users of
          the Services.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          <strong>
            When you post Contributions, you grant us a license (including use
            of your name, trademarks, and logos):&nbsp;
          </strong>
          By posting any Contributions, you grant us an unrestricted, unlimited,
          irrevocable, perpetual, non-exclusive, transferable, royalty-free,
          fully-paid, worldwide right, and license to: use, copy, reproduce,
          distribute, sell, resell, publish, broadcast, retitle, store, publicly
          perform, publicly display, reformat, translate, excerpt (in whole or
          in part), and exploit your Contributions (including, without
          limitation, your image, name, and voice) for any purpose, commercial,
          advertising, or otherwise, to prepare derivative works of, or
          incorporate into other works, your Contributions, and to sublicense
          the licenses granted in this section. Our use and distribution may
          occur in any media formats and through any media channels.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          This license includes our use of your name, company name, and
          franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          <strong>You are responsible for what you post or upload:</strong> By
          sending us Submissions and/or posting Contributions through any part
          of the Services or making Contributions accessible through the
          Services by linking your account through the Services to any of your
          social networking accounts, you:
        </div>
        <ul>
          <li data-custom-class="body_text">
            confirm that you have read and agree with our "
            <a
              className="text-decoration-none"
              href="#prohibited"
              data-custom-class="link"
            >
              PROHIBITED ACTIVITIES
            </a>
            " and will not post, send, publish, upload, or transmit through the
            Services any Submission nor post any Contribution that is illegal,
            harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
            discriminatory, threatening to any person or group, sexually
            explicit, false, inaccurate, deceitful, or misleading;
          </li>
          <li data-custom-class="body_text">
            to the extent permissible by applicable law, waive any and all moral
            rights to any such Submission and/or Contribution;
          </li>
          <li data-custom-class="body_text">
            warrant that any such Submission and/or Contributions are original
            to you or that you have the necessary rights and licenses to submit
            such Submissions and/or Contributions and that you have full
            authority to grant us the above-mentioned rights in relation to your
            Submissions and/or Contributions; and
          </li>
          <li data-custom-class="body_text">
            warrant and represent that your Submissions and/or Contributions do
            not constitute confidential information.
          </li>
        </ul>
        <div data-custom-class="body_text" className="text-muted mb-3">
          You are solely responsible for your Submissions and/or Contributions
          and you expressly agree to reimburse us for any and all losses that we
          may suffer because of your breach of (a) this section, (b) any third
          party&rsquo;s intellectual property rights, or (c) applicable law.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          <strong>We may remove or edit your Content:</strong> Although we have
          no obligation to monitor any Contributions, we shall have the right to
          remove or edit any Contributions at any time without notice if in our
          reasonable opinion we consider such Contributions harmful or in breach
          of these Legal Terms. If we remove or edit any such Contributions, we
          may also suspend or disable your account and report you to the
          authorities.
        </div>
        <div data-custom-class="heading_2" className="mt-4 mb-3">
          <h6 className="fw-bold">Copyright infringement</h6>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Services infringes upon
          any copyright you own or control, please immediately refer to the "
          <a
            className="text-decoration-none"
            href="#copyrightyes"
            data-custom-class="link"
          >
            COPYRIGHT INFRINGEMENTS
          </a>
          " section below.
        </div>
      </div>
      <div className="mt-4 mb-3" id="userreps">
        <h5 classsName="fw-bold">
          <strong>
            <strong>
              <strong>
                <strong>3.</strong>&nbsp;
              </strong>
            </strong>
            USER REPRESENTATIONS
          </strong>
        </h5>
      </div>
      <div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          By using the Services, you represent and warrant that:(1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as
          necessary;&nbsp;(3) you have the legal capacity and you agree to
          comply with these Legal Terms;&nbsp;(4) you are not a minor in the
          jurisdiction in which you reside; (5) you will not access the Services
          through automated or non-human means, whether through a bot, script or
          otherwise; (6) you will not use the Services for any illegal or
          unauthorized purpose; and (7) your use of the Services will not
          violate any applicable law or regulation.
        </div>
      </div>
      <div>
        <div>
          <div>
            <div data-custom-class="body_text" className="text-muted mb-3">
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Services (or any portion thereof).
            </div>
          </div>
          <div className="mt-4 mb-3" id="userreg">
            <h5 classsName="fw-bold">
              <strong>
                <strong>
                  <strong>
                    <strong>4.</strong>&nbsp;
                  </strong>
                </strong>
                USER REGISTRATION
              </strong>
            </h5>
          </div>
          <div>
            <div data-custom-class="body_text" className="text-muted mb-3">
              You may be required to register to use the Services. You agree to
              keep your password confidential and will be responsible for all
              use of your account and password. We reserve the right to remove,
              reclaim, or change a username you select if we determine, in our
              sole discretion, that such username is inappropriate, obscene, or
              otherwise objectionable.
            </div>
          </div>
          <h5 classsName="fw-bold" className="mt-4 mb-3" id="subscriptions">
            <strong>5. SUBSCRIPTIONS</strong>
          </h5>
          <div data-custom-class="heading_2" className="mt-4 mb-3">
            <strong>Billing and Renewal</strong>
          </div>
          <div data-custom-class="body_text" className="text-muted mb-3">
            Your subscription will continue and automatically renew unless
            canceled. You consent to our charging your payment method on a
            recurring basis without requiring your prior approval for each
            recurring charge, until such time as you cancel the applicable
            order. The length of your billing cycle will depend on the type of
            subscription plan you choose when you subscribed to the Services.
          </div>
          <div data-custom-class="heading_2" className="mt-4 mb-3">
            <strong>Free Trial</strong>
          </div>
          <div data-custom-class="body_text" className="text-muted mb-3">
            We offer a 90-day free trial to new users who register with the
            Services. The account will be charged according to the user's chosen
            subscription at the end of the free trial.
          </div>
          <div data-custom-class="heading_2" className="mt-4 mb-3">
            <strong>Cancellation</strong>
          </div>
          <div data-custom-class="body_text" className="text-muted mb-3">
            You can cancel your subscription at any time by logging into your
            account. Your cancellation will take effect at the end of the
            current paid term. If you have any questions or are unsatisfied with
            our Services, please email us at efrengarcia@hotmail.com.
          </div>
          <div data-custom-class="heading_2" className="mt-4 mb-3">
            <strong>Fee Changes</strong>
          </div>
          <span data-custom-class="body_text" className="text-muted mb-3">
            We may, from time to time, make changes to the subscription fee and
            will communicate any price changes to you in accordance with
            applicable law.
          </span>
          <div className="mt-4 mb-3" id="prohibited">
            <h5 classsName="fw-bold">
              <strong>
                <strong>
                  <strong>
                    <strong>6.</strong>
                  </strong>
                  &nbsp;
                </strong>
                PROHIBITED ACTIVITIES
              </strong>
            </h5>
          </div>
          <div>
            <div data-custom-class="body_text" className="text-muted mb-3">
              You may not access or use the Services for any purpose other than
              that for which we make the Services available. The Services may
              not be used in connection with any commercial endeavors except
              those that are specifically endorsed or approved by us.
            </div>
          </div>
          <div>
            <div>
              <div>
                <div>
                  <div
                    data-custom-class="body_text"
                    className="text-muted mb-3"
                  >
                    As a user of the Services, you agree not to:
                  </div>
                </div>
                <ul className="text-muted mb-3">
                  <li data-custom-class="body_text">
                    Systematically retrieve data or other content from the
                    Services to create or compile, directly or indirectly, a
                    collection, compilation, database, or directory without
                    written permission from us.
                  </li>
                  <li data-custom-class="body_text">
                    Trick, defraud, or mislead us and other users, especially in
                    any attempt to learn sensitive account information such as
                    user passwords.
                  </li>
                  <li data-custom-class="body_text">
                    Circumvent, disable, or otherwise interfere with
                    security-related features of the Services, including
                    features that prevent or restrict the use or copying of any
                    Content or enforce limitations on the use of the Services
                    and/or the Content contained therein.
                  </li>
                  <li data-custom-class="body_text">
                    Disparage, tarnish, or otherwise harm, in our opinion, us
                    and/or the Services.
                  </li>
                  <li data-custom-class="body_text">
                    Use any information obtained from the Services in order to
                    harass, abuse, or harm another person.
                  </li>
                  <li data-custom-class="body_text">
                    Make improper use of our support services or submit false
                    reports of abuse or misconduct.
                  </li>
                  <li data-custom-class="body_text">
                    Use the Services in a manner inconsistent with any
                    applicable laws or regulations.
                  </li>
                  <li data-custom-class="body_text">
                    Engage in unauthorized framing of or linking to the
                    Services.
                  </li>
                  <li data-custom-class="body_text">
                    Upload or transmit (or attempt to upload or to transmit)
                    viruses, Trojan horses, or other material, including
                    excessive use of capital letters and spamming (continuous
                    posting of repetitive text), that interferes with any
                    party&rsquo;s uninterrupted use and enjoyment of the
                    Services or modifies, impairs, disrupts, alters, or
                    interferes with the use, features, functions, operation, or
                    maintenance of the Services.
                  </li>
                  <li data-custom-class="body_text">
                    Engage in any automated use of the system, such as using
                    scripts to send comments or messages, or using any data
                    mining, robots, or similar data gathering and extraction
                    tools.
                  </li>
                  <li data-custom-class="body_text">
                    Delete the copyright or other proprietary rights notice from
                    any Content.
                  </li>
                  <li data-custom-class="body_text">
                    Attempt to impersonate another user or person or use the
                    username of another user.
                  </li>
                  <li data-custom-class="body_text">
                    Upload or transmit (or attempt to upload or to transmit) any
                    material that acts as a passive or active information
                    collection or transmission mechanism, including without
                    limitation, clear graphics interchange formats ("gifs"),
                    1&times;1 pixels, web bugs, cookies, or other similar
                    devices (sometimes referred to as "spyware" or "passive
                    collection mechanisms" or "pcms").
                  </li>
                  <li data-custom-class="body_text">
                    Interfere with, disrupt, or create an undue burden on the
                    Services or the networks or services connected to the
                    Services.
                  </li>
                  <li data-custom-class="body_text">
                    Harass, annoy, intimidate, or threaten any of our employees
                    or agents engaged in providing any portion of the Services
                    to you.
                  </li>
                  <li data-custom-class="body_text">
                    Attempt to bypass any measures of the Services designed to
                    prevent or restrict access to the Services, or any portion
                    of the Services.
                  </li>
                  <li data-custom-class="body_text">
                    Copy or adapt the Services' software, including but not
                    limited to Flash, PHP, HTML, JavaScript, or other code.
                  </li>
                  <li data-custom-class="body_text">
                    Except as permitted by applicable law, decipher, decompile,
                    disassemble, or reverse engineer any of the software
                    comprising or in any way making up a part of the Services.
                  </li>
                  <li data-custom-class="body_text">
                    Except as may be the result of standard search engine or
                    Internet browser usage, use, launch, develop, or distribute
                    any automated system, including without limitation, any
                    spider, robot, cheat utility, scraper, or offline reader
                    that accesses the Services, or use or launch any
                    unauthorized script or other software.
                  </li>
                  <li data-custom-class="body_text">
                    Use a buying agent or purchasing agent to make purchases on
                    the Services.
                  </li>
                  <li data-custom-class="body_text">
                    Make any unauthorized use of the Services, including
                    collecting usernames and/or email addresses of users by
                    electronic or other means for the purpose of sending
                    unsolicited email, or creating user accounts by automated
                    means or under false pretenses.
                  </li>
                  <li data-custom-class="body_text">
                    Use the Services as part of any effort to compete with us or
                    otherwise use the Services and/or the Content for any
                    revenue-generating endeavor or commercial enterprise.
                  </li>
                  <li data-custom-class="body_text">
                    Sell or otherwise transfer your profile.
                  </li>
                  <li data-custom-class="body_text">
                    Use the Services to advertise or offer to sell goods and
                    services.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="mt-4 mb-4" id="ugc">
                <h5 classsName="fw-bold">
                  <strong>
                    <strong>
                      <strong>
                        <strong>7.</strong>
                      </strong>
                      &nbsp;
                    </strong>
                    USER GENERATED CONTRIBUTIONS
                  </strong>
                </h5>
              </div>
              <div>
                <div data-custom-class="body_text" className="text-muted mb-3">
                  The Services may invite you to chat, contribute to, or
                  participate in blogs, message boards, online forums, and other
                  functionality, and may provide you with the opportunity to
                  create, submit, post, display, transmit, perform, publish,
                  distribute, or broadcast content and materials to us or on the
                  Services, including but not limited to text, writings, video,
                  audio, photographs, graphics, comments, suggestions, or
                  personal information or other material (collectively,
                  "Contributions"). Contributions may be viewable by other users
                  of the Services and through third-party websites. As such, any
                  Contributions you transmit may be treated as non-confidential
                  and non-proprietary. When you create or make available any
                  Contributions, you thereby represent and warrant that:
                </div>
              </div>
              <ul className="text-muted mb-3">
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    The creation, distribution, transmission, public display, or
                    performance, and the accessing, downloading, or copying of
                    your Contributions do not and will not infringe the
                    proprietary rights, including but not limited to the
                    copyright, patent, trademark, trade secret, or moral rights
                    of any third party.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    You are the creator and owner of or have the necessary
                    licenses, rights, consents, releases, and permissions to use
                    and to authorize us, the Services, and other users of the
                    Services to use your Contributions in any manner
                    contemplated by the Services and these Legal Terms.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    You have the written consent, release, and/or permission of
                    each and every identifiable individual person in your
                    Contributions to use the name or likeness of each and every
                    such identifiable individual person to enable inclusion and
                    use of your Contributions in any manner contemplated by the
                    Services and these Legal Terms.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions are not false, inaccurate, or misleading.
                  </span>
                  &nbsp;
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions are not unsolicited or unauthorized
                    advertising, promotional materials, pyramid schemes, chain
                    letters, spam, mass mailings, or other forms of
                    solicitation.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing, libelous, slanderous, or
                    otherwise objectionable (as determined by us).
                  </span>
                  &nbsp;
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions are not used to harass or threaten (in
                    the legal sense of those terms) any other person and to
                    promote violence against a specific person or class of
                    people.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions do not violate any applicable law,
                    regulation, or rule.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions do not violate the privacy or publicity
                    rights of any third party.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions do not violate any applicable law
                    concerning child pornography, or otherwise intended to
                    protect the health or well-being of minors.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions do not include any offensive comments
                    that are connected to race, national origin, gender, sexual
                    preference, or physical handicap.
                  </span>
                </li>
                <li data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    Your Contributions do not otherwise violate, or link to
                    material that violates, any provision of these Legal Terms,
                    or any applicable law or regulation.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-muted mb-3">
            <div data-custom-class="body_text">
              Any use of the Services in violation of the foregoing violates
              these Legal Terms and may result in, among other things,
              termination or suspension of your rights to use the Services.
            </div>
          </div>
          <div className="mt-4 mb-4" id="license">
            <h5 classsName="fw-bold">
              <strong>
                <strong>
                  <strong>
                    <strong>8.</strong>
                  </strong>
                  &nbsp;
                </strong>
                CONTRIBUTION LICENSE
              </strong>
            </h5>
          </div>
          <div>
            <div data-custom-class="body_text" className="text-muted mb-3">
              By posting your Contributions to any part of the Services, you
              automatically grant, and you represent and warrant that you have
              the right to grant, to us an unrestricted, unlimited, irrevocable,
              perpetual, non-exclusive, transferable, royalty-free, fully-paid,
              worldwide right, and license to host, use, copy, reproduce,
              disclose, sell, resell, publish, broadcast, retitle, archive,
              store, cache, publicly perform, publicly display, reformat,
              translate, transmit, excerpt (in whole or in part), and distribute
              such Contributions (including, without limitation, your image and
              voice) for any purpose, commercial, advertising, or otherwise, and
              to prepare derivative works of, or incorporate into other works,
              such Contributions, and grant and authorize sublicenses of the
              foregoing. The use and distribution may occur in any media formats
              and through any media channels.
            </div>
          </div>
          <div>
            <div data-custom-class="body_text" className="text-muted mb-3">
              This license will apply to any form, media, or technology now
              known or hereafter developed, and includes our use of your name,
              company name, and franchise name, as applicable, and any of the
              trademarks, service marks, trade names, logos, and personal and
              commercial images you provide. You waive all moral rights in your
              Contributions, and you warrant that moral rights have not
              otherwise been asserted in your Contributions.
            </div>
          </div>
          <div>
            <div data-custom-class="body_text" className="text-muted mb-3">
              We do not assert any ownership over your Contributions. You retain
              full ownership of all of your Contributions and any intellectual
              property rights or other proprietary rights associated with your
              Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area
              on the Services. You are solely responsible for your Contributions
              to the Services and you expressly agree to exonerate us from any
              and all responsibility and to refrain from any legal action
              against us regarding your Contributions.
            </div>
          </div>
          <div>
            <div data-custom-class="body_text" className="text-muted mb-3">
              We have the right, in our sole and absolute discretion, (1) to
              edit, redact, or otherwise change any Contributions; (2) to
              re-categorize any Contributions to place them in more appropriate
              locations on the Services; and (3) to pre-screen or delete any
              Contributions at any time and for any reason, without notice. We
              have no obligation to monitor your Contributions.
            </div>
          </div>
        </div>
        <div className="mt-4 mb-3" id="advertisers">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>9.</strong>
              </strong>
              &nbsp;ADVERTISERS
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We allow advertisers to display their advertisements and other
          information in certain areas of the Services, such as sidebar
          advertisements or banner advertisements. We simply provide the space
          to place such advertisements, and we have no other relationship with
          advertisers.
        </div>
        <div className="mt-4 mb-3" id="sitemanage">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>10.</strong>
                </strong>
                &nbsp;
              </strong>
              SERVICES MANAGEMENT
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We reserve the right, but not the obligation, to: (1) monitor the
          Services for violations of these Legal Terms; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates the
          law or these Legal Terms, including without limitation, reporting such
          user to law enforcement authorities; (3) in our sole discretion and
          without limitation, refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Services
          or otherwise disable all files and content that are excessive in size
          or are in any way burdensome to our systems; and (5) otherwise manage
          the Services in a manner designed to protect our rights and property
          and to facilitate the proper functioning of the Services.
        </div>
        <div className="mt-4 mb-3" id="copyrightyes">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>11.</strong>
                </strong>
                &nbsp;
              </strong>
              COPYRIGHT INFRINGEMENTS
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Services infringes upon
          any copyright you own or control, please immediately notify us using
          the contact information provided below (a "Notification"). A copy of
          your Notification will be sent to the person who posted or stored the
          material addressed in the Notification. Please be advised that
          pursuant to applicable law you may be held liable for damages if you
          make material misrepresentations in a Notification. Thus, if you are
          not sure that material located on or linked to by the Services
          infringes your copyright, you should consider first contacting an
          attorney.
        </div>
        <div className="mt-4 mb-3" id="terms">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>12.</strong>
                </strong>
                &nbsp;
              </strong>
              TERM AND TERMINATION
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          These Legal Terms shall remain in full force and effect while you use
          the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
          TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
          REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND&nbsp;ANY
          CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
          IN OUR SOLE DISCRETION.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal, and injunctive redress.
        </div>
        <div className="mt-4 mb-3" id="modifications">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>13.</strong>
                </strong>
                &nbsp;
              </strong>
              MODIFICATIONS AND INTERRUPTIONS
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We reserve the right to change, modify, or remove the contents of the
          Services at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Services. We will not be liable to you or any third party for any
          modification, price change, suspension, or discontinuance of the
          Services.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We cannot guarantee the Services will be available at all times. We
          may experience hardware, software, or other problems or need to
          perform maintenance related to the Services, resulting in
          interruptions, delays, or errors. We reserve the right to change,
          revise, update, suspend, discontinue, or otherwise modify the Services
          at any time or for any reason without notice to you. You agree that we
          have no liability whatsoever for any loss, damage, or inconvenience
          caused by your inability to access or use the Services during any
          downtime or discontinuance of the Services. Nothing in these Legal
          Terms will be construed to obligate us to maintain and support the
          Services or to supply any corrections, updates, or releases in
          connection therewith.
        </div>
        <div className="mt-4 mb-3" id="law">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>14.</strong>
                </strong>
                &nbsp;
              </strong>
              GOVERNING LAW
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          These Legal Terms and your use of the Services are governed by and
          construed in accordance with the laws of the State of Texas applicable
          to agreements made and to be entirely performed within&nbsp;the State
          of Texas,&nbsp;without regard to its conflict of law principles.
        </div>
        <div className="mt-4 mb-3" id="disputes">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>15.</strong>
                </strong>
                &nbsp;
              </strong>
              DISPUTE RESOLUTION
            </strong>
          </h5>
        </div>
        <div data-custom-class="heading_2" className="mt-4 mb-3">
          <strong>Informal Negotiations</strong>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Legal Terms (each a "Dispute"
          and collectively, the "Disputes") brought by either you or us
          (individually, a "Party" and collectively, the "Parties"), the Parties
          agree to first attempt to negotiate any Dispute (except those Disputes
          expressly provided below) informally for at least thirty (30) days
          before initiating arbitration. Such informal negotiations commence
          upon written notice from one Party to the other Party.
        </div>
        <div data-custom-class="heading_2" className="mt-4 mb-3">
          <strong>Binding Arbitration</strong>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          If the Parties are unable to resolve a Dispute through informal
          negotiations, the Dispute (except those Disputes expressly excluded
          below) will be finally and exclusively resolved by binding
          arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
          HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration
          shall be commenced and conducted under the Commercial Arbitration
          Rules of the American Arbitration Association ("AAA") and, where
          appropriate, the AAA&rsquo;s Supplementary Procedures for Consumer
          Related Disputes ("AAA Consumer Rules"), both of which are available
          at the{" "}
          <a
            className="text-decoration-none"
            href="http://www.adr.org"
            target="_blank"
            rel="noopener noreferrer"
            data-custom-class="link"
          >
            American Arbitration Association (AAA) website
          </a>
          . Your arbitration fees and your share of arbitrator compensation
          shall be governed by the AAA Consumer Rules and, where appropriate,
          limited by the AAA Consumer Rules. The arbitration may be conducted in
          person, through the submission of documents, by phone, or online. The
          arbitrator will make a decision in writing, but need not provide a
          statement of reasons unless requested by either Party. The arbitrator
          must follow applicable law, and any award may be challenged if the
          arbitrator fails to do so. Except where otherwise required by the
          applicable AAA rules or applicable law, the arbitration will take
          place in Bexar, Texas. Except as otherwise provided herein, the
          Parties may litigate in court to compel arbitration, stay proceedings
          pending arbitration, or to confirm, modify, vacate, or enter judgment
          on the award entered by the arbitrator.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          If for any reason, a Dispute proceeds in court rather than
          arbitration, the Dispute shall be commenced or prosecuted in
          the&nbsp;state and federal courts&nbsp;located in Bexar,&nbsp;Texas,
          and the Parties hereby consent to, and waive all defenses of lack of
          personal jurisdiction, and forum non conveniens with respect to venue
          and jurisdiction in such state and federal courts. Application of the
          United Nations Convention on Contracts for the International Sale of
          Goods and the Uniform Computer Information Transaction Act (UCITA) are
          excluded from these Legal Terms.
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          In no event shall any Dispute brought by either Party related in any
          way to the Services be commenced more than one (1) years after the
          cause of action arose. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </div>
        <div data-custom-class="heading_2" className="mt-4 mb-3">
          <strong>Restrictions</strong>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilize class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.
        </div>
        <div data-custom-class="heading_2" className="mt-4 mb-3">
          <strong>Exceptions to Informal Negotiations and Arbitration</strong>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations binding arbitration:
          (a) any Disputes seeking to enforce or protect, or concerning the
          validity of, any of the intellectual property rights of a Party; (b)
          any Dispute related to, or arising from, allegations of theft, piracy,
          invasion of privacy, or unauthorized use; and (c) any claim for
          injunctive relief. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </div>
        <div className="mt-4 mb-3" id="corrections">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>16.</strong>
                </strong>
                &nbsp;
              </strong>
              CORRECTIONS
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Services at any time, without prior notice.
        </div>
        <div className="mt-4 mb-3" id="disclaimer">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>17.</strong>
              </strong>{" "}
              DISCLAIMER
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
          OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
          ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL
          ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
          USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
          SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
          FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
          THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
          OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
          ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
          TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
          WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
          OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
          APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
          NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
          TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
          SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
          MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
          EXERCISE CAUTION WHERE APPROPRIATE.
        </div>
        <div className="mt-4 mb-3" id="liability">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>18.</strong>
                </strong>
                &nbsp;
              </strong>
              LIMITATIONS OF LIABILITY
            </strong>
          </h5>
        </div>
        <div>
          <span data-custom-class="body_text" className="text-muted mb-3">
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
          </span>
          <span data-custom-class="body_text" className="text-muted mb-3">
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
            LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
            OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF
            ANY, BY YOU TO US&nbsp;DURING THE six (6) mONTH PERIOD PRIOR TO ANY
            CAUSE OF ACTION ARISING.
          </span>
          &nbsp;
          <span data-custom-class="body_text" className="text-muted mb-3">
            CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
            LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
            CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
            ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
            HAVE ADDITIONAL RIGHTS.
          </span>
        </div>
        <div className="mt-4 mb-3" id="indemnification">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>19.</strong>
                </strong>
                &nbsp;
              </strong>
              INDEMNIFICATION
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys&rsquo; fees and
          expenses, made by any third party due to or arising out of: (1) your
          Contributions;&nbsp;(2) use of the Services; (3) breach of these Legal
          Terms; (4) any breach of your representations and warranties set forth
          in these Legal Terms; (5) your violation of the rights of a third
          party, including but not limited to intellectual property rights; or
          (6) any overt harmful act toward any other user of the Services with
          whom you connected via the Services. Notwithstanding the foregoing, we
          reserve the right, at your expense, to assume the exclusive defense
          and control of any matter for which you are required to indemnify us,
          and you agree to cooperate, at your expense, with our defense of such
          claims. We will use reasonable efforts to notify you of any such
          claim, action, or proceeding which is subject to this indemnification
          upon becoming aware of it.
        </div>
        <div className="mt-4 mb-3" id="userdata">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>20.</strong>
                </strong>
                &nbsp;
              </strong>
              USER DATA
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          We will maintain certain data that you transmit to the Services for
          the purpose of managing the performance of the Services, as well as
          data relating to your use of the Services. Although we perform regular
          routine backups of data, you are solely responsible for all data that
          you transmit or that relates to any activity you have undertaken using
          the Services. You agree that we shall have no liability to you for any
          loss or corruption of any such data, and you hereby waive any right of
          action against us arising from any such loss or corruption of such
          data.
        </div>
        <div className="mt-4 mb-3" id="electronic">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>21.</strong>
                </strong>
                &nbsp;
              </strong>
              ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          Visiting the Services, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Services, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You
          hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.
        </div>
        <div className="mt-4 mb-3" id="california">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>22.</strong>
                </strong>
                &nbsp;
              </strong>
              CALIFORNIA USERS AND RESIDENTS
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </div>
        <div className="mt-4 mb-3" id="misc">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>23.</strong>
                </strong>
                &nbsp;
              </strong>
              MISCELLANEOUS
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          These Legal Terms and any policies or operating rules posted by us on
          the Services or in respect to the Services constitute the entire
          agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Legal Terms shall
          not operate as a waiver of such right or provision. These Legal Terms
          operate to the fullest extent permissible by law. We may assign any or
          all of our rights and obligations to others at any time. We shall not
          be responsible or liable for any loss, damage, delay, or failure to
          act caused by any cause beyond our reasonable control. If any
          provision or part of a provision of these Legal Terms is determined to
          be unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Legal Terms and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Legal
          Terms or use of the Services. You agree that these Legal Terms will
          not be construed against us by virtue of having drafted them. You
          hereby waive any and all defenses you may have based on the electronic
          form of these Legal Terms and the lack of signing by the parties
          hereto to execute these Legal Terms.
        </div>
        <div className="mt-4 mb-3" id="contact">
          <h5 classsName="fw-bold">
            <strong>
              <strong>
                <strong>
                  <strong>24.</strong>
                </strong>
                &nbsp;
              </strong>
              CONTACT US
            </strong>
          </h5>
        </div>
        <div data-custom-class="body_text" className="text-muted mb-3">
          In order to resolve a complaint regarding the Services or to receive
          further information regarding use of the Services, please contact us
          at:
        </div>
        <div data-custom-class="body_text" className="text-muted">
          <strong>Surgisync</strong>
        </div>
        <div data-custom-class="body_text" className="text-muted">
          <strong>9902 Briar Patch</strong>
        </div>
        <div data-custom-class="body_text" className="text-muted">
          <strong>San Antonio, TX 78254</strong>
        </div>
        <div data-custom-class="body_text" className="text-muted">
          <strong>United States</strong>
        </div>
        <div data-custom-class="body_text" className="text-muted">
          <strong>
            <strong>
              <strong>
                <strong>Phone: (+1)2108853614</strong>&nbsp;
              </strong>
            </strong>
          </strong>
        </div>
        <div data-custom-class="body_text" className="text-muted">
          <strong>
            <strong>efrengarcia@hotmail.com</strong>
          </strong>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
