import { Button, Card, Flex, Select, message } from "antd"
import React, { useEffect } from "react"
import {
  PackageCapsule,
  PackageDescription,
  PackageDetail,
  PackageHeading,
  PackageName,
  PackageTopHeading,
  Text
} from "../../styledComponents"
import { CheckCircleFilled } from "@ant-design/icons"
import {
  assignPackage,
  buySubscription,
  fetchSubscription
} from "../../api/DataService"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const PackageSelection = () => {
  const navigate = useNavigate()
  const [subscriptionList, setSubscriptionList] = React.useState([{}])
  const [selectedPackage, setSlectedPackage] = React.useState()

  useEffect(() => {
    fetchSubscription().then(({ data: res }) => {
      if (res?.success) {
        setSubscriptionList(res.result)
      }
    })
  }, [])

  const freePlanHandler = () => {
    assignPackage().then(({ data: res, error }) => {
      if (res?.success) {
        message.success(res.message)
        navigate("/login")
      }

      if (error) {
        Object.keys(error).forEach((key, value) => {
          if (key === "message") {
            message.error(error[key])
          }
        })
      }
    })
  }

  const hospitalBundleHandler = () => {
    const price_id = subscriptionList.find(
      item => item.price_id === selectedPackage
    )?.price_id
    buySubscription(price_id).then(({ data: res }) => {
      if (res?.hosted_invoice_url) {
        navigate("/login")
        window.open(res?.hosted_invoice_url, "_blank")
      }
    })
  }

  return (
    <Flex justify="center" align="center" style={{ height: "100vh" }} vertical>
      <PackageTopHeading className="mb-2">PRICING</PackageTopHeading>
      <PackageHeading>How are you Planning to use SurgiSync?</PackageHeading>
      <PackageDescription>
        Discover our pricing options for seamless Preference Cards
      </PackageDescription>
      <PackageDescription>
        collaboration among Surgeons, Nurses, and Surgical Technicians.
      </PackageDescription>
      <Flex justify="center" align="center" className="mt-5">
        <Card
          className="rounded-0"
          style={{
            color: "#FFFFFF",
            background: "#2968DC",
            width: "450px",
            height: "424px"
          }}
        >
          <Flex justify="space-between">
            <Text className="text-white">HOSPITAL BUNDLE</Text>
            <PackageCapsule>Monthly</PackageCapsule>
          </Flex>
          <PackageName className="mt-4">Paid</PackageName>
          <PackageDetail className="my-4">
            Unlimited Preference cards with unlimited users
          </PackageDetail>
          <Flex gap={10} className="w-100">
            <Select
              className="w-50"
              placeholder="Select Package..."
              onChange={e => setSlectedPackage(e)}
              options={subscriptionList.map(item => ({
                value: item.price_id,
                label: item.name
              }))}
            />
            <Button
              className="rounded-0 flex-grow-1 bg-white"
              style={{ color: "#2968DC" }}
              onClick={() => hospitalBundleHandler()}
              disabled={!selectedPackage}
            >
              {selectedPackage
                ? `Get started - $${subscriptionList.find(item => item.price_id === selectedPackage)?.price}`
                : "Select Package"}
            </Button>
          </Flex>
          <Text className="mb-3 mt-5 text-white">
            <CheckCircleFilled /> Unlimited preference cards
          </Text>
          <Text className="mb-3 text-white">
            <CheckCircleFilled /> Manage access of your seats
          </Text>
          <Text className="mb-3 text-white">
            <CheckCircleFilled /> Renews every month
          </Text>
          <Text className="mb-3 text-white">
            <CheckCircleFilled /> Collaboration Feature
          </Text>
        </Card>
        <Card
          className="rounded-0"
          style={{
            background: "#F5F8FE",
            width: "450px",
            height: "424px"
          }}
        >
          <PackageTopHeading>Free</PackageTopHeading>
          <PackageName className="mt-4">Free</PackageName>
          <PackageDescription className="my-4 text-start">
            Unlimited Preference cards for 3 months
          </PackageDescription>
          <Button className="rounded-0 w-100" onClick={() => freePlanHandler()}>
            Get started - For Free
          </Button>
          <Text className="mb-3 mt-5">
            <CheckCircleFilled style={{ color: "#2968DC" }} /> Unlimited
            preference cards
          </Text>
          <Text className="mb-3">
            <CheckCircleFilled style={{ color: "#2968DC" }} /> 1 User
          </Text>
          <Text className="mb-3">
            <CheckCircleFilled style={{ color: "#2968DC" }} /> Free for 3 month
          </Text>
          <Text className="mb-3">
            <CheckCircleFilled style={{ color: "#2968DC" }} /> Collaboration
            Feature
          </Text>
        </Card>
      </Flex>
    </Flex>
  )
}

const PackageWraper = styled.div`
  width: 90%;
  height: 100%;
  margin: auto;
  padding: 20px;
  .slick-active.slick-center.slick-current .ant-card {
    z-index: 1;
    box-shadow: 0px 0px 10px 5px #aaaaaa;
    color: #ffffff;
    background: #2968dc !important;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #2968dc;
  }
`

export default PackageSelection
