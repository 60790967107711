import React, { useEffect, useState } from "react"
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  Divider,
  Switch,
  message,
  Upload,
  Image,
  Radio,
  Space,
  Select,
  InputNumber
} from "antd"
import { LeftOutlined, InboxOutlined } from "@ant-design/icons"
import { UserText } from "../../../styledComponents"
import { useLocation, useNavigate } from "react-router-dom"
import { addUser, editUser } from "../../../api/DataService"
import {
  Countries,
  getCountryLabelAndCode
} from "../../../constants/CountryCodes"
const { Dragger } = Upload

const EditUser = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const formValues = location.state || {}
  const [form] = Form.useForm()
  const [image, setImage] = useState(formValues?.profile_picture)
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState(formValues?.role)
  const [selectCountryCode, setSelectCountryCode] =
    useState("United States_US_1")
  const updatedCountryCode = Countries.map(country => ({
    label: country.value,
    value: `${country.label}_${country.value}_${country.phoneCode}`
  }))

  useEffect(() => {
    setSelectCountryCode(
      getCountryLabelAndCode(
        formValues?.phone_number?.split("_")[0] || "US",
        formValues?.phone_number?.split("_")[1] || "1"
      )
    )
    form.setFieldsValue({
      profile_picture: formValues?.profile_picture,
      name: formValues?.name,
      designation: formValues?.designation,
      email: formValues?.email,
      phone_number: formValues?.phone_number?.split("_")[2],
      is_active: formValues?.is_active
    })
  }, [formValues])

  const handleSubmit = values => {
    const formData = new FormData()
    image &&
      typeof image == "object" &&
      formData.append("profile_picture", image)
    values.name && formData.append("name", values.name)
    values.designation && formData.append("designation", values.designation)
    values.email && formData.append("email", values.email)
    values.phone_number &&
      formData.append(
        "phone_number",
        `${selectCountryCode.split("_")[1]}_${selectCountryCode.split("_")[2]}_${values.phone_number}`
      )
    role && formData.append("role", role)
    values.is_active && formData.append("is_active", values.is_active)

    editUser(formValues.id, formData).then(({ data: res, error }) => {
      if (res?.status === 200) {
        message.success(res?.message)
        navigate("/user-listing")
        return
      }

      if (error) {
        Object.entries(error).forEach(([key, value]) => {
          message.error(value)
        })
      }
    })
  }

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload(file) {
      const fileType = file.type
      if (
        fileType !== "image/png" &&
        fileType !== "image/jpg" &&
        fileType !== "image/jpeg"
      ) {
        message.error("File should be of type PNG, JPG, or JPEG")
        return
      }
      setImage(file)
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    }
  }

  return (
    <div className="w-100" style={{ margin: 0 }}>
      <Breadcrumb className="m-2 mx-3" style={{ margin: 0 }}>
        <Breadcrumb.Item>User Management</Breadcrumb.Item>
        <Breadcrumb.Item>{formValues.name}</Breadcrumb.Item>{" "}
        <Breadcrumb.Item>Edit a User</Breadcrumb.Item>
      </Breadcrumb>
      <Divider style={{ margin: 0 }} />
      <div>
        <div className="d-flex align-items-center m-3 gap-2">
          <LeftOutlined onClick={() => navigate("/user-listing")} />
          <h4 style={{ margin: 0 }}>Edit a User</h4>
        </div>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <div
            className="d-flex flex-column gap-3 p-4"
            style={{ backgroundColor: "#F7F8F9" }}
          >
            <div className="d-flex">
              <div style={{ flexBasis: "40%" }}>
                <h5>User Details</h5>
                <UserText>Add details about the User</UserText>
              </div>
              <div
                style={{ flexBasis: "50%" }}
                className="d-flex flex-column gap-3"
              >
                <Form.Item
                  name="profile_picture"
                  label="Upload Picture"
                  style={{ margin: 0 }}
                >
                  <Dragger {...props} className="w-100">
                    {typeof image === "string" ? (
                      <Image
                        src={image}
                        style={{ maxWidth: "249px", maxHeight: "140px" }}
                        preview={false}
                      />
                    ) : image && typeof image === "object" ? (
                      <Image
                        src={URL.createObjectURL(image)}
                        style={{ maxWidth: "249px", maxHeight: "140px" }}
                        preview={false}
                      />
                    ) : (
                      <>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <div className="ant-upload-text">
                          Click or drag file to this area to upload
                        </div>
                        <div className="ant-upload-hint">
                          Support for a single file upload. Can be PNG, JPEG,
                          JPG
                        </div>
                      </>
                    )}
                  </Dragger>
                </Form.Item>
                <Form.Item
                  name="name"
                  label="Username"
                  style={{ margin: 0 }}
                  rules={[
                    { required: true, message: "Please input your name!" }
                  ]}
                >
                  <Input placeholder="Enter name" maxLength={255}/>
                </Form.Item>
                <Form.Item
                  name="designation"
                  label="Designation"
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your designation!"
                    }
                  ]}
                >
                  <Input placeholder="Enter designation" maxLength={255}/>
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email Address"
                  style={{ margin: 0 }}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!"
                    },
                    { required: true, message: "Please input your email!" }
                  ]}
                >
                  <Input placeholder="Enter email" maxLength={255}/>
                </Form.Item>
                <Form.Item
                  name="phone_number"
                  label="Phone Number"
                  style={{ margin: 0 }}
                  rules={[
                    {
                      type: "number",
                      message: "Please enter a valid Phone Number!",
                      transform: value => value && Number(value)
                    }
                  ]}
                >
                  <InputNumber
                    addonBefore={
                      <Select
                        value={selectCountryCode}
                        options={updatedCountryCode}
                        style={{ width: 100 }}
                        onChange={e => setSelectCountryCode(e)}
                        showSearch
                      />
                    }
                    placeholder="Enter your phone number"
                    type="number"
                    controls={false}
                    className="w-100"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ flexBasis: "40%" }}>
                <h5>Choose the Role</h5>
                <UserText>Select the role for creating a User</UserText>
              </div>
              <div
                style={{ flexBasis: "50%" }}
                className="d-flex flex-column gap-3"
              >
                <Form.Item
                  name="role"
                  label="Select the Role"
                  style={{ margin: 0 }}
                >
                  <Radio.Group
                    defaultValue={role}
                    onChange={e => setRole(e.target.value)}
                  >
                    <Radio value={"surgeon"}>Surgeon</Radio>
                    <Radio value={"nurse"}>Nurse</Radio>
                    <Radio value={"surgical-technician"}>
                      Surgical Technician
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ flexBasis: "40%" }}>
                <h5>Mark the Status</h5>
              </div>
              <div
                style={{ flexBasis: "50%" }}
                className="d-flex flex-column gap-5"
              >
                <Form.Item
                  className="d-flex flex-column"
                  name="is_active"
                  label="Mark User as Active?"
                  labelCol={{ style: { order: 2 } }}
                  style={{ margin: 0 }}
                >
                  <Switch size="small" />
                </Form.Item>
              </div>
            </div>
          </div>
          <Form.Item
            className="d-flex justify-content-end m-2"
            style={{ margin: 0 }}
          >
            <Button
              className="ms-2"
              type="default"
              htmlType="button"
              onClick={() => navigate("/user-listing")}
            >
              Cancel
            </Button>
            <Button className="ms-2" type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default EditUser
