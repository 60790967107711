import React from "react"
import { Form, Input, Button, Image, Checkbox, message } from "antd"
import CompanyLogo from "../../assets/Company-Logo.svg"
import AuthSplashImage from "../../assets/Login-Splash-Image.jpg"
import styled from "styled-components"
import {
  AuthDescription,
  AuthField,
  AuthFormItem,
  BlurOverlay,
  Card,
  Description,
  Header,
  Text
} from "../../styledComponents"
import { useNavigate } from "react-router-dom"
import { forgetPassword } from "../../api/DataService"

const ForgetPasswrod = () => {
  const navigate = useNavigate()

  const onFinish = values => {
    forgetPassword(values.email).then(res => {
      if (res?.data?.success) {
        message.success(res.data.message)
        navigate("/login")
        return
      }
      if (res?.error) {
        message.error("User not found")
      }
    })
  }

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Header bgColor={AuthSplashImage} hideHeader="none">
          <BlurOverlay>
            <Card>
              <h5>Synchronize Surgical Success with Seamless Collaboration</h5>
              <Description>
                Upgrade to Collaborate and Innovate approach
              </Description>
            </Card>
          </BlurOverlay>
        </Header>
      </div>
      <div style={{ flex: 1 }} className="my-auto mx-3">
        <div>
          <FormContainer>
            <div className="text-center">
              <Image
                className="text-center"
                src={CompanyLogo}
                preview={false}
              />
            </div>
            <h4 className="text-start mt-4">Forgot Password?</h4>
            <AuthDescription className="text-start">
              Please enter your email Id to receive a reset password link
            </AuthDescription>
            <Form onFinish={onFinish} layout="vertical">
              <AuthFormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!"
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address!"
                  }
                ]}
              >
                <Input placeholder="Enter your email" maxLength={255}/>
              </AuthFormItem>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-100 mt-2">
                  Send Link
                </Button>
              </Form.Item>
            </Form>
          </FormContainer>
        </div>
      </div>
    </div>
  )
}

const FormContainer = styled.div`
  width: 428px;
  height: 100%;
  margin: auto;
  padding: 20px;
`

export default ForgetPasswrod
