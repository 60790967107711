import React, { useEffect, useState } from "react"
import {
  Table,
  Input,
  Button,
  Space,
  Pagination,
  Dropdown,
  Select,
  message
} from "antd"
import {
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons"
import { userListingColumns } from "../../../constants/Headers"
import styled from "styled-components"
import { deleteUser, getUserList } from "../../../api/DataService"
import { Link, useNavigate } from "react-router-dom"
import { RoleOptions } from "../../../constants/ConstantOption"
import DeleteModal from "../../../components/DeleteModal"

const UserListing = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [role, setRole] = useState("")
  const [listData, setListData] = useState([])
  const [searchedColumn, setSearchedColumn] = useState("")
  const [deleteModalValues, setDeleteModalValues] = useState()
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [userLimit, setUserLimit] = useState(0)

  useEffect(() => {
    let timeoutId
    const delay = 500

    const fetchData = () => {
      setLoading(true)
      getUserList(searchText, role, currentPage).then(({ data: res, error }) => {
        setListData(res?.data?.users?.results)
        setCount(res?.data?.registered_users)
        setUserLimit(res?.data?.users_limit)
        setLoading(false)
        if (error) message.error(error?.message)
      })
    }

    const debounceFetchData = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(fetchData, delay)
    }

    debounceFetchData()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchText, role, currentPage])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText("")
  }

  const handleEdit = record => {
    navigate("/edit-user", { state: record })
  }

  const handleDelete = record => {
    setDeleteModalValues(record)
    setIsModalOpen(true)
  }

  const deleteUserHandler = () => {
    deleteUser(deleteModalValues.id).then(({ data: res }) => {
      message.success("User deleted successfully")
      setIsModalOpen(false)
      getUserList(searchText, role, currentPage).then(({ data: res }) => {
        setListData(res?.data?.users?.results)
        setCount(res?.data?.registered_users)
        setUserLimit(res?.data?.users_limit)

      })
    })
  }

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        deleteHandler={deleteUserHandler}
        headingText="Are you sure, you want to Delete this record?"
        descText="By deleting this data, you’ll no longer be able to access it"
      />
      <div className="m-4 flex-grow-1">
        <div className="d-flex justify-content-between">
          <h3 className="mb-4">Users Management</h3>
          <div
            style={{
              background: "#F5F8FE",
              height: "40px",
              borderLeft: "5px solid #1D57C5"
            }}
            className="d-flex gap-3 p-2 align-items-center rounded-2"
          >
            <ExclamationCircleOutlined />
            <span>{count}/{userLimit} Users</span>
            <Link
              style={{
                color: "#1D57C5"
              }}
              to="/subscription-plan"
            >
              Upgrade Plan
            </Link>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex gap-3">
            <Input
              placeholder="Search User..."
              prefix={<SearchOutlined />}
              onChange={e => setSearchText(e.target.value)}
              allowClear
            />
            <Select
              className="w-100"
              placeholder="Select Role..."
              options={RoleOptions}
              onChange={e => setRole(e)}
              allowClear
            />
          </div>
          <Button
            size="medium"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/add-user")}
          >
            Add a New User
          </Button>
        </div>
        <Table
          columns={userListingColumns(handleEdit, handleDelete)}
          dataSource={listData}
          pagination={false}
          loading={loading}
        />
        <Pagination
          className="text-end mt-3"
          total={count}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={10}
          onChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  )
}

export default UserListing
