import React, { useEffect, useState } from "react"
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined
} from "@ant-design/icons"
import { Menu } from "antd"
import { settingTabMenu } from "../../../constants/Constants"
import StyledMenu from "../../../components/StyledMenu"
import Notification from "./Notification"
import PasswordSecurity from "./PasswordSecurity"
import ProfileSetting from "./ProfileSetting"


const Setting = () => {
  const [current, setCurrent] = useState("profile")

  return (
    <div className="w-100">
      <h3 className="m-3">Settings</h3>
      <StyledMenu
        onClick={setCurrent}
        selectedKeys={current}
        mode="horizontal"
        items={settingTabMenu}
      />
      {current === "profile" && <ProfileSetting />}
      {current === "password" && <PasswordSecurity />}
      {current === "notifications" && <Notification />}
    </div>
  )
}

export default Setting
