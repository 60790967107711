import {
  UnorderedListOutlined,
  FileSearchOutlined,
  TableOutlined,
  DollarOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  BlockOutlined
} from "@ant-design/icons"

export const sidebarNavData = [
  {
    heading: {
      itemName: "My Preference Cards",
      itemIcon: <UnorderedListOutlined />,
      navigateTo: "/mypreferencecard-listing"
    },
    itemList: [
      {
        itemName: "Preference Cards",
        itemIcon: <FileSearchOutlined />,
        navigateTo: "/preferencecard-listing"
      },
      {
        itemName: "Items",
        itemIcon: <UnorderedListOutlined />,
        navigateTo: "/item-listing"
      },
      {
        itemName: "Templates",
        itemIcon: <TableOutlined />,
        navigateTo: "/template-listing"
      }
    ]
  },
  {
    heading: {
      itemName: "User Management",
      itemIcon: <UsergroupAddOutlined />,
      navigateTo: "/user-listing"
    }
  },
  {
    heading: {
      itemName: "Settings",
      itemIcon: <SettingOutlined />,
      navigateTo: "/setting"
    }
  },
  {
    heading: {
      itemName: "Subscription Plans",
      itemIcon: <DollarOutlined />,
      navigateTo: "/subscription-plan"
    }
  }
]

export const surgeonSidebarNavData = [
  {
    heading: {
      itemName: "My Preference Cards",
      itemIcon: <UnorderedListOutlined />,
      navigateTo: "/mypreferencecard-listing"
    },
    itemList: [
      {
        itemName: "Preference Cards",
        itemIcon: <FileSearchOutlined />,
        navigateTo: "/preferencecard-listing"
      },
      {
        itemName: "Items",
        itemIcon: <UnorderedListOutlined />,
        navigateTo: "/item-listing"
      },
      {
        itemName: "Templates",
        itemIcon: <TableOutlined />,
        navigateTo: "/template-listing"
      }
    ]
  },
  {
    heading: {
      itemName: "Settings",
      itemIcon: <SettingOutlined />,
      navigateTo: "/setting"
    }
  }
]

export const adminNurseSidebarNavData = [
  {
    heading: {
      itemName: "General Cards",
      itemIcon: <FileSearchOutlined />,
      navigateTo: "/general-card-listing"
    },
    itemList: [
      {
        itemName: "Saved Profiles",
        itemIcon: <BlockOutlined />,
        navigateTo: "/saved-profile-listing"
      }
    ]
  },
  {
    heading: {
      itemName: "User Management",
      itemIcon: <UsergroupAddOutlined />,
      navigateTo: "/user-listing"
    }
  },
  {
    heading: {
      itemName: "Settings",
      itemIcon: <SettingOutlined />,
      navigateTo: "/setting"
    }
  },
  {
    heading: {
      itemName: "Subscription Plans",
      itemIcon: <DollarOutlined />,
      navigateTo: "/subscription-plan"
    }
  }
]

export const nurseSidebarNavData = [
  {
    heading: {
      itemName: "General Cards",
      itemIcon: <FileSearchOutlined />,
      navigateTo: "/general-card-listing"
    },
    itemList: [
      {
        itemName: "Saved Profiles",
        itemIcon: <BlockOutlined />,
        navigateTo: "/saved-profile-listing"
      }
    ]
  },
  {
    heading: {
      itemName: "Settings",
      itemIcon: <SettingOutlined />,
      navigateTo: "/setting"
    }
  }
]
