import React from "react"
import { Form, Input, Button, Breadcrumb, Divider, message } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import {
  fetchItems,
  getSinglePreferenceCard,
  editMyPreferenceCard,
  requestSuggestion
} from "../../../api/DataService"
import { removeEmptyObjectsFromArray } from "../../../util/commonUtil"
import DynamicFieldPrefCard from "../../../components/DynamicFieldPrefCard"
import { UserText } from "../../../styledComponents"

const RequestSuggestionNurse = () => {
  const [form] = Form.useForm()
  const [prevValues, setPrevValues] = useState({})
  const [items, setItems] = useState([])
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const formValues = location.state || {}
  const sections = [
    "equipments",
    "instruments",
    "supplies",
    "medications",
    "sutures",
    "dressings",
    "special_requirements"
  ]

  useEffect(() => {
    fetchItems().then(({ data: res }) => {
      const updatedItems = {}
      res?.data?.forEach(item => {
        if (!updatedItems[item.category_identifier]) {
          updatedItems[item.category_identifier] = []
        }

        updatedItems[item.category_identifier]?.push({
          label: item.name,
          value: item.id
        })
      })
      setItems(updatedItems)
    })
  }, [])

  useEffect(() => {
    getSinglePreferenceCard(formValues.id).then(({ data: res }) => {
      setPrevValues(res?.data)
      form.setFieldsValue({
        surgeon_name: res?.data?.surgeon_name,
        procedure_name: res?.data?.procedure_name,
        description: res?.data?.description,
        glove_size: res?.data?.glove_size,
        equipments: res?.data?.equipments?.length
          ? res?.data?.equipments
          : [{ equipment: undefined, quantity: 1 }],
        instruments: res?.data?.instruments?.length
          ? res?.data?.instruments
          : [{ instrument: undefined, quantity: 1 }],
        supplies: res?.data?.supplies?.length
          ? res?.data?.supplies
          : [{ supply: undefined, quantity: 1 }],
        medications: res?.data?.medications?.length
          ? res?.data?.medications
          : [{ medication: undefined, quantity: 1 }],
        sutures: res?.data?.sutures?.length
          ? res?.data?.sutures
          : [{ suture: undefined, quantity: 1 }],
        dressings: res?.data?.dressings?.length
          ? res?.data?.dressings
          : [{ dressing: undefined, quantity: 1 }],
        special_requirements: res?.data?.special_requirements?.length
          ? res?.data?.special_requirements
          : [{ special_requirement: undefined, quantity: 1 }],
        notes: res?.data?.notes
      })
    })
  }, [])

  // Add new entities Functions
  const returnSingleNewEntities = (oldArray, newArray) => {
    const map = oldArray.reduce((acc, item) => {
      acc[item.id] = true
      return acc
    }, {})

    return newArray.filter(item => !item.id || !map[item.id])
  }

  const returnNewEntities = (oldArray, newArray, addItemRequest) => {
    const newEntries = {}
    sections.forEach(section => {
      newEntries[section] = returnSingleNewEntities(
        oldArray[section],
        newArray[section]
      )
      if (newEntries[section].length > 0) {
        addItemRequest(section, newEntries[section])
      }
    })

    return newEntries
  }

  const addItemRequest = (section, item) => {
    let newSection = section.slice(0, -1)
    newSection =
      newSection === "special_requirement" ? "special_requirement" : newSection
    newSection = newSection === "supplie" ? "supply" : newSection
    const updatedValues = item.map(item => ({
      suggestion_type: "add_item",
      pref_card: formValues.id,
      item_category: newSection,
      item: item[newSection],
      quantity: item.quantity
    }))

    requestSuggestion(updatedValues).then(({ data: res, error }) => {
      console.log(res, " and ", error)
    })
  }

  // Remove entities Functions
  const findSingleRemovedEntries = (oldArray, newArray) => {
    const removedEntries = oldArray.filter(oldEntry => {
      return !newArray.some(newEntry => oldEntry.id === newEntry.id)
    })
    return removedEntries
  }

  const returnRemovedEntities = (oldArray, newArray, removeItemRequest) => {
    const newEntries = {}

    sections.forEach(section => {
      newEntries[section] = findSingleRemovedEntries(
        oldArray[section],
        newArray[section]
      )

      if (newEntries[section].length > 0) {
        removeItemRequest(section, newEntries[section])
      }
    })

    return newEntries
  }

  const removeItemRequest = (section, item) => {
    let newSection = section.slice(0, -1)
    newSection =
      newSection === "special_requirement" ? "special_requirement" : newSection
    newSection = newSection === "supplie" ? "supply" : newSection
    const updatedValues = item.map(item => ({
      suggestion_type: "remove_item",
      pref_card: formValues.id,
      item_category: newSection,
      suggested_item_id: item.id,
      item: item[newSection]
    }))

    requestSuggestion(updatedValues).then(({ data: res, error }) => {
      console.log(res, " and ", error)
    })
  }

  //Update entities Functions
  const findSingleUpdatedEntries = (oldArray, newArray, section) => {
    const updatedEntries = []
    newArray.forEach(newEntry => {
      const matchingOldEntry = oldArray.find(
        oldEntry => oldEntry.id === newEntry.id
      )
      if (matchingOldEntry) {
        if (
          matchingOldEntry[section] !== newEntry[section] ||
          matchingOldEntry.quantity !== newEntry.quantity
        ) {
          updatedEntries.push({
            ...newEntry,
            prev_quantity: matchingOldEntry.quantity
          })
        }
      }
    })
    return updatedEntries
  }

  const returnUpdatedEntities = (oldArray, newArray, updateItemRequest) => {
    const newEntries = {}

    sections.forEach(section => {
      newEntries[section] = findSingleUpdatedEntries(
        oldArray[section],
        newArray[section],
        section
      )

      if (newEntries[section].length > 0) {
        updateItemRequest(section, newEntries[section])
      }
    })

    return newEntries
  }

  const updateItemRequest = (section, item) => {
    let newSection = section.slice(0, -1)
    newSection =
      newSection === "special_requirement" ? "special_requirement" : newSection
    newSection = newSection === "supplie" ? "supply" : newSection
    const updatedValues = item.map(item => ({
      suggestion_type: "update_item",
      pref_card: formValues.id,
      item_category: newSection,
      suggested_item_id: item.id,
      item: item[newSection],
      quantity: item.quantity,
      prev_quantity: item.prev_quantity
    }))

    requestSuggestion(updatedValues).then(({ data: res, error }) => {
      console.log(res, " and ", error)
    })
  }

  const handleSubmit = values => {
    returnNewEntities(prevValues, values, addItemRequest)
    returnRemovedEntities(prevValues, values, removeItemRequest)
    returnUpdatedEntities(prevValues, values, updateItemRequest)
    message.success("Request Suggestion submitted successfully")
    navigate(-1)
  }

  return (
    <div className="w-100" style={{ margin: 0 }}>
      <Breadcrumb className="m-2 mx-3" style={{ margin: 0 }}>
        <Breadcrumb.Item>General Cards</Breadcrumb.Item>
        <Breadcrumb.Item>{formValues.procedure_name}</Breadcrumb.Item>
        <Breadcrumb.Item>Request Suggestion</Breadcrumb.Item>
      </Breadcrumb>
      <Divider style={{ margin: 0 }} />
      <div>
        <div className="d-flex align-items-center m-3 gap-2">
          <LeftOutlined onClick={() => navigate(-1)} />
          <h4 style={{ margin: 0 }}>Request Suggestion</h4>
        </div>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            surgeon_name: "",
            procedure_name: "",
            description: "",
            glove_size: "",
            equipments: [{ equipment: undefined, quantity: 1 }],
            instruments: [{ instrument: undefined, quantity: 1 }],
            supplies: [{ supply: undefined, quantity: 1 }],
            medications: [{ medication: undefined, quantity: 1 }],
            sutures: [{ suture: undefined, quantity: 1 }],
            dressings: [{ dressing: undefined, quantity: 1 }],
            special_requirements: [
              { special_requirement: undefined, quantity: 1 }
            ],
            notes: ""
          }}
        >
          <div
            className="d-flex flex-column gap-3 p-4"
            style={{ backgroundColor: "#F7F8F9" }}
          >
            <div className="d-flex mb-4">
              <div style={{ flexBasis: "40%" }}>
                <h5>Procedure Details</h5>
                <UserText>Add details about the procedure</UserText>
              </div>
              <div
                style={{ flexBasis: "50%" }}
                className="d-flex flex-column gap-3"
              >
                <div className="d-flex gap-3">
                  <Form.Item
                    name="procedure_name"
                    label="Procedure Name"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Please input procedure name!"
                      }
                    ]}
                    className="flex-grow-1"
                  >
                    <Input placeholder="Enter name" disabled maxLength={255} />
                  </Form.Item>
                  <Form.Item
                    name="surgeon_name"
                    label="Surgeon Name"
                    style={{ margin: 0 }}
                    rules={[
                      { required: true, message: "Please input surgeon name!" }
                    ]}
                    className="flex-grow-1"
                  >
                    <Input placeholder="Enter name" disabled maxLength={255} />
                  </Form.Item>
                </div>
                <Form.Item
                  name="description"
                  label="Position Description"
                  style={{ margin: 0 }}
                  rules={[
                    { required: true, message: "Please select description!" }
                  ]}
                >
                  <Input.TextArea placeholder="Enter detail" disabled />
                </Form.Item>
                <Form.Item
                  name="glove_size"
                  label="Glove Size"
                  style={{ margin: 0 }}
                  rules={[
                    { required: true, message: "Please input glove size!" }
                  ]}
                >
                  <Input placeholder="Enter glove size" maxLength={255} />
                </Form.Item>
              </div>
            </div>
            <div className="d-flex mb-4">
              <div style={{ flexBasis: "40%" }}>
                <h5>Items Details</h5>
                <UserText>Add Items used in the procedure</UserText>
              </div>
              <div style={{ flexBasis: "50%" }}>
                <DynamicFieldPrefCard
                  form={form}
                  name="equipments"
                  label="Equipments"
                  itemLabel="Equipment"
                  itemName="equipment"
                  itemPlaceholder="Select Equipment"
                  itemOptions={items?.equipment || []}
                  hideCreateItem={true}
                />
                <DynamicFieldPrefCard
                  form={form}
                  name="instruments"
                  label="Instruments"
                  itemLabel="Instrument"
                  itemName="instrument"
                  itemPlaceholder="Select Instrument"
                  itemOptions={items?.instrument || []}
                  hideCreateItem={true}
                />
                <DynamicFieldPrefCard
                  form={form}
                  name="supplies"
                  label="Supplies"
                  itemLabel="Supply"
                  itemName="supply"
                  itemPlaceholder="Select supplies"
                  itemOptions={items?.supply || []}
                  hideCreateItem={true}
                />
                <DynamicFieldPrefCard
                  form={form}
                  name="medications"
                  label="Medication"
                  itemLabel="Medication"
                  itemName="medication"
                  itemPlaceholder="Select medications"
                  itemOptions={items?.medication || []}
                  hideCreateItem={true}
                />
                <DynamicFieldPrefCard
                  form={form}
                  name="sutures"
                  label="Suture"
                  itemLabel="Suture"
                  itemName="suture"
                  itemPlaceholder="Select suture"
                  itemOptions={items?.suture || []}
                  hideCreateItem={true}
                />
                <DynamicFieldPrefCard
                  form={form}
                  name="dressings"
                  label="Dressings"
                  itemLabel="Dressing"
                  itemName="dressing"
                  itemPlaceholder="Select dressing"
                  itemOptions={items?.dressing || []}
                  hideCreateItem={true}
                />
                <DynamicFieldPrefCard
                  form={form}
                  name="special_requirements"
                  label="Special Requirements"
                  itemLabel="Special Requirement"
                  itemName="special_requirement"
                  itemPlaceholder="Select requirements"
                  itemOptions={items["special_requirement"] || []}
                  hideCreateItem={true}
                />
                <Form.Item name="notes" label="Special Notes">
                  <Input.TextArea placeholder="Add notes here" />
                </Form.Item>
              </div>
            </div>
          </div>
          <Form.Item
            className="d-flex justify-content-end m-2"
            style={{ margin: 0 }}
          >
            <Button
              className="ms-2"
              type="default"
              htmlType="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button className="ms-2" type="primary" htmlType="submit">
              Save Change Request
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default RequestSuggestionNurse
