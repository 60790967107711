import React from "react"
import { Form, Input, Button, Image, Checkbox,message } from "antd"
import CompanyLogo from "../../assets/Company-Logo.svg"
import AuthSplashImage from "../../assets/Login-Splash-Image.jpg"
import styled from "styled-components"
import {
  AuthDescription,
  AuthField,
  AuthFormItem,
  BlurOverlay,
  Card,
  Description,
  Header,
  Text
} from "../../styledComponents"
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import {resetPassword} from "../../api/DataService"

const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const onFinish = values => {
    let uid=searchParams.get("uid");
    let token=searchParams.get("token")
   
    resetPassword(values.password, values.confirmPassword,uid,token).then(({ data: res, error }) => {
      if (res?.status === 200) {
        message.success(res?.message)
          navigate("/login")
  
      }
      if (error) {
        message.error(error)
      }
    })
  }

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Header bgColor={AuthSplashImage} hideHeader="none">
          <BlurOverlay>
            <Card>
              <h5>Synchronize Surgical Success with Seamless Collaboration</h5>
              <Description>
                Upgrade to Collaborate and Innovate approach
              </Description>
            </Card>
          </BlurOverlay>
        </Header>
      </div>
      <div style={{ flex: 1 }} className="my-auto mx-3">
        <div>
          <FormContainer>
            <div className="text-center">
              <Image
                className="text-center"
                src={CompanyLogo}
                preview={false}
              />
            </div>
            <h4 className="text-start mt-4">Forgot Password?</h4>
            <AuthDescription className="text-start">
              Please enter your new password
            </AuthDescription>
            <Form onFinish={onFinish} layout="vertical">
              <AuthFormItem
                label="New Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" }
                ]}
              >
                <Input.Password placeholder="Enter your password" />
              </AuthFormItem>
              <AuthFormItem
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  { required: true, message: "Please input your password!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      )
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Re-enter your password" />
              </AuthFormItem>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-100 mt-2">
                    Update Password
                </Button>
              </Form.Item>
            </Form>
          </FormContainer>
        </div>
      </div>
    </div>
  )
}

const FormContainer = styled.div`
  width: 428px;
  height: 100%;
  margin: auto;
  padding: 20px;
`

export default ResetPassword
