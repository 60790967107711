import { Table } from "antd"
import React from "react"
import styled from "styled-components"

const CustomTable = ({ tableHeading, columns, data }) => {
  return (
    <>
      {data?.length ? (
        <div className="p-3">
          <CardDescription className="bg-white p-3">
            {tableHeading}
          </CardDescription>
          <StyledTable columns={columns} dataSource={data} pagination={false} />
        </div>
      ) : null}
    </>
  )
}

const CardDescription = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0d1e40;
`

const StyledTable = styled(Table)`
  & .ant-table-cell,
  .ant-table-cell::before {
    border: none;
  }
  & th.ant-table-cell {
    background-color: #f5f8fe !important;
  }
`

export default CustomTable
