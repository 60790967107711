import {
  Breadcrumb,
  Button,
  Card,
  Divider,
  Dropdown,
  Image,
  Table,
  message
} from "antd"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  LeftOutlined,
  DownloadOutlined,
  EditOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  CopyOutlined,
  DeleteOutlined
} from "@ant-design/icons"
import { useEffect, useState } from "react"
import {
  deleteMyPreferenceCard,
  duplicatePrefCard,
  getSinglePreferenceCard
} from "../../../../api/DataService"
import DefaulImage from "../../../../assets/DefaultImage.png"
import { AuthFormItem, PrefCardHeading } from "../../../../styledComponents"
import CustomTable from "../../../../components/CustomTable"
import {
  detailColumns,
  detailDescriptionColumns
} from "../../../../constants/Headers"
import styled from "styled-components"
import DeleteModal from "../../../../components/DeleteModal"
import PDFFile from "../../../PDFFile"
import { usePDF } from "react-to-pdf"
import DuplicateModal from "../../../../components/DuplicateModal"

const PreferenceCardDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [prefCardData, setPrefCardData] = useState({})
  const { toPDF, targetRef } = usePDF({ filename: prefCardData?.procedure_name })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false)
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [deleteModalValues, setDeleteModalValues] = useState(null)
  const location = useLocation()
  const state = location.state || {}

  const duplicatePreferenceCard = () => {
    duplicatePrefCard(id).then(({ data: res }) => {
      if(res.success) {
        message.success("Preference Card duplicated successfully")
        setIsDuplicateOpen(false)
      }
    })
  }

  const items = prefCardData => [
    {
      label: (
        <div className="d-flex gap-2 me-4" onClick={() => setIsDuplicateOpen(true)}>
          <CopyOutlined />
          Duplicate
        </div>
      ),
      key: "0"
    },
    {
      label: (
        <div
          style={{ color: "#D92D20" }}
          onClick={e => {
            e.stopPropagation()
            setIsModalOpen(true)
            setDeleteModalValues(prefCardData)
          }}
          className="d-flex gap-2 me-4"
        >
          <DeleteOutlined />
          Delete
        </div>
      ),
      key: "1"
    }
  ]

  useEffect(() => {
    getSinglePreferenceCard(id).then(({ data: res }) => {
      setPrefCardData(res?.data)
    })
  }, [id])

  const handleDelete = () => {
    deleteMyPreferenceCard([deleteModalValues.id]).then(() => {
      message.success("Preference Card deleted successfully")
      setIsModalOpen(false)
      navigate(-1)
    })
  }

  return (
    <>
      <DuplicateModal
        isOpen={isDuplicateOpen}
        setIsOpen={setIsDuplicateOpen}
        duplicateHandler={duplicatePreferenceCard}
        headingText={`Are you sure, you want to duplicate this record?`}
        descText="By doing this action, this record will be duplicated and saved as a new record."
      />
      <DeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        deleteHandler={handleDelete}
        headingText="Are you sure, you want to Delete this record?"
        descText="By deleting this data, you’ll no longer be able to access it"
      />
      <div className="w-100" style={{ margin: 0 }}>
        <Breadcrumb className="m-2 mx-3" style={{ margin: 0 }}>
          <Breadcrumb.Item>General Cards</Breadcrumb.Item>
          <Breadcrumb.Item>{prefCardData?.procedure_name}</Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{ margin: 0 }} />
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center m-3 gap-2">
              <LeftOutlined onClick={() => navigate(-1)} />
              <h4 style={{ margin: 0 }}>{prefCardData?.procedure_name}</h4>
            </div>
            <div className="d-flex gap-2 me-3">
              {state == "personal" && (
                <Button
                  className="rounded-0 d-flex align-items-center"
                  style={{
                    background: "#FBEEED",
                    border: "1px solid #FBEEED",
                    color: "#D92D20"
                  }}
                  onClick={() =>
                    navigate("/suggestion-requests", { state: prefCardData })
                  }
                >
                  <ExclamationCircleOutlined /> {prefCardData?.total_suggestions} Suggestions
                </Button>
              )}
              <Button
                className="rounded-0 d-flex align-items-center"
                onClick={() => toPDF()}
              >
                <DownloadOutlined />
                Export as PDF
              </Button>
              {state == "personal" && (
                <>
                  <Button
                    className="rounded-0 d-flex align-items-center"
                    onClick={() =>
                      navigate("/edit-preferencecard", { state: prefCardData })
                    }
                  >
                    <EditOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      items: items(prefCardData)
                    }}
                    trigger={["click"]}
                  >
                    <Button
                      className="d-flex align-items-center rounded-0"
                      onClick={() => setIsDropDownOpen(prev => !prev)}
                    >
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
          <div className="d-flex">
            <div style={{ flexBasis: "7%" }} className="text-center">
              <Image
                className="rounded-circle text-center"
                src={prefCardData?.owner_image || DefaulImage}
                preview={false}
                width={36}
                height={36}
              />
            </div>
            <div className="d-flex" style={{ flexBasis: "90%" }}>
              <div style={{ flexBasis: "50%" }}>
                <PrefCardHeading>
                  {prefCardData?.owner_designation || "---"}
                </PrefCardHeading>
                <div className="mb-3">{prefCardData?.owner_name || "---"}</div>
                <PrefCardHeading>Glove Size</PrefCardHeading>
                <div>{prefCardData?.glove_size || "---"}</div>
              </div>
              <div style={{ flexBasis: "50%" }}>
                <PrefCardHeading>Position</PrefCardHeading>
                <div className="mb-3">{prefCardData?.description || "---"}</div>
                <PrefCardHeading>Created On</PrefCardHeading>
                <div>{prefCardData?.create_at || "---"}</div>
              </div>
            </div>
          </div>
          <div
            className="bg-light mt-3"
            style={{ height: "64vh", overflow: "auto" }}
          >
            <CustomTable
              tableHeading="Equipments"
              columns={detailColumns("equipment_name")}
              data={prefCardData?.equipments}
            />
            <CustomTable
              tableHeading="Instruments"
              columns={detailColumns("instrument_name")}
              data={prefCardData?.instruments}
            />
            <CustomTable
              tableHeading="Supplies"
              columns={detailColumns("supply_name")}
              data={prefCardData?.supplies}
            />
            <CustomTable
              tableHeading="Medications"
              columns={detailColumns("medication_name")}
              data={prefCardData?.medications}
            />
            <CustomTable
              tableHeading="Suture"
              columns={detailColumns("suture_name")}
              data={prefCardData?.sutures}
            />
            <CustomTable
              tableHeading="Dressings"
              columns={detailColumns("dressing_name")}
              data={prefCardData?.dressings}
            />
            <CustomTable
              tableHeading="Special Requirements"
              columns={detailColumns("special_requirement_name")}
              data={prefCardData?.special_requirements}
            />
            <div className="p-3">
              <Heading className="bg-white p-3">Notes</Heading>
              <SubHeading
                className="p-3"
                style={{ backgroundColor: "#F5F8FE" }}
              >
                Description
              </SubHeading>
              <div className="bg-white p-3">{prefCardData?.notes}</div>
            </div>
          </div>
        </div>
        <PDFFile targetRef={targetRef} prefCardData={prefCardData} />
      </div>
    </>
  )
}

export const Heading = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0d1e40;
`

export const SubHeading = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0d1e40;
`

export default PreferenceCardDetail
