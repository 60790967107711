import React, { useContext, useEffect, useState } from "react"
import { LeftOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { Badge } from "react-bootstrap"
import { Flex, Menu } from "antd"
import { PackageTopHeading, Text } from "../../styledComponents"
import StyledMenu from "../../components/StyledMenu"
import AllNotification from "./AllNotification"
import ReadNotification from "./ReadNotification"
import UnreadNotification from "./UnreadNotification"
import {
  deleteAllNotification,
  markAllAsReadNotification,
  unreadNotificationCount
} from "../../api/DataService"
import GlobalContext from "../../components/GlobalContext"

const ViewAllNotification = () => {
  const [current, setCurrent] = useState("all")
  const { notificationCountGlobal, notificationCountGlobalHandler } = useContext(GlobalContext);
  const [loadNotification, setLoadNotification] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    unreadNotificationCount().then(({ data: res }) => {
      if (res?.success) {
        notificationCountGlobalHandler(res?.data?.unread_count)
      }
    })
  }, [])

  const markAllReadHandler = () => {
    markAllAsReadNotification().then(({ data: res }) => {
      if (res.success) {
        console.log("All notification marked as read")
        setLoadNotification(true)
        notificationCountGlobalHandler(0)
      }
    })
  }

  const clearAllHandler = () => {
    deleteAllNotification().then(({ data: res }) => {
      if (res.success) {
        console.log("All notification cleared")
        setLoadNotification(true)
        notificationCountGlobalHandler(0)
      }
    })
  }

  const notificationTabMenu = [
    {
      label: "All",
      key: "all"
    },
    {
      label: "Read",
      key: "read"
    },
    {
      label: "Unread",
      key: "unread"
    }
  ]

  return (
    <>
      <div className="w-100">
        <Flex className="m-3 gap-2" align="start">
          <LeftOutlined onClick={() => navigate(-1)} className="mt-2" />
          <Flex vertical align="start" className="gap-2">
            <Flex align="center" className="gap-2">
              <h4 style={{ margin: 0 }}>Notifications</h4>
              <Badge pill bg="primary">
                {notificationCountGlobal}
              </Badge>
            </Flex>
            <Text>You have unread notifications</Text>
          </Flex>
        </Flex>
        <div className="bg-light">
          <div className="w-50 m-auto">
            <Menu
              className="bg-light"
              onClick={e => setCurrent(e.key)}
              selectedKeys={current}
              mode="horizontal"
              items={notificationTabMenu}
            />
            <Flex className="gap-2 my-3" justify="end">
              <PackageTopHeading
                fontWeight="500"
                className="text-end"
                onClick={() => markAllReadHandler()}
                style={{ cursor: "pointer" }}
              >
                Mark all as read
              </PackageTopHeading>
              <PackageTopHeading
                fontWeight="500"
                className="text-end text-danger"
                onClick={() => clearAllHandler()}
                style={{ cursor: "pointer" }}
              >
                Clear All
              </PackageTopHeading>
            </Flex>
            {current === "all" && (
              <AllNotification loadNotification={loadNotification} />
            )}
            {current === "read" && (
              <ReadNotification loadNotification={loadNotification} />
            )}
            {current === "unread" && (
              <UnreadNotification loadNotification={loadNotification} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAllNotification
