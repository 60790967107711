import React, { useEffect, useState } from "react"
import { Form, Input, Button, Image, Checkbox, message } from "antd"
import CompanyLogo from "../../assets/Company-Logo.svg"
import AuthSplashImage from "../../assets/Login-Splash-Image.jpg"
import styled from "styled-components"
import {
  AuthDescription,
  AuthField,
  AuthFormItem,
  BlurOverlay,
  Card,
  Description,
  Header,
  Text
} from "../../styledComponents"
import { useNavigate } from "react-router-dom"
import { login } from "../../api/DataService"
import OneSignal from "react-onesignal"

const Login = () => {
  const [remember, setRemember] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const initializeOneSignal = () => {
    OneSignal.init({
      appId: "0b1003bb-e45c-4436-90f3-48fb8e1798b8"
    }).then(() => {
      OneSignal.Slidedown.promptPush()
    })
  }

  useEffect(() => {
    const remember = localStorage.getItem("remember") === "true"
    const email = localStorage.getItem("email") || ""
    const password = localStorage.getItem("password") || ""

    if (remember) {
      setRemember(true)
      setEmail(email)
      setPassword(password)
      form.setFieldsValue({ email, password, remember })
    }
  }, [form])

  const handleRememberMe = event => {
    const checked = event.target.checked
    setRemember(checked)
    const email = form.getFieldValue("email")
    const password = form.getFieldValue("password")

    if (checked) {
      localStorage.setItem("remember", "true")
      localStorage.setItem("email", email)
      localStorage.setItem("password", password)
    } else {
      localStorage.removeItem("remember")
      localStorage.removeItem("email")
      localStorage.removeItem("password")
    }
  }

  useEffect(() => {
    const remember = localStorage.getItem("remember") === "true"
    const email = localStorage.getItem("email") || ""
    const password = localStorage.getItem("password") || ""

    if (remember) {
      setRemember(true)
      setEmail(email)
      setPassword(password)
      form.setFieldsValue({ email, password, remember })
    }
  }, [form])

  const onFinish = values => {
    login(values.email, values.password).then(({ data: res, error }) => {
      if (res?.status === 200) {
        message.success(res?.message)
        localStorage.setItem("token", res.data?.token)
        localStorage.setItem("user", JSON.stringify(res.data?.user))
        initializeOneSignal()
        OneSignal.Notifications.addEventListener("permissionChange", () => {
          OneSignal.login(`${res.data?.user.id}`)
        })
        if (res.data?.user?.role === "surgeon") {
          navigate("/mypreferencecard-listing")
        } else {
          navigate("/general-card-listing")
        }
        return
      }
      if (error) {
        message.error(error[0])
      }
    })
  }

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Header bgColor={AuthSplashImage} hideHeader="none">
          <BlurOverlay>
            <Card>
              <h5>Synchronize Surgical Success with Seamless Collaboration</h5>
              <Description>
                Upgrade to Collaborate and Innovate approach
              </Description>
            </Card>
          </BlurOverlay>
        </Header>
      </div>
      <div style={{ flex: 1 }} className="my-auto mx-3">
        <FormContainer>
          <div className="text-center">
            <Image className="text-center" src={CompanyLogo} preview={false} />
          </div>
          <h4 className="text-start mt-4">Log In</h4>
          <AuthDescription className="text-start">
            Welcome Back! Please enter your credentials.
          </AuthDescription>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{ email, password, remember }}
          >
            <AuthFormItem
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!"
                },
                {
                  type: "email",
                  message: "Please enter a valid email address!"
                }
              ]}
            >
              <Input placeholder="Enter your email" maxLength={255}/>
            </AuthFormItem>

            <AuthFormItem
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" }
              ]}
            >
              <Input.Password placeholder="Enter your password" maxLength={255}/>
            </AuthFormItem>
            <AuthFormItem name="remember" valuePropName="checked">
              <div className="d-flex justify-content-between align-items-baseline mb-3">
                <Checkbox onChange={handleRememberMe} checked={remember}>
                  <AuthField>Remember me</AuthField>
                </Checkbox>
                <AuthField
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/forget-password")}
                >
                  Forget password?
                </AuthField>
              </div>
            </AuthFormItem>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="w-100 mt-3">
                Log in
              </Button>
            </Form.Item>
          </Form>
          <Text
            className="text-center d-flex justify-content-center"
            fontWeight="400"
          >
            Don't have an account?{" "}
            <Text
              className="ms-1 text-decoration-underline"
              fontWeight="600"
              color="#1B2B4B"
              onClick={() => navigate("/signup")}
              style={{ cursor: "pointer" }}
            >
              Sign up
            </Text>
          </Text>
        </FormContainer>
      </div>
    </div>
  )
}

const FormContainer = styled.div`
  width: 428px;
  height: 100%;
  margin: auto;
  padding: 20px;
`

export default Login
