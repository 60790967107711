import React, { useEffect, useState } from "react"
import { addItem, fetchCategories } from "../api/DataService"
import { Button, Form, Image, Input, Modal, Select, message } from "antd"
import { useNavigate } from "react-router-dom"
import { UserText } from "../styledComponents"
import Dragger from "antd/es/upload/Dragger"
import { InboxOutlined } from "@ant-design/icons"
import styled from "styled-components"

const CreateItemModal = ({ isOpen, setIsOpen, setSelectedCategory, selectedCategory }) => {
  const [form] = Form.useForm()
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    fetchCategories().then(({ data: res }) => {
      console.log("res = ", res);
      if (res?.status === 200) {
        const categories = res?.data.map(({ id, name }) => ({
          label: name,
          value: id
        }))
        setCategories(categories)
      }
    })
  }, [])

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({ category: categories.find(item => item.label === selectedCategory).value })
    }
  }, [isOpen])

  const handleSubmit = values => {
    setLoading(true)
    const formData = new FormData()
    image && formData.append("image", image)
    formData.append("name", values.name)
    formData.append("category", values.category)
    addItem(formData).then(({ data: res, error }) => {
      if (res?.status === 201) {
        message.success(res?.message)
        setIsOpen(false)
        return
      }

      if (error) {
        Object.entries(error).forEach(([key, value]) => {
          message.error(value)
        })
      }
    })
    setLoading(false)
    setSelectedCategory(undefined)
    form.resetFields()
  }

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload(file) {
      const fileType = file.type
      if (
        fileType !== "image/png" &&
        fileType !== "image/jpg" &&
        fileType !== "image/jpeg"
      ) {
        message.error("File should be of type PNG, JPG, or JPEG")
        return
      }
      setImage(file)
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    }
  }
  return (
    <Modal
      title="Add New Item"
      open={isOpen}
      onCancel={() => {
        setSelectedCategory(undefined)
        form.resetFields()
        setIsOpen(false)
      }}
      footer={null}
      zIndex={1200}
      centered
    >
      <div className="w-100" style={{ margin: 0 }}>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            category: selectedCategory,
            name: undefined
          }}
        >
          <div
            className="d-flex flex-column gap-3 p-4"
            style={{ backgroundColor: "#F7F8F9" }}
          >
            <div className="d-flex flex-column gap-3">
              <Form.Item
                name="image"
                label="Upload Picture"
                style={{ margin: 0 }}
              >
                <Dragger {...props} className="w-100">
                  {image ? (
                    <Image
                      src={URL.createObjectURL(image)}
                      style={{ maxWidth: "249px", maxHeight: "140px" }}
                      preview={false}
                    />
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <div className="ant-upload-text">
                        Click or drag file to this area to upload
                      </div>
                      <div className="ant-upload-hint">
                        Support for a single file upload. Can be PNG, JPEG, JPG
                      </div>
                    </>
                  )}
                </Dragger>
              </Form.Item>
              <Form.Item
                name="category"
                label="Item Category"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Please select your category!"
                  }
                ]}
              >
                <Select placeholder="Select Category" options={categories} />
              </Form.Item>
              <Form.Item
                name="name"
                label="Item Name"
                style={{ margin: 0 }}
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input placeholder="Enter name" maxLength={255} />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            className="d-flex justify-content-end m-2"
            style={{ margin: 0 }}
          >
            <Button
              className="ms-2"
              type="default"
              onClick={() => {
                setSelectedCategory(undefined)
                form.resetFields()
                setIsOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="ms-2"
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default CreateItemModal
