export const RoleOptions = [
  { label: "Surgeon", value: "surgeon" },
  { label: "Surgical Technician", value: "surgical-technician" },
  { label: "Nurse", value: "nurse" },
]

export const CategoryOptions = [
  { label: "Equipment", value: "equipment" },
  { label: "Supplies", value: "supplies" },
  { label: "Medication", value: "medication" },
  { label: "Dressings", value: "dressings" },
  { label: "Instrument", value: "instrument" },
  { label: "Suture", value: "suture" },
  { label: "Dressings", value: "dressings" },
  { label: "Special Requirements", value: "special-requirements" },
]

