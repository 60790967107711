import React, { useEffect, useState } from "react"
import DeleteModal from "../../../../components/DeleteModal"
import { CapsuleTab, UserText } from "../../../../styledComponents"
import { Button, Card, Dropdown, Image, Input, message } from "antd"
import {
  TemplateDate,
  TemplateDescription,
  TemplateHeading,
  TemplateSubHeading,
  UseCardText
} from "../../../surgeon/preferenceCard/template/TemplateListing"
import { MoreOutlined, SearchOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { removeSavedProfile, saveProfileList } from "../../../../api/DataService"
import DefaultImage from "../../../../assets/DefaultImage.png"

const SavedProfileList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [listData, setListData] = useState([])
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [deleteModalValue, setDeleteModalValue] = useState(null)
  const navigate = useNavigate()

  const deleteSavedProfile = () => {
    removeSavedProfile(deleteModalValue).then(() => {
      message.success("Profile Removed Successfully")
      setIsModalOpen(false)
      saveProfileList(searchText).then(({ data: res }) => {
        setListData(res?.data)
      })
    })
  }

  useEffect(() => {
    const delay = 500;
    let timeoutId;

    const debounceSearch = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        saveProfileList(searchText).then(({ data: res }) => {
          setListData(res?.data);
        });
      }, delay);
    };

    debounceSearch();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        deleteHandler={deleteSavedProfile}
        headingText="Are you sure, you want to Delete this record?"
        descText="By deleting this data, you’ll no longer be able to access it"
      />
      <div className="m-4 flex-grow-1">
        <h3 className="mb-4">Saved Profiles</h3>
        <div className="d-flex justify-content-between mb-3">
          <Input
            placeholder="Search Profiles..."
            className="w-50"
            prefix={<SearchOutlined />}
            onChange={e => setSearchText(e.target.value)}
            allowClear
          />
        </div>
        <div className="d-flex gap-3 flex-wrap">
          {listData?.map(item => (
            <Card style={{ minWidth: "378px" }}>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-start gap-2">
                  <div className="d-flex flex-column gap-2" onClick={() => navigate(`/user-profile/${item.surgeon}`, { state: {id: item.id, surgeon: item.surgeon} })}>
                    <Image
                      className="rounded-circle"
                      src={item.surgeon_image || DefaultImage}
                      alt="profile"
                      width={40}
                      height={40}
                      preview={false}
                    />
                    <div className="d-flex gap-2">
                      <TemplateHeading>{item.surgeon_name}</TemplateHeading>
                      <CapsuleTab bgColor="#F5F8FE" color="#1D57C5">
                        {item.surgeon_role}
                      </CapsuleTab>
                    </div>
                    <TemplateDescription>
                      {item.surgeon_designation}
                    </TemplateDescription>
                  </div>
                  <UseCardText
                    onClick={() => {
                      setIsModalOpen(true)
                      setDeleteModalValue(item.surgeon)
                    }}
                    style={{ cursor: "pointer", color: "#D92D20" }}
                  >
                    Remove
                  </UseCardText>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </>
  )
}

export default SavedProfileList
