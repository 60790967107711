import { createContext, useEffect, useState } from "react"
import { getSingleUser, unreadNotificationCount } from "../api/DataService"

const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const [notificationCountGlobal, setNotificationCountGlobal] = useState(0)
  const [profilePicture, setProfilePicture] = useState()
  const userInfo = JSON.parse(localStorage.getItem("user"))

  const notificationCountGlobalHandler = (unread_count) => {
    setNotificationCountGlobal(unread_count)
  }

  return (
    <GlobalContext.Provider
      value={{ notificationCountGlobal, notificationCountGlobalHandler, profilePicture, setProfilePicture }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext;
