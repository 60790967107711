import React from "react"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

const PrivacyPolicy = () => {
  const navigate = useNavigate()
  return (
    <div className="m-5 p-5">
      <div className="mb-3">
        <strong>
          <h2 className="fw-bold">PRIVACY NOTICE</h2>
        </strong>
      </div>
      <div className="mb-5">
        <span className="fw-bold text-muted">Last updated May 22, 2024</span>
      </div>
      <div>
        <div className="text-muted">
          <span>
            This privacy notice for __________ ("<strong>we</strong>," "
            <strong>us</strong>," or "<strong>our</strong>"
          </span>
          <span>
            ), describes how and why we might collect, store, use, and/or share
            ("
            <strong>process</strong>") your information when you use our
            services ("<strong>Services</strong>"), such as when you:
          </span>
        </div>
        <div>
          <div>
            <div className="text-muted">
              <span>
                <strong>Questions or concerns?&nbsp;</strong>Reading this
                privacy notice will help you understand your privacy rights and
                choices. if you do not agree with our policies and practices,
                please do not use our services.
              </span>
            </div>
            <div className="mt-5 mb-4">
              <strong>
                <h5 className="fw-bold">SUMMARY OF KEY POINTS</h5>
              </strong>
            </div>
            <div className="text-muted mb-4">
              <span>
                <strong>
                  <em>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our&nbsp;
                  </em>
                </strong>
              </span>
              <a href="#toc" className="text-decoration-none">
                <span>
                  <strong>
                    <em>table of contents</em>
                  </strong>
                </span>
              </a>
              <span>
                <strong>
                  <em>&nbsp;below to find the section you are looking for.</em>
                </strong>
              </span>
            </div>
            <div className="text-muted mb-3">
              <span>
                <strong>What personal information do we process?</strong> When
                you visit, use, or navigate our services, we may process
                personal information depending on how you interact with us and
                the services, the choices you make, and the products and
                features you use. Learn more about&nbsp;
              </span>
              <a className="text-decoration-none" href="#personalinfo">
                <span>personal information you disclose to us</span>
              </a>
              <span>.</span>
            </div>
            <div className="text-muted mb-3">
              <span>
                <strong>
                  Do we process any sensitive personal information?
                </strong>{" "}
                We do not process sensitive personal information.
              </span>
            </div>
            <div className="text-muted mb-3">
              <span>
                <strong>
                  Do we collect any information from third parties?
                </strong>{" "}
                We may collect information from public databases, marketing
                partners, social media platforms, and other outside sources.
                Learn more about&nbsp;
              </span>
              <a className="text-decoration-none" href="#othersources">
                <span>information collected from other sources</span>
              </a>
              <span>.</span>
            </div>
            <div className="text-muted mb-3">
              <span>
                <strong>How do we process your information?</strong> We process
                your information to provide, improve, and administer our
                services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Learn more about&nbsp;
              </span>
              <a className="text-decoration-none" href="#infouse">
                <span>how we process your information</span>
              </a>
              <span>.</span>
            </div>
            <div className="text-muted mb-3">
              <span>
                <strong>
                  In what situations and with which parties do we share personal
                  information?
                </strong>{" "}
                We may share information in specific situations and with
                specific third parties. Learn more about&nbsp;
              </span>
              <a className="text-decoration-none" href="#whoshare">
                <span>
                  when and with whom we share your personal information
                </span>
              </a>
              <span>.</span>
            </div>
            <div className="text-muted mb-3">
              <span>
                <strong>What are your rights?</strong> Depending on where you
                are located geographically, the applicable privacy law may mean
                you have certain rights regarding your personal information.
                Learn more about&nbsp;
              </span>
              <a className="text-decoration-none" href="#privacyrights">
                <span>your privacy rights</span>
              </a>
              <span>.</span>
            </div>
            <div className="text-muted mb-3">
              <span>
                <strong>How do you exercise your rights?</strong> the easiest
                way to exercise your rights is by submitting a&nbsp;
              </span>
              <a
                className="text-decoration-none"
                href="https://app.termly.io/notify/ff0e2ee8-906b-4283-bba0-2e185b20dd74"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>data subject access request</span>
              </a>
              <span>
                , or by contacting us. We will consider and act upon any request
                in accordance with applicable data protection laws.
              </span>
            </div>
            <div className="text-muted mb-3">
              <span>
                Want to learn more about what we do with any information we
                collect?&nbsp;
              </span>
              <a className="text-decoration-none" href="#toc">
                <span>Review the privacy notice in full</span>
              </a>
              <span>.</span>
            </div>
            <div className="mt-5 mb-3" id="toc">
              <strong>
                <h5 className="fw-bold">TABLE OF CONTENTS</h5>
              </strong>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#infocollect">
                1. WHAT INFORMATION DO WE COLLECT?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#infouse">
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#whoshare">
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#cookies">
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#sociallogins">
                5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#intltransfers">
                6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#inforetain">
                7. HOW LONG DO WE KEEP YOUR INFORMATION?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#infominors">
                8. DO WE COLLECT INFORMATION FROM MINORS?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#privacyrights">
                9. WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#DNT">
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#policyupdates">
                11. DO WE MAKE UPDATES TO THIS NOTICE?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#contact">
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
            </div>
            <div className="mb-1">
              <a className="text-decoration-none" href="#request">
                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </a>
            </div>
            <div className="mt-5 mb-4 fw-bold" id="infocollect">
              <strong>
                <h5 className="fw-bold">1. WHAT INFORMATION DO WE COLLECT?</h5>
              </strong>
            </div>
            <div className="mb-3" id="personalinfo">
              <h6>
                <strong>Personal information you disclose to us</strong>
              </h6>
            </div>
            <div className="text-muted mb-3">
              <div>
                <span>
                  <span>
                    <strong>
                      <em>In Short: </em>
                    </strong>
                  </span>
                </span>
                <span>
                  <span>
                    <em>
                      We collect personal information that you provide to us.
                    </em>
                  </span>
                </span>
              </div>
            </div>
            <div className="text-muted mb-3">
              <span>
                We collect personal information that you voluntarily provide to
                us when you register on the services,&nbsp;
              </span>
              <span>
                express an interest in obtaining information about us or our
                products and services, when you participate in activities on the
                services, or otherwise when you contact us.
              </span>
            </div>
            <div className="text-muted mb-3">
              <span>
                <strong>Sensitive Information.</strong> We do not process
                sensitive information.
              </span>
            </div>
            <div className="text-muted mb-3">
              <span>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </div>
            <div className="mb-3" id="othersources">
              <h6>
                <strong>Information automatically collected</strong>
              </h6>
            </div>
            <div className="text-muted mb-3">
              <div>
                <span>
                  <span>
                    <strong>
                      <em>In Short: </em>
                    </strong>
                  </span>
                </span>
                <span>
                  <span>
                    <em>
                      Some information &mdash; such as your internet protocol
                      (ip) address and/or browser and device characteristics
                      &mdash; is collected automatically when you visit our
                      services.
                    </em>
                  </span>
                </span>
              </div>
            </div>
            <div className="text-muted mb-3">
              <span>
                We automatically collect certain information when you visit,
                use, or navigate the services. this information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your ip
                address, browser and device characteristics, operating system,
                language preferences, referring uRLs, device name, country,
                location, information about how and when you use our services,
                and other technical information. this information is primarily
                needed to maintain the security and operation of our services,
                and for our internal analytics and reporting purposes.
              </span>
            </div>
            <div className="text-muted mb-3">
              <span>
                Like many businesses, we also collect information through
                cookies and similar technologies.{" "}
              </span>
            </div>
            <div className="mt-5 mb-4" id="infouse">
              <strong>
                <h5 className="fw-bold">
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                </h5>
              </strong>
            </div>
            <div className="text-muted mb-3">
              <div>
                <span>
                  <span>
                    <strong>
                      <em>In Short:&nbsp;</em>
                    </strong>
                    <em>
                      We process your information to provide, improve, and
                      administer our services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent.
                    </em>
                  </span>
                </span>
              </div>
            </div>
            <div className="mb-3">
              <span className="text-muted">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our services, including:
                </strong>
              </span>
              <div className="mt-5 mb-4" id="whoshare">
                <strong>
                  <h5 className="fw-bold">
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </h5>
                </strong>
              </div>
              <div className="text-muted mb-3">
                <span>
                  <strong>
                    <em>In Short:</em>
                  </strong>
                  <em>
                    &nbsp;We may share information in specific situations
                    described in this section and/or with the following third
                    parties.
                  </em>
                </span>
              </div>
              <div className="text-muted mb-3">
                <span>
                  We may need to share your personal information in the
                  following situations:
                </span>
              </div>
              <ul className="text-muted mb-3">
                <li>
                  <span>
                    <strong>Business Transfers.</strong> We may share or
                    transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets,
                    financing, or acquisition of all or a portion of our
                    business to another company.
                  </span>
                </li>
              </ul>
              <div>
                <ul className="text-muted mb-3">
                  <li>
                    <span>
                      <strong>Affiliates.&nbsp;</strong>
                      We may share your information with our affiliates, in
                      which case we will require those affiliates to honor this
                      privacy notice. affiliates include our parent company and
                      any subsidiaries, joint venture partners, or other
                      companies that we control or that are under common control
                      with us.
                    </span>
                  </li>
                </ul>
                <ul className="text-muted mb-3">
                  <li>
                    <span>
                      <strong>Business Partners.</strong> We may share your
                      information with our business partners to offer you
                      certain products, services, or promotions.
                    </span>
                  </li>
                </ul>
                <div>
                  <div>
                    <div className="mt-5 mb-4" id="cookies">
                      <strong>
                        <h5 className="fw-bold">
                          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        <strong>
                          <em>In Short:</em>
                        </strong>
                        <em>
                          &nbsp;We may use cookies and other tracking
                          technologies to collect and store your information.
                        </em>
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        We may use cookies and similar tracking technologies
                        (like web beacons and pixels) to gather information when
                        you interact with our services. some online tracking
                        technologies help us maintain the security of our
                        services, prevent crashes, fix bugs, save your
                        preferences, and assist with basic site functions.
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        We also permit third parties and service providers to
                        use online tracking technologies on our services for
                        analytics and advertising, including to help manage and
                        display advertisements, to tailor advertisements to your
                        interests, or to send abandoned shopping cart reminders
                        (depending on your communication preferences). the third
                        parties and service providers use their technology to
                        provide advertising about products and services tailored
                        to your interests which may appear either on our
                        services or on other websites.
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        Specific information about how we use such technologies
                        and how you can refuse certain cookies is set out in our
                        Cookie Notice
                        <span>.</span>
                      </span>
                    </div>
                    <div className="mt-5 mb-4" id="sociallogins">
                      <strong>
                        <h5 className="fw-bold">
                          5. HOW DO WE HaNdlE YOuR sOCiaL LOGins?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          If you choose to register or log in to our services
                          using a social media account, we may have access to
                          certain information about you.
                        </em>
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        Our services offer you the ability to register and log
                        in using your third-party social media account details
                        (like your Facebook or X logins). Where you choose to do
                        this, we will receive certain profile information about
                        you from your social media provider. the profile
                        information we receive may vary depending on the social
                        media provider concerned, but will often include your
                        name, email address, friends list, and profile picture,
                        as well as other information you choose to make public
                        on such a social media platform.
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        We will use the information we receive only for the
                        purposes that are described in this privacy notice or
                        that are otherwise made clear to you on the relevant
                        services. please note that we do not control, and are
                        not responsible for, other uses of your personal
                        information by your third-party social media provider.
                        We recommend that you review their privacy notice to
                        understand how they collect, use, and share your
                        personal information, and how you can set your privacy
                        preferences on their sites and apps.
                      </span>
                    </div>
                    <div className="mt-5 mb-4" id="intltransfers">
                      <strong>
                        <h5 className="fw-bold">
                          6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          We may transfer, store, and process your information
                          in countries other than your own.
                        </em>
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        Our servers are located in
                        <span>
                          . if you are accessing our services from outside
                        </span>
                        <span>
                          , please be aware that your information may be
                          transferred to, stored, and processed by us in our
                          facilities and by those third parties with whom we may
                          share your personal information (see "
                        </span>
                        <a className="text-decoration-none" href="#whoshare">
                          WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                          INFORMATION?
                        </a>
                        <span>" above), in</span>
                        <span>&nbsp;and other countries.</span>
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        if you are a resident in the European Economic area
                        (EEa), united Kingdom (uK), or switzerland, then these
                        countries may not necessarily have data protection laws
                        or other similar laws as comprehensive as those in your
                        country. However, we will take all necessary measures to
                        protect your personal information in accordance with
                        this privacy notice and applicable law.
                      </span>
                    </div>
                    <div className="mt-5 mb-4" id="inforetain">
                      <strong>
                        <h5 className="fw-bold">
                          7. HOW LONG DO WE KEEP YOUR INFORMATION?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          We keep your information for as long as necessary to
                          fulfill the purposes outlined in this privacy notice
                          unless otherwise required by law.
                        </em>
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        We will only keep your personal information for as long
                        as it is necessary for the purposes set out in this
                        privacy notice, unless a longer retention period is
                        required or permitted by law (such as tax, accounting,
                        or other legal requirements).
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        When we have no ongoing legitimate business need to
                        process your personal information, we will either delete
                        or anonymize such information, or, if this is not
                        possible (for example, because your personal information
                        has been stored in backup archives), then we will
                        securely store your personal information and isolate it
                        from any further processing until deletion is possible.
                      </span>
                    </div>
                    <div className="mt-5 mb-4" id="infominors">
                      <strong>
                        <h5 className="fw-bold">
                          8. DO WE COLLECT INFORMATION FROM MINORS?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        <strong>
                          <em>In Short:</em>
                        </strong>
                        <em>
                          &nbsp;We do not knowingly collect data from or market
                          to children under 18 years of age.
                        </em>
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        We do not knowingly collect, solicit data from, or
                        market to children under 18 years of age, nor do we
                        knowingly sell such personal information. by using the
                        services, you represent that you are at least 18 or that
                        you are the parent or guardian of such a minor and
                        consent to such minor dependent&rsquo;s use of the
                        services. if we learn that personal information from
                        users less than 18 years of age has been collected, we
                        will deactivate the account and take reasonable measures
                        to promptly delete such data from our records. if you
                        become aware of any data we may have collected from
                        children under age 18, please contact us at{" "}
                        <span>__________</span>.
                      </span>
                    </div>
                    <div className="mt-5 mb-4" id="privacyrights">
                      <strong>
                        <h5 className="fw-bold">
                          9. WHAT ARE YOUR PRIVACY RIGHTS?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        <strong>
                          <em>In Short:</em>
                        </strong>
                        <em>
                          &nbsp;You may review, change, or terminate your
                          account at any time, depending on your country,
                          province, or state of residence.
                        </em>
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        <strong>
                          <u>Withdrawing your consent:</u>
                        </strong>{" "}
                        if we are relying on your consent to process your
                        personal information, which may be express and/or
                        implied consent depending on the applicable law, you
                        have the right to withdraw your consent at any time. You
                        can withdraw your consent at any time by contacting us
                        by using the contact details provided in the section "
                      </span>
                      <a className="text-decoration-none" href="#contact">
                        <span>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                      </a>
                      <span>" below.</span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        However, please note that this will not affect the
                        lawfulness of the processing before its withdrawal nor,
                        when applicable law allows, will it affect the
                        processing of your personal information conducted in
                        reliance on lawful processing grounds other than
                        consent.
                      </span>
                    </div>
                    <div className="mt-4 mb-3">
                      <h6>
                        <strong>Account Information</strong>
                      </h6>
                    </div>
                    <div className="text-muted">
                      <span>
                        If you would at any time like to review or change the
                        information in your account or terminate your account,
                        you can:
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        Upon your request to terminate your account, we will
                        deactivate or delete your account and information from
                        our active databases. However, we may retain some
                        information in our files to prevent fraud, troubleshoot
                        problems, assist with any investigations, enforce our
                        legal terms and/or comply with applicable legal
                        requirements.
                      </span>
                    </div>
                    <div className="mt-5 mb-4" id="DNT">
                      <strong>
                        <h5 className="fw-bold">
                          10. CONTROLS FOR DO-NOT-TRACK FEATURES
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        Most web browsers and some mobile operating systems and
                        mobile applications include a Do-Not-track ("DNT")
                        feature or setting you can activate to signal your
                        privacy preference not to have data about your online
                        browsing activities monitored and collected. at this
                        stage, no uniform technology standard for recognizing
                        and implementing DNT signals has been finalized. as
                        such, we do not currently respond to DNT browser signals
                        or any other mechanism that automatically communicates
                        your choice not to be tracked online. if a standard for
                        online tracking is adopted that we must follow in the
                        future, we will inform you about that practice in a
                        revised version of this privacy notice.
                      </span>
                    </div>
                    <div className="mt-5 mb-4" id="policyupdates">
                      <strong>
                        <h5 className="fw-bold">
                          11. DO WE MAKE UPDATES TO THIS NOTICE?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        <em>
                          <strong>In Short:&nbsp;</strong>
                          Yes, we will update this notice as necessary to stay
                          compliant with relevant laws.
                        </em>
                      </span>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        We may update this privacy notice from time to time. the
                        updated version will be indicated by an updated
                        "Revised" date at the top of this privacy notice. if we
                        make material changes to this privacy notice, we may
                        notify you either by prominently posting a notice of
                        such changes or by directly sending you a notification.
                        We encourage you to review this privacy notice
                        frequently to be informed of how we are protecting your
                        information.
                      </span>
                    </div>
                    <div className="mt-5 mb-4" id="contact">
                      <strong>
                        <h5 className="fw-bold">
                          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        If you have questions or comments about this notice, you
                        may <span>contact us by post at:</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        <span>__________</span>
                      </span>
                    </div>
                    <div>
                      <span>__________</span>
                    </div>
                    <div>
                      <span>__________</span>
                    </div>
                    <div className="mt-5 mb-4" id="request">
                      <strong>
                        <h5 className="fw-bold">
                          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                          COLLECT FROM YOU?
                        </h5>
                      </strong>
                    </div>
                    <div className="text-muted mb-3">
                      <span>
                        Based on the applicable laws of your country, you may
                        have the right to request access to the personal
                        information we collect from you, details about how we
                        have processed it, correct inaccuracies, or delete your
                        personal information. You may also have the right to
                        withdraw your consent to our processing of your personal
                        information. these rights may be limited in some
                        circumstances by applicable law. To request to review,
                        update, or delete your personal information, please fill
                        out and submit a&nbsp;
                      </span>
                      <span>
                        <a
                          className="text-decoration-none"
                          href="https://app.termly.io/notify/ff0e2ee8-906b-4283-bba0-2e185b20dd74"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          data subject access request
                        </a>
                      </span>
                      <span>.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
