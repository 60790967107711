import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Input, Table, Pagination, Select } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { generalCardListingNurseColumns } from "../../../../constants/Headers"
import {
  fetchSurgeonList,
  getPreferenceCardList,
  getSinglePreferenceCard
} from "../../../../api/DataService"
import { mapIdAndNameToLabelAndValue } from "../../../../util/commonUtil"
import PDFFile from "../../../PDFFile"
import { usePDF } from "react-to-pdf"

const GeneralCardListing = () => {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState("")
  const [surgeonFilter, setSurgeonFilter] = useState("")
  const [listData, setListData] = useState([])
  const [loader, setLoader] = useState(false)
  const [surgeonList, setSurgeonList] = useState([])
  const [prefCardData, setPrefCardData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const { toPDF, targetRef } = usePDF({ filename: prefCardData?.procedure_name })

  useEffect(() => {
    let timeoutId = null
    const delay = 500

    fetchSurgeonList().then(({ data: res }) => {
      setSurgeonList(mapIdAndNameToLabelAndValue(res?.data))
    })

    const fetchData = () => {
      setLoader(true)
      getPreferenceCardList(searchText, currentPage).then(({ data: res }) => {
        setListData(res?.data.results)
        setCount(res?.data.count)
        setLoader(false)
      })
    }

    const debounceFetchData = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(fetchData, delay)
    }

    debounceFetchData()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchText, surgeonFilter, currentPage])

  useEffect(() => {
    if (Object.keys(prefCardData).length) {
      toPDF()
    }
  }, [prefCardData])

  const exportPDF = record => {
    getSinglePreferenceCard(record.id).then(({ data: res }) => {
      setPrefCardData(res.data)
    })
  }

  return (
    <>
      <div className="m-4 flex-grow-1">
        <h3 className="mb-4">General Preference Cards</h3>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex gap-3">
            <Input
              placeholder="Search list..."
              prefix={<SearchOutlined />}
              onChange={e => setSearchText(e.target.value)}
              allowClear
            />
            <Select
              placeholder="Search surgeon..."
              onChange={e => setSurgeonFilter(e)}
              options={surgeonList}
              allowClear
            />
          </div>
        </div>
        <Table
          columns={generalCardListingNurseColumns(navigate, exportPDF)}
          dataSource={listData}
          pagination={false}
          loading={loader}
        />
        <Pagination
          className="text-end mt-3"
          total={count}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={10}
          onChange={page => setCurrentPage(page)}
        />
      </div>
      <PDFFile targetRef={targetRef} prefCardData={prefCardData} />
    </>
  )
}

export default GeneralCardListing
