import React, { useState } from "react"
import {
  Form,
  Input,
  Button,
  Image,
  message,
  Space,
  Select,
  InputNumber
} from "antd"
import CompanyLogo from "../../assets/Company-Logo.svg"
import AuthSplashImage from "../../assets/Login-Splash-Image.jpg"
import styled from "styled-components"
import {
  BlurOverlay,
  Description,
  Header,
  Text,
  Heading,
  Card,
  AuthDescription,
  AuthFormItem
} from "../../styledComponents"
import { CheckCircleOutlined } from "@ant-design/icons"
import { useLocation, useNavigate } from "react-router-dom"
import { signup } from "../../api/DataService"
import { ErrorConstant } from "../../constants/ErrorConstant"
import { Countries } from "../../constants/CountryCodes"

const ProfileSetup = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectCountryCode, setSelectCountryCode] =
    useState("United States_US_1")
  const updatedCountryCode = Countries.map(country => ({
    label: country.value,
    value: `${country.label}_${country.value}_${country.phoneCode}`
  }))
  const signUpFormValues = location.state || {}

  const onFinish = values => {
    const updatedValues = { ...values, ...signUpFormValues }
    updatedValues.phone_number = `${selectCountryCode.split("_")[1]}_${selectCountryCode.split("_")[2]}_${values.phone_number}`
    signup(updatedValues).then(({ data: res, error }) => {
      if (res?.success) {
        localStorage.setItem("token", res.data?.token)
        localStorage.setItem("user", JSON.stringify(res.data?.user))
        message.success(res.message)
        navigate("/package-selection")
        return
      }
      if (error) {
        Object.entries(error).forEach(([key, value]) => {
          if (value == ErrorConstant.USER_ALREADY_EXISTS) {
            return message.error(value)
          } else {
            return message.error(`${key} is required`)
          }
        })
      }
    })
  }

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Header bgColor={AuthSplashImage} hideHeader="none">
          <BlurOverlay>
            <Card>
              <h5>Synchronize Surgical Success with Seamless Collaboration</h5>
              <Description>
                Upgrade to Collaborate and Innovate approach
              </Description>
            </Card>
          </BlurOverlay>
        </Header>
      </div>
      <div style={{ flex: 1 }} className="my-auto mx-3">
        <div>
          <FormContainer>
            <div className="text-center">
              <Image
                className="text-center"
                src={CompanyLogo}
                preview={false}
              />
            </div>
            <h4 className="text-start mt-4">Setup Your Profile</h4>
            <AuthDescription className="text-start">
              Welcome aboard! Complete your account setup to personalize your
              experience!
            </AuthDescription>
            <Form onFinish={onFinish} layout="vertical">
              <AuthFormItem
                label="Full Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Full Name!"
                  }
                ]}
              >
                <Input placeholder="Enter your full name" maxLength={255}/>
              </AuthFormItem>

              <AuthFormItem
                label="Professional Title"
                name="designation"
                rules={[
                  { required: true, message: "Please input your Designation!" }
                ]}
              >
                <Input placeholder="Enter your designation" maxLength={255}/>
              </AuthFormItem>
              <AuthFormItem
                label="Role"
                name="role"
                rules={[
                  { required: true, message: "Please select your Role!" }
                ]}
              >
                <Select
                  placeholder="Select your role"
                  options={[
                    { label: "Surgeon", value: "surgeon" },
                    { label: "Nurse", value: "nurse" },
                    {
                      label: "Surgical Technician",
                      value: "surgical-technician"
                    }
                  ]}
                />
              </AuthFormItem>
              <AuthFormItem label="Phone Number" name="phone_number">
                <InputNumber
                  addonBefore={
                    <Select
                      defaultValue={selectCountryCode}
                      options={updatedCountryCode}
                      style={{ width: 100 }}
                      onChange={e => setSelectCountryCode(e)}
                      showSearch
                    />
                  }
                  placeholder="Enter your phone number"
                  type="number"
                  controls={false}
                  className="w-100"
                />
              </AuthFormItem>
              <AuthFormItem label="Organization Name" name="organization_name">
                <Input placeholder="Enter your organization name" maxLength={255}/>
              </AuthFormItem>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-100 mt-3">
                  Sign Up
                </Button>
                <Button
                  type="default"
                  htmlType="button"
                  className="w-100 mt-3"
                  onClick={() => navigate("/signup")}
                >
                  Back
                </Button>
              </Form.Item>
            </Form>
          </FormContainer>
        </div>
      </div>
    </div>
  )
}

const FormContainer = styled.div`
  width: 428px;
  height: 100%;
  margin: auto;
  padding: 20px;
`

export default ProfileSetup
