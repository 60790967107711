import { Breadcrumb, Input, Pagination, Select, Table } from "antd"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  LeftOutlined,
  DownloadOutlined,
  EditOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  DiffOutlined,
  SearchOutlined
} from "@ant-design/icons"
import { Divider, Image, Button } from "antd"
import {
  getSinglePreferenceCard,
  getUserProfile,
  removeSavedProfile,
  saveProfile
} from "../../../api/DataService"
import DefaulImage from "../../../assets/DefaultImage.png"
import CustomTable from "../../../components/CustomTable"
import { detailColumns, userProfilePrefListingColumns } from "../../../constants/Headers"
import styled from "styled-components"
import { CapsuleTab, PrefCardHeading } from "../../../styledComponents"
import {
  TemplateDescription,
  TemplateHeading
} from "../../surgeon/preferenceCard/template/TemplateListing"
import PDFFile from "../../PDFFile"
import { usePDF } from "react-to-pdf"

const UserProfileDetail = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state || {}
  const [userProfiledData, setUserProfiledData] = useState({})
  const [searchText, setSearchText] = useState("")
  const [loader, setLoader] = useState(false)
  const [prefCardData, setPrefCardData] = useState({})
  const { toPDF, targetRef } = usePDF({ filename: prefCardData?.procedure_name })


  const bookmarkProfile = () => {
    saveProfile(state.surgeon).then(() => {
      getUserProfile(state.surgeon).then(({ data: res }) => {
        setUserProfiledData(res.data)
      })
    })
  }

  const removeBookmarkProfile = () => {
    removeSavedProfile(state.surgeon).then(() => {
      getUserProfile(state.surgeon).then(({ data: res }) => {
        setUserProfiledData(res.data)
      })
    })
  }

  const exportPDF = record => {
    getSinglePreferenceCard(record.id)
      .then(({ data: res }) => {
        setPrefCardData(res.data)
      })
  }

  useEffect(() => {
    setLoader(true)
    getUserProfile(state.surgeon).then(({ data: res }) => {
      setUserProfiledData(res.data)
      setLoader(false)
    })
  }, [state])

  useEffect(() => {
    if(Object.keys(prefCardData).length){
      toPDF();
    }
  }, [prefCardData])

  return (
    <>
      <div className="w-100" style={{ margin: 0 }}>
        <Breadcrumb className="m-2 mx-3" style={{ margin: 0 }}>
          <Breadcrumb.Item>General Cards</Breadcrumb.Item>
          <Breadcrumb.Item>Search</Breadcrumb.Item>
          <Breadcrumb.Item>{userProfiledData?.surgeon?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{ margin: 0 }} />
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center m-3 mb-4 gap-2">
              <LeftOutlined
                onClick={() => navigate(-1)}
              />
              <h4 style={{ margin: 0 }}>User Profile</h4>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ flexBasis: "7%" }} className="text-center">
              <Image
                className="rounded-circle text-center"
                src={userProfiledData?.surgeon?.profile_image || DefaulImage}
                preview={false}
                width={36}
                height={36}
              />
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex gap-2">
                <TemplateHeading>
                  {userProfiledData?.surgeon?.name || "---"}
                </TemplateHeading>
                <CapsuleTab bgColor="#F5F8FE" color="#1D57C5">
                  {userProfiledData?.surgeon?.role || "---"}
                </CapsuleTab>
              </div>
              <TemplateDescription className="mb-3">
                {userProfiledData?.surgeon?.designation || "---"} &#x2022;{" "}
                {userProfiledData?.surgeon?.organization_name || "---"}
              </TemplateDescription>
            </div>
            {userProfiledData?.surgeon?.is_surgeon_bookmarked ? (
              <Button
                className="border-0 rounded-2 d-flex align-items-center ms-auto me-4"
                style={{ backgroundColor: "#F1F2F4", color: "#0D1E40" }}
                onClick={removeBookmarkProfile}
              >
                <CheckCircleOutlined />
                Saved Profile
              </Button>
            ) : (
              <Button
                className="rounded-2 d-flex align-items-center ms-auto me-4"
                style={{ backgroundColor: "#2968DC", color: "#FFFFFF" }}
                onClick={bookmarkProfile}
              >
                <DiffOutlined />
                Save Profile
              </Button>
            )}
          </div>
          <div
            className="mt-3"
            style={{ height: "64vh", overflow: "auto" }}
          >
            <>
              <div className="m-4 flex-grow-1">
                <h4 className="mb-4">Preference Cards</h4>
                <div className="d-flex justify-content-between mb-3">
                  <div className="d-flex gap-3">
                    <Input
                      placeholder="Search list..."
                      prefix={<SearchOutlined />}
                      onChange={e => setSearchText(e.target.value)}
                      allowClear
                    />
                  </div>
                </div>
                <Table
                  columns={userProfilePrefListingColumns(
                    navigate,
                    exportPDF,
                    state.surgeon
                  )}
                  dataSource={userProfiledData?.pref_cards}
                  pagination={false}
                  loading={loader}
                />
                <Pagination
                  className="text-end mt-3"
                  total={userProfiledData?.pref_cards?.length}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  defaultPageSize={20}
                  defaultCurrent={1}
                  pageSize={10}
                />
              </div>
            </>
          </div>
        </div>
        <PDFFile targetRef={targetRef} prefCardData={prefCardData}/>
      </div>
    </>
  )
}

export const Heading = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0d1e40;
`

export const SubHeading = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0d1e40;
`

export default UserProfileDetail
