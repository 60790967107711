import { Menu } from "antd"
import React from "react"
import styled from "styled-components"

const StyledMenu = ({onClick, selectedKeys, mode, items}) => {
  return (
    <StyledMenuItem
      onClick={(e) => onClick(e.key)}
      selectedKeys={[selectedKeys]}
      mode={mode}
      items={items}
    />
  )
}

const StyledMenuItem = styled(Menu)`
  & span.ant-menu-title-content {
    padding: 0 0.5rem;
  }
`

export default StyledMenu
