import { Breadcrumb, Divider, Space } from "antd"
import {
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined
} from "@ant-design/icons"
import { UserText } from "../../../../styledComponents"
import { useNavigate } from "react-router-dom"
import { Form, Input, Button, Select, Image, message } from "antd"
import { useEffect, useState } from "react"
import { addMyPreferenceCard, fetchItems } from "../../../../api/DataService"
import DynamicFieldPrefCard from "../../../../components/DynamicFieldPrefCard"
import { removeEmptyObjectsFromArray } from "../../../../util/commonUtil"
import SaveAsTemplateModal from "../../../../components/SaveAsTemplateModal"
import CreateItemModal from "../../../../components/CreateItemModal"

const AddMyPreferenceCard = () => {
  const [form] = Form.useForm()
  const [items, setItems] = useState([])
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [templateName, setTemplateName] = useState("")
  const [isCreateItemModalOpen, setIsCreateItemModalOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const [preferenceBtnClicked, setPreferenceBtnClicked] = useState(false)
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchItems().then(({ data: res }) => {
      const updatedItems = {}
      res?.data?.forEach(item => {
        if (!updatedItems[item.category_identifier]) {
          updatedItems[item.category_identifier] = []
        }

        updatedItems[item.category_identifier]?.push({
          label: item.name,
          value: item.id
        })
      })
      setItems(updatedItems)
    })
  }, [isCreateItemModalOpen])

  const handleSubmit = values => {
    const userData = JSON.parse(localStorage.getItem("user"))
    const formValues = removeEmptyObjectsFromArray(values)
    const updateValues = {
      ...formValues,
      status: preferenceBtnClicked ? "published" : "draft",
      surgeon_name: userData.name
    }

    if (!preferenceBtnClicked) updateValues.draft_name = templateName

    addMyPreferenceCard(updateValues).then(({ data: res, error }) => {
      if (res?.success) {
        message.success(res?.message)
        navigate("/mypreferencecard-listing")
        return
      }
      if (error) message.error(error?.message)
    })
  }

  const templateNameHandler = () => {
    if (templateName) {
      form.submit()
      setIsTemplateModalOpen(false)
      setPreferenceBtnClicked(false)
    }
  }

  return (
    <>
      <CreateItemModal
        isOpen={isCreateItemModalOpen}
        setIsOpen={setIsCreateItemModalOpen}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
      />
      <div className="w-100" style={{ margin: 0 }}>
        <Breadcrumb className="m-2 mx-3" style={{ margin: 0 }}>
          <Breadcrumb.Item>Preference Cards</Breadcrumb.Item>
          <Breadcrumb.Item>Add a new Preference Card</Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{ margin: 0 }} />
        <SaveAsTemplateModal
          isOpen={isTemplateModalOpen}
          setIsOpen={setIsTemplateModalOpen}
          templateName={templateName}
          setTemplateName={setTemplateName}
          templateNameHandler={templateNameHandler}
        />
        <div>
          <div className="d-flex align-items-center m-3 gap-2">
            <LeftOutlined
              onClick={() => navigate("/mypreferencecard-listing")}
            />
            <h4 style={{ margin: 0 }}>Add a new Preference Card</h4>
          </div>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
              procedure_name: "",
              description: "",
              glove_size: "",
              equipments: [{ equipment: undefined, quantity: 1 }],
              instruments: [{ instrument: undefined, quantity: 1 }],
              supplies: [{ supply: undefined, quantity: 1 }],
              medications: [{ medication: undefined, quantity: 1 }],
              sutures: [{ suture: undefined, quantity: 1 }],
              dressings: [{ dressing: undefined, quantity: 1 }],
              special_requirements: [
                { special_requirement: undefined, quantity: 1 }
              ],
              notes: ""
            }}
          >
            <div
              className="d-flex flex-column gap-3 p-4"
              style={{ backgroundColor: "#F7F8F9" }}
            >
              <div className="d-flex mb-4">
                <div style={{ flexBasis: "40%" }}>
                  <h5>Procedure Details</h5>
                  <UserText>Add details about the procedure</UserText>
                </div>
                <div
                  style={{ flexBasis: "50%" }}
                  className="d-flex flex-column gap-3"
                >
                  <Form.Item
                    name="procedure_name"
                    label="Procedure Name"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Please input procedure name!"
                      }
                    ]}
                  >
                    <Input placeholder="Enter name" maxLength={255} />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="Position Description"
                    style={{ margin: 0 }}
                    rules={[
                      { required: true, message: "Please select description!" }
                    ]}
                  >
                    <Input placeholder="Enter detail" maxLength={255} />
                  </Form.Item>
                  <Form.Item
                    name="glove_size"
                    label="Glove Size"
                    style={{ margin: 0 }}
                    rules={[
                      { required: true, message: "Please input glove size!" }
                    ]}
                  >
                    <Input placeholder="Enter glove size" maxLength={255} />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex mb-4">
                <div style={{ flexBasis: "40%" }}>
                  <h5>Items Details</h5>
                  <UserText>Add Items used in the procedure</UserText>
                </div>
                <div style={{ flexBasis: "50%" }}>
                  <DynamicFieldPrefCard
                    form={form}
                    name="equipments"
                    label="Equipments"
                    itemLabel="Equipment"
                    itemName="equipment"
                    itemPlaceholder="Select Equipment"
                    itemOptions={items?.equipment || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="instruments"
                    label="Instruments"
                    itemLabel="Instrument"
                    itemName="instrument"
                    itemPlaceholder="Select Instrument"
                    itemOptions={items?.instrument || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="supplies"
                    label="Supplies"
                    itemLabel="Supply"
                    itemName="supply"
                    itemPlaceholder="Select supplies"
                    itemOptions={items?.supply || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="medications"
                    label="Medication"
                    itemLabel="Medication"
                    itemName="medication"
                    itemPlaceholder="Select medications"
                    itemOptions={items?.medication || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="sutures"
                    label="Suture"
                    itemLabel="Suture"
                    itemName="suture"
                    itemPlaceholder="Select suture"
                    itemOptions={items?.suture || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="dressings"
                    label="Dressings"
                    itemLabel="Dressing"
                    itemName="dressing"
                    itemPlaceholder="Select dressing"
                    itemOptions={items?.dressing || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="special_requirements"
                    label="Special Requirements"
                    itemLabel="Special Requirement"
                    itemName="special_requirement"
                    itemPlaceholder="Select requirements"
                    itemOptions={items["special_requirement"] || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <Form.Item name="notes" label="Special Notes">
                    <Input.TextArea placeholder="Add notes here" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end p-2">
              <Button
                className="me-auto"
                type="default"
                htmlType="button"
                onClick={() => navigate("/mypreferencecard-listing")}
              >
                Cancel
              </Button>
              <div>
                <Button
                  className="ms-2"
                  htmlType="button"
                  onClick={() => setIsTemplateModalOpen(true)}
                >
                  Save as a Template
                </Button>
                <Button
                  className="ms-2"
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.submit()
                    setPreferenceBtnClicked(true)
                  }}
                >
                  Save & Publish
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

export default AddMyPreferenceCard
