import { Button, Checkbox, Form, Input, Switch } from "antd"
import React, { useEffect, useState } from "react"
import { UserText } from "../../../styledComponents"
import {
  fetchNotificationSettings,
  updateNotificationSettings
} from "../../../api/DataService"

const Notification = () => {
  const [enableNotification, setEnableNotification] = useState(false)
  const [emailChecked, setEmailChecked] = useState(false)
  const [pushChecked, setPushChecked] = useState(false)

  const [form] = Form.useForm()
  const updateSetting = values => {
    updateNotificationSettings(values).then(res => {
      console.log(res)
    })
  }

  useEffect(() => {
    fetchNotificationSettings()
      .then(({ data: res }) => {
        if (res?.success) {
          const enable =
            res.data.email_notif_enabled || res.data.push_notif_enabled
          setEnableNotification(enable)
          setEmailChecked(enable ? res.data.email_notif_enabled : false)
          setPushChecked(enable ? res.data.push_notif_enabled : false)
        }
      })
      .catch(error => {
        console.error("Error fetching notification settings:", error)
      })
  }, [])

  return (
    <div>
      <div
        className="d-flex flex-column gap-3 p-4"
        style={{ backgroundColor: "#F7F8F9" }}
      >
        <div className="d-flex mb-4">
          <div style={{ flexBasis: "40%" }}>
            <h5>Enable Notifications?</h5>
            <UserText>Turm On / Off notifications</UserText>
          </div>
          <div
            style={{ flexBasis: "50%" }}
            className="d-flex flex-column gap-3 justify-content-center"
          >
            <div>
              <Switch
                size="small"
                onChange={e => {
                  setEnableNotification(e)
                  setEmailChecked(e)
                  setPushChecked(e)
                  updateSetting({
                    email_notif_enabled: e,
                    push_notif_enabled: e
                  })
                }}
                className="me-2"
                checked={enableNotification}
              />
              {enableNotification ? "Enabled" : "Disabled"}
            </div>
          </div>
        </div>
        <div className="d-flex mb-4">
          <div style={{ flexBasis: "40%" }}>
            <h5>Notification Alerts</h5>
            <UserText>Plan your alerts accordingly</UserText>
          </div>
          <div
            style={{ flexBasis: "50%" }}
            className="d-flex flex-column gap-3"
          >
            <Checkbox
              onChange={e => {
                setEmailChecked(e.target.checked)
                updateSetting({
                  email_notif_enabled: e.target.checked
                })
                !e.target.checked &&
                  !pushChecked &&
                  setEnableNotification(false)
                e.target.checked && pushChecked && setEnableNotification(true)
              }}
              checked={emailChecked}
            >
              Email
            </Checkbox>
            <Checkbox
              onChange={e => {
                setPushChecked(e.target.checked)
                updateSetting({
                  push_notif_enabled: e.target.checked
                })
                !e.target.checked &&
                  !emailChecked &&
                  setEnableNotification(false)
                e.target.checked && emailChecked && setEnableNotification(true)
              }}
              checked={pushChecked}
            >
              Push
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notification
