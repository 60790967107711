import { Button, Input, Modal } from "antd"
import React, { useState } from "react"
import { UserText } from "../styledComponents"

const SaveAsTemplateModal = ({
  isOpen,
  setIsOpen,
  templateName,
  setTemplateName,
  templateNameHandler
}) => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      width={"460px"}
      height={"222px"}
      closeIcon={false}
      centered
    >
      <h5>Save as Template</h5>
      <label className="mb-1 mt-2">Name</label>
      <Input
        placeholder="Enter name"
        onChange={e => setTemplateName(e.target.value)}
        className="mb-3"
        value={templateName}
        maxLength={255}
      />
      <div className="d-flex gap-2 justify-content-end">
        <Button
          type="default"
          onClick={() => {
            setIsOpen(false)
            setTemplateName("")
          }}
        >
          Cancel
        </Button>
        <Button type="primary" onClick={() => templateNameHandler()}>
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default SaveAsTemplateModal
