import React, { useContext, useEffect, useRef, useState } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import {
  BellOutlined,
  LogoutOutlined,
  UserOutlined,
  SettingOutlined,
  FileOutlined,
  DeleteOutlined,
  InboxOutlined
} from "@ant-design/icons"
import { ReactComponent as CompanyLogo } from "../assets/Company-Logo.svg"
import styled from "styled-components"
import Dropdown from "react-bootstrap/Dropdown"
import HeadingComponent from "./Heading"
import DefaultImage from "../assets/DefaultImage.png"
import {
  deleteNotification,
  fetchAllNotification,
  fetchNotification,
  markAllAsReadNotification,
  unreadNotificationCount
} from "../api/DataService"
import {
  AuthDescription,
  CapsuleTab,
  Heading,
  PackageHeading,
  PackageTopHeading,
  Text,
  UserText
} from "../styledComponents"
import Badge from "react-bootstrap/Badge"
import { Flex, Image, Badge as AntBadge, Avatar, Skeleton, message } from "antd"
import OneSignal from "react-onesignal"
import GlobalContext from "./GlobalContext"
import { convertToTitleCaseWithSpaces } from "../util/commonUtil"

const HeaderNav = () => {
  let navigate = useNavigate()
  const {
    notificationCountGlobal,
    notificationCountGlobalHandler,
    profilePicture
  } = useContext(GlobalContext)
  const [isNotificationLoading, setIsNotificationLoading] = useState(false)
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [notificationList, setNotificationList] = useState([])
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const dropdownRef = useRef(null)
  const notificationDropdownRef = useRef(null)
  let pathName = useLocation().pathname

  useEffect(() => {
    OneSignal.Notifications.addEventListener(
      "foregroundWillDisplay",
      foregroundWillDisplayListener
    )
    unreadNotificationCount().then(({ data: res }) => {
      notificationCountGlobalHandler(res?.data?.unread_count)
    })

    return () => {
      OneSignal.Notifications.removeEventListener(
        "foregroundWillDisplay",
        foregroundWillDisplayListener
      )
    }
  }, [])

  const profileMenus = [
    {
      icon: <SettingOutlined />,
      name: "Profile Settings",
      NavTo: "",
      onClick: () => navigate("/setting")
    },
    {
      icon: <FileOutlined />,
      name: "Terms & Conditions",
      NavTo: "",
      onClick: () => navigate("/terms-and-conditions")
    },
    {
      icon: <FileOutlined />,
      name: "Privacy Policy",
      NavTo: "",
      onClick: () => navigate("/privacy-policy")
    }
  ]

  const handleDropdownItemClick = event => {
    event.preventDefault()
    if (dropdownRef.current) {
      dropdownRef.current.blur()
    }
  }

  const handleNotificationDropdownClick = event => {
    event.preventDefault()
    if (notificationDropdownRef.current) {
      notificationDropdownRef.current.blur()
    }
  }

  const getNotification = read => {
    setIsNotificationLoading(true)
    fetchNotification(read).then(({ data: res }) => {
      setNotificationList(res)
      setIsNotificationLoading(false)
    })
  }

  const foregroundWillDisplayListener = event => {
    notificationCountGlobalHandler(prevNotification => prevNotification + 1)
  }

  const markAllReadHandler = () => {
    markAllAsReadNotification().then(({ data: res }) => {
      if (res.success) {
        setNotificationList([])
        notificationCountGlobalHandler(0)
      }
    })
  }

  const deleteNotificationHandler = id => {
    deleteNotification(id).then(({ data: res }) => {
      getNotification(0)
      unreadNotificationCount().then(({ data: res }) => {
        if (res?.success) {
          notificationCountGlobalHandler(res?.data?.unread_count)
        }
      })
    })
  }

  return (
    <HeaderContainer>
      <PageTitle
        onClick={() => navigate("/mypreferencecard-listing")}
        style={{ cursor: "pointer" }}
      >
        <div style={{ width: "160px", height: "36px" }}>
          <CompanyLogo style={{ width: "inherit" }} />
        </div>
      </PageTitle>
      <HeaderRightContainer>
        <div></div>
        <UserText className="">{user?.name}</UserText>
        <CapsuleTab className="ms-2 me-4" bgColor="#F5F8FE" color="#1D57C5">
          {convertToTitleCaseWithSpaces(user?.role)}
        </CapsuleTab>
        <NotificationContainer>
          <Dropdown
            show={isNotificationOpen}
            onClick={() => {
              getNotification(0)
            }}
            onToggle={setIsNotificationOpen}
          >
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                marginRight: "12px",
                outline: "none"
              }}
            >
              <AntBadge count={notificationCountGlobal} className="mt-1">
                <Avatar
                  icon={<BellOutlined />}
                  className="bg-white text-black"
                />
              </AntBadge>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                border: "1px solid #E0E0E0",
                borderRadius: "10px",
                boxShadow: "rgb(0 0 0 / 41%) 1px 1px 7px -3px",
                width: "400px"
              }}
            >
              <Flex align="center" justify="space-between" className="m-3">
                <Flex gap={5} align="center">
                  <Heading>Notifications</Heading>
                  <Badge pill bg="primary">
                    {notificationCountGlobal}
                  </Badge>
                </Flex>
                <PackageTopHeading
                  fontWeight="500"
                  onClick={() => markAllReadHandler()}
                  style={{ cursor: "pointer" }}
                >
                  Mark all as read
                </PackageTopHeading>
              </Flex>
              <div
                style={{
                  maxHeight: "400px",
                  minHeight: "100px",
                  overflowY: "auto"
                }}
              >
                {notificationList?.results?.length ? (
                  notificationList?.results?.map((items, index) => {
                    return (
                      <>
                        <Flex gap={5} className="m-3" align="start" key={index}>
                          <Image
                            src={
                              items?.about_user?.profile_picture || DefaultImage
                            }
                            alt="User Avatar"
                            className="rounded-circle me-1"
                            preview={false}
                            style={{ height: "36px", width: "36px" }}
                          />
                          <Flex
                            vertical
                            className="w-100"
                            onClick={e => {
                              navigate(
                                items?.about_user &&
                                  `/preference-card-detail/${items?.pref_card}`,
                                {
                                  state: "personal"
                                }
                              )
                              setIsNotificationOpen(false)
                            }}
                          >
                            {items?.about_user && (
                              <Flex justify="space-between" className="w-100">
                                <Text>
                                  {items?.about_user?.name}{" "}
                                  <span className="text-muted">
                                    ({items?.about_user?.role})
                                  </span>
                                </Text>
                                <span className="text-muted">
                                  {items?.created_at_readable}
                                </span>
                              </Flex>
                            )}
                            <Flex
                              justify="space-between"
                              align="center"
                              className="w-100"
                              wrap="wrap"
                            >
                              <Text>"{items?.title}"</Text>
                              {!items?.about_user && (
                                <span className="text-muted ms-auto">
                                  {items?.created_at_readable}
                                </span>
                              )}
                              <UserText className="text-muted">
                                {items?.message}
                              </UserText>
                            </Flex>
                          </Flex>
                          <DeleteOutlined
                            style={{ color: "#D92D20" }}
                            className="mt-1 ms-2"
                            onClick={() => deleteNotificationHandler(items.id)}
                          />
                        </Flex>
                      </>
                    )
                  })
                ) : isNotificationLoading ? (
                  <div className="p-3">
                    <Skeleton
                      avatar
                      paragraph={{
                        rows: 0
                      }}
                    />
                    <Skeleton
                      avatar
                      paragraph={{
                        rows: 0
                      }}
                    />
                    <Skeleton
                      avatar
                      paragraph={{
                        rows: 0
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                    <InboxOutlined className="fs-2 text-muted" />
                    <AuthDescription>No Notification Found</AuthDescription>
                  </div>
                )}
              </div>
              <PackageTopHeading
                className="m-3"
                fontWeight="500"
                onClick={() => {
                  navigate("/view-all-notifications")
                  setIsNotificationOpen(false)
                }}
              >
                View All Notifications
              </PackageTopHeading>
            </Dropdown.Menu>
          </Dropdown>
        </NotificationContainer>
        <Dropdown ref={dropdownRef}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            style={{
              background: "none",
              border: "none",
              padding: 0,
              marginRight: "-12px",
              outline: "none"
            }}
          >
            <UserAvatar
              src={
                profilePicture ||
                user?.profile_picture ||
                user?.profile_image ||
                DefaultImage
              }
              alt="User Avatar"
              style={{ width: "36px", height: "36px" }}
              preview={false}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              border: "1px solid #E0E0E0",
              borderRadius: "10px",
              boxShadow: "rgb(0 0 0 / 41%) 1px 1px 7px -3px"
            }}
          >
            {profileMenus?.map((items, index) => {
              return (
                <div
                  className="my-3 mx-2"
                  onClick={event => {
                    handleDropdownItemClick(event)
                    items.onClick && items.onClick()
                  }}
                  style={{
                    margin: "8px 0px",
                    minWidth: "220px",
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 12px",
                    cursor: "pointer"
                  }}
                  key={index}
                >
                  <HeadingComponent
                    text={
                      <>
                        {items.icon}
                        &nbsp;&nbsp;
                        {items.name}
                      </>
                    }
                    fontSize="14px"
                    fontWeight={400}
                  />
                </div>
              )
            })}

            <div
              className="mt-3 mx-2"
              style={{
                borderTop: "1px solid #E0E0E0",
                padding: "10px 12px 0px 12px",
                cursor: "pointer"
              }}
              onClick={() => {
                OneSignal.logout()
                localStorage.removeItem("token")
                localStorage.removeItem("userId")
                window.location.reload()
              }}
            >
              <HeadingComponent
                text={
                  <div className="text-danger">
                    <LogoutOutlined />
                    &nbsp;&nbsp; Logout
                  </div>
                }
                fontSize="14px"
                margin={"3px 0px 3px 0px"}
                fontWeight={400}
              />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </HeaderRightContainer>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 5px 32px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #e4eaf0;
  background-color: white;
`

const HeaderRightContainer = styled.div`
  display: flex;
  height: 44px;

  align-items: center;
`

const NotificationContainer = styled.div`
  height: 100%;
  width: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const UserAvatar = styled(Image)`
  height: 36px;
  width: 36px;
  background-color: #2d68fe;
  object-fit: cover; /* or contain, depending on your preference */
  border-radius: 50px; /* Use 50% for a perfect circle */
  &:hover {
    cursor: pointer;
  }
`
const PageTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  color: #414d55;
`
const LogoutContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export default HeaderNav
