import { Button, Modal, Select, message } from "antd"
import React, { useEffect, useState } from "react"
import { DeleteOutlined } from "@ant-design/icons"
import { UserText } from "../styledComponents"
import { buySubscription, fetchSubscription } from "../api/DataService"
import UpgradeConfirmModal from "./UpgradeConfirmModal"

const UpgradePlanModal = ({
  isOpen,
  setIsOpen,
  upgradePlanHandler,
  headingText,
  descText
}) => {
  const [subscriptionList, setSubscriptionList] = useState([{}])
  const [selectedPackage, setSlectedPackage] = useState()
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false)

  useEffect(() => {
    fetchSubscription().then(({ data: res }) => {
      if (res?.success) {
        setSubscriptionList(res.result)
      }
    })
  }, [])

  const hospitalBundleHandler = (setIsRefreshPageModalOpen) => {
    const price_id = subscriptionList.find(
      item => item.price_id === selectedPackage
    )?.price_id
    buySubscription(price_id).then(({ data: res, error }) => {
      if (res?.hosted_invoice_url) {
        setIsOpen(false)
        setIsUpgradeOpen(false)
        setIsRefreshPageModalOpen(true)
        window.open(res?.hosted_invoice_url, "_blank")
      }
      if (error) message.error(error?.message)
    })
  }

  const confirmHandler = (setIsRefreshPageModalOpen) => {
    hospitalBundleHandler(setIsRefreshPageModalOpen)
    setIsOpen(false)
  }

  return (
    <>
      <UpgradeConfirmModal
        isOpen={isUpgradeOpen}
        setIsOpen={setIsUpgradeOpen}
        headingText="Upgrade Plan"
        descText="Are you sure you want to proceed with the plan upgrade? By confirming, your payment will be processed immediately."
        confirmHandler={confirmHandler}
      />
      <Modal
        open={isOpen}
        footer={null}
        width={"416px"}
        height={"280px"}
        onCancel={() => setIsOpen(false)}
        centered
      >
        <h5>{headingText}</h5>
        <UserText className="mb-3">Select a package</UserText>
        <div className="d-flex gap-2 align-items-center mb-4">
          <Select
            className="w-50"
            placeholder="Select Package..."
            onChange={e => setSlectedPackage(e)}
            options={subscriptionList.map(item => ({
              value: item.price_id,
              label: item.name
            }))}
          />
          <div
            className="rounded-0 flex-grow-1 bg-white"
            style={{ color: "#2968DC" }}
            disabled={!selectedPackage}
          >
            {selectedPackage
              ? `(${subscriptionList.find(item => item.price_id === selectedPackage)?.users_limit} Seats) - $${subscriptionList.find(item => item.price_id === selectedPackage)?.price}`
              : ""}
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-end">
          <Button type="default" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setIsOpen(false)
              setIsUpgradeOpen(true)
            }}
          >
            Continue to Payment
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default UpgradePlanModal
