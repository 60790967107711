import React, { useEffect, useState } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import styled, { css } from "styled-components"

function NavItem({
  text,
  icon,
  to = "",
  pathsToMatch,
  hasExtras,
  isExtraHover,
  isHover,
  extraCallback,
  extras = [],
  isHeading
}) {
  let navigate = useNavigate()

  const { pathname } = useLocation()
  var dd = pathname.includes(to)
  const [is_active, setIsActive] = useState(
    pathname == to || pathname.includes(to)
  )
  useEffect(() => {
    is_active && extraCallback(hasExtras)
  }, [])

  useEffect(() => {
    setIsActive(pathname == to || pathname?.includes(to))
  }, [pathname])

  const isClientDetails = pathsToMatch?.some(path => pathname.includes(path))

  return (
    <NavListItem active={pathname === to || isClientDetails}>
      <Container
        isHeading={isHeading}
        onClick={() => navigate(to)}
        isExpanded={!isHover}
        type="button"
      >
        <LogoContainer isHover={isHover}>
          <Logo active={is_active}>{icon}</Logo>
        </LogoContainer>
        {isHover && (
          <>
            <LogoText active={pathname === to || isClientDetails}>
              {text}
            </LogoText>
            {hasExtras && (
              <ExtraContainer>
                <Logo active={is_active}>{""}</Logo>
              </ExtraContainer>
            )}
          </>
        )}
      </Container>
      {hasExtras && is_active && isExtraHover && (
        <ExtraContent>
          {extras.map((e, i) => (
            <ExtraButton
              key={i}
              onClick={() => navigate(to + e.link)}
              active={pathname.replace(to, "") == e.link}
            >
              {e.name}
            </ExtraButton>
          ))}
        </ExtraContent>
      )}
    </NavListItem>
  )
}
const Container = styled.button`
  position: relative;
  width: ${props => (props.isExpanded ? "55px" : "200px")};
  padding: ${props => (props.isHeading ? "10px 0px" : "10px 15px")};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  background: ${props => (props.active ? "#FFF6E8" : "none")};
  display: flex;
  align-items: baseline;

  &:hover {
    background: ${props =>
      props.active && !props.disabled ? "lightblue" : "none"};
  }

  ${props =>
    props.active &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 28%;
        left: 0;
        height: 16px;
        width: 5px;
        background-color: #fbcf34;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    `}
`
const LogoContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 3px;
`
const ExtraContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-left: auto;
`

const Logo = styled.div`
  height: 100%;
  width: 100%;
  object-fit: contain;
  color: ${props => (props.active ? "#2D68FE" : "#000000")};
`

const LogoText = styled.span`
  font-weight: ${props => (props.active ? 500 : 400)};
  color: ${props => (props.active ? "#2D68FE" : "#000000")};
  text-wrap: nowrap;
  font-size: 0.875rem;
  text-align: left;
`

const ExtraContent = styled.div`
  padding-top: 50px;
  width: 140px;
  height: 100%;
  position: absolute;
  left: 77px;
  // left: 100px;
  top: 75px;
  border-left: 1px solid #e4eaf0;
  padding-left: 18px;
`
const ExtraButton = styled.button`
  width: 135px;
  height: 29px;
  background: ${props => (props.active ? "#FFF6E8" : "transparent")};
  border-radius: 5px;
  border: none;
  color: ${props => (props.active ? "#fff" : "#8B8698")};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background: lightblue;
  }
`

const NavListItem = styled.li`
  background: ${props => (props.active ? "#EFF6FF" : "#FFFFFF")};
  border-right: 4px solid ${props => (props.active ? "#2D68FE" : "#fff")};
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default NavItem
