import React, { useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import SidebarNav from "./SideBarNav"
import {
  adminNurseSidebarNavData,
  nurseSidebarNavData,
  sidebarNavData,
  surgeonSidebarNavData
} from "../constants/SideBarNavData"
import HeaderNav from "./HeaderNav"
import { Button, Image } from "antd"
import { fetchAdsBanner } from "../api/DataService"

const ProtectedRoute = ({ component: Component }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [hideAds, setHideAds] = useState(
    localStorage.getItem("hideHorizontalAds") || false
  )
  const token = localStorage.getItem("token")
  const user = JSON.parse(localStorage.getItem("user"))
  const [adsBanner, setAdsBanner] = useState({})

  useEffect(() => {
    if (
      user &&
      user.is_admin !== true &&
      (location.pathname === "/user-listing" ||
        location.pathname === "/subscription-plan")
    ) {
      navigate("/mypreferencecard-listing")
    }
  }, [location.pathname, navigate, user])

  useEffect(() => {
    fetchAdsBanner().then(({ data: res }) => {
      setAdsBanner(
        res?.results?.reduce((acc, item) => {
          acc[item.identifier] = item.image
          return acc
        }, {})
      )
    })
  }, [])

  if (!token) {
    return <Navigate to="/login" />
  }

  const handleHideAds = () => {
    setHideAds(true)
    localStorage.setItem("hideHorizontalAds", true)
  }

  const getUserSideBar = () => {
    if (user.is_admin && user.role === "surgeon") {
      return sidebarNavData
    } else if (user.is_admin) {
      return adminNurseSidebarNavData
    } else if (user.role === "surgeon") {
      return surgeonSidebarNavData
    } else if (user.role === "nurse" || user.role === "surgical-technician") {
      return nurseSidebarNavData
    }
  }

  return (
    <div>
      <HeaderNav />
      <div className="d-flex">
        {location.pathname !== "/view-all-notifications" && (
          <SidebarNav navItems={getUserSideBar()} sidebarToggle={true} />
        )}
        <div className="w-100">
          {!hideAds && (
            <div
              className="d-flex mt-3 justify-content-center m-auto"
              style={{ position: "relative", width: "720px", height: "90px" }}
            >
              <Image
                src={adsBanner?.horizontal}
                width={720}
                height={90}
                className="object-fit-cover m-auto"
                preview={false}
              />
              <div
                onClick={handleHideAds}
                className="cross-button border-0"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: -12,
                  right: -5
                }}
              >
                X
              </div>
            </div>
          )}
          <Component />
        </div>
      </div>
    </div>
  )
}

export default ProtectedRoute
