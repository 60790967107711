import { Button, Card, Flex, Input, InputNumber, Select, message } from "antd"
import React, { useEffect } from "react"
import {
  CapsuleTab,
  PackageCapsule,
  PackageDescription,
  PackageDetail,
  PackageHeading,
  PackageName,
  PackageTopHeading,
  StyledInputNumber,
  Text
} from "../../styledComponents"
import {
  MinusOutlined,
  PlusOutlined,
  CheckCircleFilled
} from "@ant-design/icons"
import {
  assignPackage,
  buySubscription,
  fetchSubscription
} from "../../api/DataService"
import { useNavigate } from "react-router-dom"

const FreeTrialEndPackageScreen = () => {
  const navigate = useNavigate()
  const [subscriptionList, setSubscriptionList] = React.useState([{}])
  const [selectedPackage, setSlectedPackage] = React.useState()

  useEffect(() => {
    fetchSubscription().then(({ data: res }) => {
      if (res?.success) {
        setSubscriptionList(res.result)
      }
    })
  }, [])

  const hospitalBundleHandler = () => {
    const price_id = subscriptionList.find(
      item => item.price_id === selectedPackage
    )?.price_id
    buySubscription(price_id).then(({ data: res }) => {
      if (res?.hosted_invoice_url) {
        navigate("/login")
        window.open(res?.hosted_invoice_url, "_blank")
      }
    })
  }

  return (
    <Flex justify="center" align="center" style={{ height: "100vh" }} vertical>
      <PackageHeading>
        Your Free Trial has been ended. Kindly Upgrade Your Plan
      </PackageHeading>
      <PackageDescription>
        Your Free Trail Period has been ended. You need to purchase one
      </PackageDescription>
      <PackageDescription>
        in order to continue taking servies
      </PackageDescription>
      <Flex justify="center" align="center" className="mt-5">
        <Card
          className="rounded-0"
          style={{
            color: "#FFFFFF",
            background: "#2968DC",
            width: "450px",
            height: "424px"
          }}
        >
          <Flex justify="space-between">
            <Text className="text-white">HOSPITAL BUNDLE</Text>
            <PackageCapsule>Monthly</PackageCapsule>
          </Flex>
          <PackageName className="mt-4">Paid</PackageName>
          <PackageDetail className="my-4">
            Unlimited Preference cards with unlimited users
          </PackageDetail>
          <Flex gap={10} className="w-100">
            <Select
              className="w-50"
              placeholder="Select Package..."
              onChange={e => setSlectedPackage(e)}
              options={subscriptionList.map(item => ({
                value: item.price_id,
                label: item.name
              }))}
            />
            <Button
              className="rounded-0 flex-grow-1 bg-white"
              style={{ color: "#2968DC" }}
              onClick={() => hospitalBundleHandler()}
              disabled={!selectedPackage}
            >
              {selectedPackage
                ? `Get started - $${subscriptionList.find(item => item.price_id === selectedPackage)?.price}`
                : "Select Package"}
            </Button>
          </Flex>
          <Text className="mb-3 mt-5 text-white">
            <CheckCircleFilled /> Unlimited preference cards
          </Text>
          <Text className="mb-3 text-white">
            <CheckCircleFilled /> Manage access of your seats
          </Text>
          <Text className="mb-3 text-white">
            <CheckCircleFilled /> Renews every month
          </Text>
          <Text className="mb-3 text-white">
            <CheckCircleFilled /> Collaboration Feature
          </Text>
        </Card>
        {/* <Card
          className="rounded-0"
          style={{
            background: "#F5F8FE",
            width: "450px",
            height: "424px"
          }}
        >
          <Flex justify="space-between">
            <PackageTopHeading>INDIVIDUAL PLAN</PackageTopHeading>
            <PackageCapsule>Monthly</PackageCapsule>
          </Flex>
          <PackageName className="mt-4">$10</PackageName>
          <PackageDescription className="my-4 text-start">
            Unlimited Preference cards for 1 User
          </PackageDescription>
          <Button className="rounded-0 w-100">Get started</Button>
          <Text className="mb-3 mt-5">
            <CheckCircleFilled style={{ color: "#2968DC" }} /> Unlimited
            preference cards
          </Text>
          <Text className="mb-3">
            <CheckCircleFilled style={{ color: "#2968DC" }} /> 1 User
          </Text>
          <Text className="mb-3">
            <CheckCircleFilled style={{ color: "#2968DC" }} /> Free for 3 month
          </Text>
          <Text className="mb-3">
            <CheckCircleFilled style={{ color: "#2968DC" }} /> Collaboration
            Feature
          </Text>
        </Card> */}
      </Flex>
    </Flex>
  )
}

export default FreeTrialEndPackageScreen
