import axios from "axios";
import { BASE_URL, apiEndPoints } from "./apiEndPoints";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.response.use(
  response => response,
  error => {
    console.log("error = ", window.location.href)
    if (
      error.response &&
      error.response.status === 401 &&
      !window.location.href.includes("/login")
    ) {
      window.location.href = "/login"
    }
    return Promise.reject(error)
  }
)

export const login = async (email, password) => {
  try {
    const response = await instance.post(`${BASE_URL}${apiEndPoints.LOGIN}`, {
      email,
      password
    })
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response?.data?.non_field_errors }
  }
}

export const signup = async formValues => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.SIGNUP}`,
      formValues
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response?.data }
  }
}

export const forgetPassword = async email => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.FORGET_PASSWORD}`,
      {
        email
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data.message }
  }
}

export const resetPassword = async (password, NewPassword, uid, token) => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.RESET_PASSWORD}`,
      {
        new_password1: password,
        new_password2: NewPassword,
        uid: uid,
        token: token
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data.message }
  }
}

export const changePassword = async formValues => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.CHANGE_PASSWORD}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    console.log(error.response.data)
    return { data: null, error: error.response.data }
  }
}

export const getUserList = async (search = "", role = "", currentPage = "") => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.USER_LISTING(search, role, currentPage)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error?.response?.data }
  }
}

export const getSingleUser = async id => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.SINGLE_USER(id)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const addUser = async formValues => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.ADD_USER}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const editUser = async (id, formValues) => {
  try {
    const response = await instance.put(
      `${BASE_URL}${apiEndPoints.EDIT_USER(id)}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const deleteUser = async id => {
  try {
    const response = await instance.delete(
      `${BASE_URL}${apiEndPoints.DELETE_USER(id)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const getItemList = async (
  search = "",
  category = "",
  currentPage = ""
) => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.ITEM_LISTING(search, category, currentPage)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error?.response?.data }
  }
}

export const addItem = async formValues => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.ADD_ITEM}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const editItem = async (id, formValues) => {
  try {
    const response = await instance.put(
      `${BASE_URL}${apiEndPoints.EDIT_ITEM(id)}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const deleteItem = async data => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.DELETE_ITEM}`,
      {
        items: data
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error?.response?.data }
  }
}

export const getPreferenceCardList = async (search, currentPage = "") => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.PREFERENCE_CARD_LISTING(search, currentPage)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error?.response?.data }
  }
}

export const getMyPreferenceCardList = async (
  search = "",
  currentPage = ""
) => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.MY_PREFERENCE_CARD_LISTING(search, currentPage)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error?.response?.data }
  }
}

export const getSinglePreferenceCard = async id => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.SINGLE_PREFERENCE_CARD(id)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error }
  }
}

export const addMyPreferenceCard = async formValues => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.ADD_MY_PREFERENCE_CARD}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const editMyPreferenceCard = async (id, formValues) => {
  try {
    const response = await instance.put(
      `${BASE_URL}${apiEndPoints.EDIT_MY_PREFERENCE_CARD(id)}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const deleteMyPreferenceCard = async data => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.DELETE_MY_PREFERENCE_CARD}`,
      { preference_cards: data },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchCategories = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_CATEGORIES}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchItems = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_ITEMS}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchSurgeonList = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_SURGEON_LIST}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const getTemplateList = async search => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.TEMPLATE_LISTING(search)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const useTemplate = async id => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.ADD_TEMPLATE(id)}`,
      {},
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const saveProfileList = async search => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.SAVED_PROFILE_LISTING(search)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const removeSavedProfile = async id => {
  try {
    const response = await instance.delete(
      `${BASE_URL}${apiEndPoints.REMOVE_SAVED_PROFILE(id)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const getUserProfile = async id => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.USER_PROFILE(id)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const saveProfile = async id => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.SAVE_PROFILE}`,
      { surgeon: id },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const requestSuggestion = async formValues => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.REQUEST_SUGGESTION}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const suggestionRequestList = async (id, search) => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.SUGGESTION_REQUEST_LISTING(id, search)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const reviewSuggestion = async formValues => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.REVIEW_SUGGESTION}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    console.log("error = ", error)
    return { data: null, error: error.response.data }
  }
}

export const assignPackage = async () => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.ASSIGN_FREE_PACKAGE}`,
      null,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const duplicatePrefCard = async id => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.DUPLICATE_PREF_CARD(id)}`,
      {},
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    console.log(error)
    return { data: null, error: error.response.data }
  }
}

export const fetchAllNotification = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_ALL_NOTIFICATION}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchNotification = async read => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_NOTIFICATION(read)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const deleteNotification = async id => {
  try {
    const response = await instance.delete(
      `${BASE_URL}${apiEndPoints.DELETE_NOTIFICATION(id)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const deleteAllNotification = async () => {
  try {
    const response = await instance.delete(
      `${BASE_URL}${apiEndPoints.DELETE_ALL_NOTIFICATION}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const markAllAsReadNotification = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.MARK_ALL_AS_READ_NOTIFICATION}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const unreadNotificationCount = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.UNREAD_NOTIFICATION_COUNT}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchSubscription = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_SUBSCRIPTION}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const buySubscription = async price_id => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.BUY_SUBSCRIPTION}`,
      {
        price_tier: price_id
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const customerPortal = async () => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.CUSTOMER_PORTAL}`,
      null,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const updateNotificationSettings = async formValues => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.UPDATE_NOTIFICATION_SETTINGS(formValues.id)}`,
      formValues,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const cancelSubscription = async subscriptionId => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.CANCEL_SUBSCRIPTION}`,
      {
        sub_id: subscriptionId
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchNotificationSettings = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_NOTIFICATION_SETTINGS}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchSubscriptionPlan = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_SUBSCRIPTION_PLAN}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchAdsBanner = async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${apiEndPoints.FETCH_ADS_BANNER}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}

export const fetchPreferenceCardBulk = async prefCardIds => {
  try {
    const response = await instance.post(
      `${BASE_URL}${apiEndPoints.FETCH_PREFERENCE_CARD_BULK}`,
      {
        preference_cards: prefCardIds
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }
    )
    return { data: response.data, error: null }
  } catch (error) {
    return { data: null, error: error.response.data }
  }
}
