export const convertToTitleCaseWithSpaces = text =>
  text &&
  text
    .split("-")
    .map(word => word[0].toLocaleUpperCase() + word.slice(1))
    .join(" ")

const removeEmptyObjects = arr => arr.filter(obj => Object.keys(obj).length > 1)

export const removeEmptyObjectsFromArray = data => {
  const tempData = JSON.parse(JSON.stringify(data))
  Object.keys(tempData).forEach(key => {
    if (Array.isArray(tempData[key])) {
      tempData[key] = removeEmptyObjects(tempData[key])
    }
  })
  return tempData
}

export const mapIdAndNameToLabelAndValue = data =>
  data?.map(({ id, name }) => ({ label: name, value: id }))