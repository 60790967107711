import React, { useEffect, useState } from "react"
import DeleteModal from "../../../../components/DeleteModal"
import { Button, Input, Pagination, Table, message } from "antd"
import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
  DownloadOutlined
} from "@ant-design/icons"
import { preferenceCardListingColumns } from "../../../../constants/Headers"
import {
  deleteMyPreferenceCard,
  duplicatePrefCard,
  fetchPreferenceCardBulk,
  getMyPreferenceCardList,
  getSinglePreferenceCard
} from "../../../../api/DataService"
import { useNavigate } from "react-router-dom"
import PDFFile from "../../../PDFFile"
import { usePDF } from "react-to-pdf"
import DuplicateModal from "../../../../components/DuplicateModal"
import PDFFileBulk from "../../../PDFFileBulk"

const MyPreferenceCardListing = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [listData, setListData] = useState([])
  const [deleteModalValues, setDeleteModalValues] = useState()
  const [duplicateModalValues, setDuplicateModalValues] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loader, setLoader] = useState(false)
  const [prefCardBulkData, setPrefCardBulkData] = useState([])
  const [prefCardData, setPrefCardData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const { toPDF, targetRef } = usePDF({
    filename: prefCardData?.procedure_name
  })
  const { toPDF: toPDFBulk, targetRef: targetRefBulk } = usePDF({
    filename: "page.pdf"
  })

  useEffect(() => {
    let timeoutId
    const delay = 500

    const fetchData = () => {
      setLoader(true)
      getMyPreferenceCardList(searchText, currentPage).then(({ data: res, error }) => {
        setListData(res?.data?.results)
        setCount(res?.data?.count)
        setLoader(false)
        if(error) message.error(error?.message)
      })
    }

    const debounceFetchData = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(fetchData, delay)
    }

    debounceFetchData()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchText, currentPage])

  useEffect(() => {
    if (Object.keys(prefCardData).length) {
      toPDF()
    }
  }, [prefCardData])

  const exportPDF = record => {
    getSinglePreferenceCard(record.id).then(({ data: res }) => {
      setPrefCardData(res.data)
    })
  }

  const exportPDFBulk = () => {
    toPDFBulk()
  }

  const duplicateRecord = record => {
    setDuplicateModalValues(record)
    setIsDuplicateOpen(true)
  }

  const duplicatePreferenceCard = () => {
    duplicatePrefCard(duplicateModalValues.id).then(({ data: res }) => {
      if (res.success) {
        message.success("Preference Card duplicated successfully")
        getMyPreferenceCardList(searchText, currentPage).then(
          ({ data: res }) => {
            setListData(res?.data?.results)
            setCount(res?.data?.count)
          }
        )
        setIsDuplicateOpen(false)
      }
    })
  }

  const handleEdit = record => {
    navigate("/edit-preferencecard", { state: record })
  }

  const handleDelete = record => {
    setDeleteModalValues(record)
    setIsModalOpen(true)
  }

  const deleteMyPreferenceCardHandler = deleteRecordIds => {
    deleteMyPreferenceCard(deleteRecordIds).then(({ data: res }) => {
      message.success("Preference Card deleted successfully")
      setIsModalOpen(false)
      getMyPreferenceCardList(searchText, currentPage).then(({ data: res }) => {
        setListData(res?.data?.results)
        setCount(res?.data?.count)
      })
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      fetchPreferenceCardBulk(selectedRowKeys).then(({ data: res }) => {
        setPrefCardBulkData(res?.data)
      })
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  return (
    <>
      <DuplicateModal
        isOpen={isDuplicateOpen}
        setIsOpen={setIsDuplicateOpen}
        duplicateHandler={duplicatePreferenceCard}
        headingText={`Are you sure, you want to duplicate this record?`}
        descText="By doing this action, this record will be duplicated and saved as a new record."
      />
      <DeleteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        deleteHandler={() =>
          selectedRowKeys.length > 0
            ? deleteMyPreferenceCardHandler(selectedRowKeys)
            : deleteMyPreferenceCardHandler([deleteModalValues.id])
        }
        headingText="Are you sure, you want to Delete this record?"
        descText="By deleting this data, you’ll no longer be able to access it"
      />
      <div className="m-4 flex-grow-1">
        <h3 className="mb-4">My Preference Cards</h3>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex gap-3">
            <Input
              placeholder="Search list..."
              prefix={<SearchOutlined />}
              onChange={e => setSearchText(e.target.value)}
              allowClear
            />
          </div>
          <div className="d-flex gap-2">
            {selectedRowKeys.length > 0 && (
              <>
                <Button
                  size="medium"
                  icon={<DownloadOutlined />}
                  onClick={() => exportPDFBulk()}
                >
                  Export All
                </Button>
                <Button
                  size="medium"
                  icon={<DeleteOutlined />}
                  style={{ color: "#D92D20" }}
                  onClick={() => setIsModalOpen(true)}
                >
                  Delete All
                </Button>
              </>
            )}
            <Button
              size="medium"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/add-preferencecard")}
            >
              Add new Preference Card
            </Button>
          </div>
        </div>
        <Table
          columns={preferenceCardListingColumns(
            navigate,
            handleEdit,
            exportPDF,
            duplicateRecord,
            handleDelete
          )}
          dataSource={listData || []}
          pagination={false}
          loading={loader}
          rowSelection={rowSelection}
          rowKey="id"
        />
        <Pagination
          className="text-end mt-3"
          total={count}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={10}
          onChange={page => setCurrentPage(page)}
        />
      </div>

      <PDFFileBulk targetRef={targetRefBulk} prefCardData={prefCardBulkData} />
      <PDFFile targetRef={targetRef} prefCardData={prefCardData} />
    </>
  )
}

export default MyPreferenceCardListing
