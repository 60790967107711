import React from "react"
import { Form, Input, Button, Image, message, Checkbox } from "antd"
import CompanyLogo from "../../assets/Company-Logo.svg"
import AuthSplashImage from "../../assets/Login-Splash-Image.jpg"
import styled from "styled-components"
import {
  BlurOverlay,
  Description,
  Header,
  Text,
  Heading,
  Card,
  AuthDescription,
  AuthFormItem
} from "../../styledComponents"
import { CheckCircleOutlined } from "@ant-design/icons"
import { Link, useNavigate } from "react-router-dom"
import { signup } from "../../api/DataService"

const SignUp = () => {
  const [password, setpassword] = React.useState("")
  const [signUpStep, setSignUpStep] = React.useState(1)
  const [acceptTerms, setAcceptTerms] = React.useState(false)
  const navigate = useNavigate()

  const hasMinimumLength = password.length >= 8
  const hasUpperCase = /[A-Z]/.test(password)
  const hasLowerCase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)

  const onFinish = values => {
    if (!hasMinimumLength || !hasUpperCase || !hasLowerCase || !hasNumber) {
      message.error(
        "Weak password. Please make sure your password meets the requirements."
      )
      return
    }
    navigate("/profile-setup", {
      state: { email: values.email, password: values.password }
    })
  }

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Header bgColor={AuthSplashImage} hideHeader="none">
          <BlurOverlay>
            <Card>
              <h5>Synchronize Surgical Success with Seamless Collaboration</h5>
              <Description>
                Upgrade to Collaborate and Innovate approach
              </Description>
            </Card>
          </BlurOverlay>
        </Header>
      </div>
      <div style={{ flex: 1 }} className="my-auto mx-3">
        <div>
          <FormContainer>
            <div className="text-center">
              <Image
                className="text-center"
                src={CompanyLogo}
                preview={false}
              />
            </div>
            <h4 className="text-start mt-4">Sign up</h4>
            <AuthDescription className="text-start">
              One step closer to amazing experiences! Provide your email and
              password to set up your account.
            </AuthDescription>
            <Form
              onFinish={onFinish}
              layout="vertical"
              initialValues={{
                email: "",
                password: ""
              }}
            >
              <AuthFormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!"
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address!"
                  }
                ]}
              >
                <Input placeholder="Enter your email" autoComplete="off" maxLength={255}/>
              </AuthFormItem>

              <AuthFormItem
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" }
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  onChange={e => setpassword(e.target.value)}
                  autoComplete="new-password"
                />
              </AuthFormItem>
              <Text
                className="mb-2"
                style={{ color: hasMinimumLength ? "#2F3E5B" : "#646F84" }}
              >
                <CheckCircleOutlined
                  style={{ color: hasMinimumLength ? "#5CB85C" : "initial" }}
                />{" "}
                Must be a minimum of 8 characters
              </Text>
              <Text
                className="mb-2"
                style={{ color: hasUpperCase ? "#2F3E5B" : "#646F84" }}
              >
                <CheckCircleOutlined
                  style={{ color: hasUpperCase ? "#5CB85C" : "initial" }}
                />{" "}
                Must contain an upper case letter
              </Text>
              <Text
                className="mb-2"
                style={{ color: hasLowerCase ? "#2F3E5B" : "#646F84" }}
              >
                <CheckCircleOutlined
                  style={{ color: hasLowerCase ? "#5CB85C" : "initial" }}
                />{" "}
                Must contain lower case letter
              </Text>
              <Text
                className="mb-2"
                style={{ color: hasNumber ? "#2F3E5B" : "#646F84" }}
              >
                <CheckCircleOutlined
                  style={{ color: hasNumber ? "#5CB85C" : "initial" }}
                />{" "}
                Must contain at least one number
              </Text>
              <Form.Item>
                <Checkbox
                  className="mt-2"
                  onChange={e => setAcceptTerms(e.target.checked)}
                >
                  I accept the{" "}
                  <Link to={"/terms-and-conditions"} target="_blank">
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link to={"/privacy-policy"} target="_blank">
                    Privacy Policy
                  </Link>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-100 mt-3"
                  disabled={!acceptTerms}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
            <Text
              className="text-center d-flex justify-content-center"
              fontWeight="400"
            >
              Already have an account?
              <Text
                className="ms-1 text-decoration-underline cursor-pointer"
                fontWeight="600"
                color="#1B2B4B"
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer" }}
              >
                Login Here
              </Text>
            </Text>
          </FormContainer>
        </div>
      </div>
    </div>
  )
}

const FormContainer = styled.div`
  width: 428px;
  height: 100%;
  margin: auto;
  padding: 20px;
`

export default SignUp
