import React from "react"
import { Form, Input, Button, Breadcrumb, Divider, message } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { useLocation, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import {
  fetchItems,
  addMyPreferenceCard,
  getSinglePreferenceCard,
  editMyPreferenceCard
} from "../../../../api/DataService"
import { UserText } from "../../../../styledComponents"
import DynamicFieldPrefCard from "../../../../components/DynamicFieldPrefCard"
import { removeEmptyObjectsFromArray } from "../../../../util/commonUtil"
import CreateItemModal from "../../../../components/CreateItemModal"

const EditTemplate = () => {
  const [form] = Form.useForm()
  const [items, setItems] = useState([])
  const [formValues, setFormValues] = useState({})
  const [isCreateItemModalOpen, setIsCreateItemModalOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const templateId = location.state || {}

  useEffect(() => {
    fetchItems().then(({ data: res }) => {
      const updatedItems = {}
      res?.data?.forEach(item => {
        if (!updatedItems[item.category_identifier]) {
          updatedItems[item.category_identifier] = []
        }

        updatedItems[item.category_identifier]?.push({
          label: item.name,
          value: item.id
        })
      })
      setItems(updatedItems)
    })
  }, [isCreateItemModalOpen])

  useEffect(() => {
    getSinglePreferenceCard(templateId).then(({ data: res }) => {
      setFormValues(res?.data)
      form.setFieldsValue({
        draft_name: res?.data?.draft_name,
        procedure_name: res?.data?.procedure_name,
        description: res?.data?.description,
        glove_size: res?.data?.glove_size,
        equipments: res?.data?.equipments?.length
          ? res?.data?.equipments
          : [{ equipment: undefined, quantity: 1 }],
        instruments: res?.data?.instruments?.length
          ? res?.data?.instruments
          : [{ instrument: undefined, quantity: 1 }],
        supplies: res?.data?.supplies?.length
          ? res?.data?.supplies
          : [{ supply: undefined, quantity: 1 }],
        medications: res?.data?.medications?.length
          ? res?.data?.medications
          : [{ medication: undefined, quantity: 1 }],
        sutures: res?.data?.sutures?.length
          ? res?.data?.sutures
          : [{ suture: undefined, quantity: 1 }],
        dressings: res?.data?.dressings?.length
          ? res?.data?.dressings
          : [{ dressing: undefined, quantity: 1 }],
        special_requirements: res?.data?.special_requirements?.length
          ? res?.data?.special_requirements
          : [{ special_requirement: undefined, quantity: 1 }],
        notes: res?.data?.notes
      })
    })
  }, [])

  const handleSubmit = values => {
    const userData = JSON.parse(localStorage.getItem("user"))
    const formValues = removeEmptyObjectsFromArray(values)
    const updateValues = {
      ...formValues,
      status: "draft",
      surgeon_name: userData?.name
    }

    editMyPreferenceCard(templateId, updateValues).then(
      ({ data: res, error }) => {
        if (res?.success) {
          message.success(res?.message)
          navigate("/template-listing")
          return
        }
        if (error) message.error(error?.message)
      }
    )
  }

  return (
    <>
      <CreateItemModal
        isOpen={isCreateItemModalOpen}
        setIsOpen={setIsCreateItemModalOpen}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
      />
      <div className="w-100" style={{ margin: 0 }}>
        <Breadcrumb className="m-2 mx-3" style={{ margin: 0 }}>
          <Breadcrumb.Item>Templates</Breadcrumb.Item>
          <Breadcrumb.Item>{formValues.draft_name}</Breadcrumb.Item>
          <Breadcrumb.Item>Edit a Template</Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{ margin: 0 }} />
        <div>
          <div className="d-flex align-items-center m-3 gap-2">
            <LeftOutlined onClick={() => navigate("/template-listing")} />
            <h4 style={{ margin: 0 }}>Edit a Template</h4>
          </div>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
              draft_name: "",
              procedure_name: "",
              description: "",
              glove_size: "",
              equipments: [{ equipment: undefined, quantity: 1 }],
              instruments: [{ instrument: undefined, quantity: 1 }],
              supplies: [{ supply: undefined, quantity: 1 }],
              medications: [{ medication: undefined, quantity: 1 }],
              sutures: [{ suture: undefined, quantity: 1 }],
              dressings: [{ dressing: undefined, quantity: 1 }],
              special_requirements: [
                { special_requirement: undefined, quantity: 1 }
              ],
              notes: ""
            }}
          >
            <div
              className="d-flex flex-column gap-3 p-4"
              style={{ backgroundColor: "#F7F8F9" }}
            >
              <div className="d-flex mb-4">
                <div style={{ flexBasis: "40%" }}>
                  <h5>Template Details</h5>
                  <UserText>Add details about the template</UserText>
                </div>
                <div
                  style={{ flexBasis: "50%" }}
                  className="d-flex flex-column gap-3"
                >
                  <Form.Item
                    name="draft_name"
                    label="Template Name"
                    style={{ margin: 0 }}
                    rules={[
                      { required: true, message: "Please input template name!" }
                    ]}
                  >
                    <Input placeholder="Enter name" maxLength={255} />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex mb-4">
                <div style={{ flexBasis: "40%" }}>
                  <h5>Procedure Details</h5>
                  <UserText>Add details about the procedure</UserText>
                </div>
                <div
                  style={{ flexBasis: "50%" }}
                  className="d-flex flex-column gap-3"
                >
                  <Form.Item
                    name="procedure_name"
                    label="Procedure Name"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Please input procedure name!"
                      }
                    ]}
                  >
                    <Input placeholder="Enter name" maxLength={255} />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="Position Description"
                    style={{ margin: 0 }}
                    rules={[
                      { required: true, message: "Please select description!" }
                    ]}
                  >
                    <Input placeholder="Enter detail" maxLength={255} />
                  </Form.Item>
                  <Form.Item
                    name="glove_size"
                    label="Glove Size"
                    style={{ margin: 0 }}
                    rules={[
                      { required: true, message: "Please input glove size!" }
                    ]}
                  >
                    <Input placeholder="Enter glove size" maxLength={255} />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex mb-4">
                <div style={{ flexBasis: "40%" }}>
                  <h5>Items Details</h5>
                  <UserText>Add Items used in the procedure</UserText>
                </div>
                <div style={{ flexBasis: "50%" }}>
                  <DynamicFieldPrefCard
                    form={form}
                    name="equipments"
                    label="Equipments"
                    itemLabel="Equipment"
                    itemName="equipment"
                    itemPlaceholder="Select Equipment"
                    itemOptions={items?.equipment || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="instruments"
                    label="Instruments"
                    itemLabel="Instrument"
                    itemName="instrument"
                    itemPlaceholder="Select Instrument"
                    itemOptions={items?.instrument || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="supplies"
                    label="Supplies"
                    itemLabel="Supply"
                    itemName="supply"
                    itemPlaceholder="Select supplies"
                    itemOptions={items?.supply || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="medications"
                    label="Medication"
                    itemLabel="Medication"
                    itemName="medication"
                    itemPlaceholder="Select medications"
                    itemOptions={items?.medication || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="sutures"
                    label="Suture"
                    itemLabel="Suture"
                    itemName="suture"
                    itemPlaceholder="Select suture"
                    itemOptions={items?.suture || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="dressings"
                    label="Dressings"
                    itemLabel="Dressing"
                    itemName="dressing"
                    itemPlaceholder="Select dressing"
                    itemOptions={items?.dressing || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <DynamicFieldPrefCard
                    form={form}
                    name="special_requirements"
                    label="Special Requirements"
                    itemLabel="Special Requirement"
                    itemName="special_requirement"
                    itemPlaceholder="Select requirements"
                    itemOptions={items["special_requirement"] || []}
                    setSelectedCategory={setSelectedCategory}
                    setIsCreateItemModalOpen={setIsCreateItemModalOpen}
                  />
                  <Form.Item name="notes" label="Special Notes">
                    <Input.TextArea placeholder="Add notes here" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end p-2">
              <Button
                type="default"
                htmlType="button"
                onClick={() => navigate("/template-listing")}
              >
                Cancel
              </Button>
              <Button className="ms-2" type="primary" htmlType="submit">
                Save Changes
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

export default EditTemplate
