import { Route, Routes } from "react-router-dom"
import OneSignal from "react-onesignal"
import "./App.css"
import Login from "./pages/auth/Login"
import SignUp from "./pages/auth/SignUp"
import ForgetPasswrod from "./pages/auth/ForgetPasswrod"
import ResetPassword from "./pages/auth/ResetPassword"
import ProtectedRoute from "./components/ProtectedRouted"
import ProfileSetup from "./pages/auth/ProfileSetup"
import AddUser from "./pages/surgeon/userManagement/AddUser"
import UserListing from "./pages/surgeon/userManagement/UserListing"
import EditUser from "./pages/surgeon/userManagement/EditUser"
import ItemListing from "./pages/surgeon/preferenceCard/item/ItemListing"
import PreferenceCard from "./pages/surgeon/preferenceCard/PreferenecCard/PreferenceCardListing"
import MyPreferenceCard from "./pages/surgeon/preferenceCard/PreferenecCard/MyPreferenceCardListing"
import Setting from "./pages/surgeon/setting/Setting"
import SubscriptionPlan from "./pages/surgeon/subscriptionPlan/SubscriptionPlan"
import AddItem from "./pages/surgeon/preferenceCard/item/AddItem"
import EditItem from "./pages/surgeon/preferenceCard/item/EditItem"
import AddMyPreferenceCard from "./pages/surgeon/preferenceCard/PreferenecCard/AddMyPreferenceCard"
import EditMyPreferenceCard from "./pages/surgeon/preferenceCard/PreferenecCard/EditMyPreferenceCard"
import PreferenceCardDetail from "./pages/surgeon/preferenceCard/PreferenecCard/PreferenceCardDetail"
import TemplateListing from "./pages/surgeon/preferenceCard/template/TemplateListing"
import UseTemplate from "./pages/surgeon/preferenceCard/template/UseTemplate"
import EditTemplate from "./pages/surgeon/preferenceCard/template/EditTemplate"
import SavedProfileList from "./pages/nurse/generalCard/savedProfiles/SavedProfileList"
import UserProfileDetail from "./pages/nurse/generalCard/UserProfileDetail"
import GeneralCardListing from "./pages/nurse/generalCard/generalCard/GeneralCardListing"
import GeneralCardDetail from "./pages/nurse/generalCard/GeneralCardDetail"
import RequestSuggestionNurse from "./pages/nurse/generalCard/RequestSuggestionNurse"
import SuggestionRequestSurgeon from "./pages/surgeon/SuggestionRequestSurgeon"
import PackageSelection from "./pages/surgeon/PackageSelection"
import FreeTrialEndPackageScreen from "./pages/surgeon/FreeTrialEndPackageScreen"
import PDFFile from "./pages/PDFFile"
import ViewAllNotification from "./pages/notification/ViewAllNotification"
import { useEffect, useState } from "react"
import { foregroundWillDisplayListener } from "./components/HeaderNav"
import { Navigate } from "react-router-dom";
import TermsAndConditions from "./pages/TermsAndConditions"
import PrivacyPolicy from "./pages/PrivacyPolicy"

function App() {
  const [initialized, setInitialized] = useState(false)


  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/profile-setup" element={<ProfileSetup />} />
        <Route path="/forget-password" element={<ForgetPasswrod />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/package-selection" element={<PackageSelection />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/trail-end-package"
          element={<FreeTrialEndPackageScreen />}
        />

        <Route
          path="/user-listing"
          element={<ProtectedRoute component={UserListing} />}
        />
        <Route
          path="/add-user"
          element={<ProtectedRoute component={AddUser} />}
        />
        <Route
          path="/edit-user"
          element={<ProtectedRoute component={EditUser} />}
        />

        <Route
          path="/mypreferencecard-listing"
          element={<ProtectedRoute component={MyPreferenceCard} />}
        />
        <Route
          path="/preference-card-detail/:id"
          element={<ProtectedRoute component={PreferenceCardDetail} />}
        />
        <Route
          path="/add-preferencecard"
          element={<ProtectedRoute component={AddMyPreferenceCard} />}
        />
        <Route
          path="/edit-preferencecard"
          element={<ProtectedRoute component={EditMyPreferenceCard} />}
        />
        <Route
          path="/preferencecard-listing"
          element={<ProtectedRoute component={PreferenceCard} />}
        />
        <Route
          path="/suggestion-requests"
          element={<ProtectedRoute component={SuggestionRequestSurgeon} />}
        />

        <Route
          path="/item-listing"
          element={<ProtectedRoute component={ItemListing} />}
        />
        <Route
          path="/add-item"
          element={<ProtectedRoute component={AddItem} />}
        />
        <Route
          path="/edit-item"
          element={<ProtectedRoute component={EditItem} />}
        />

        <Route
          path="/template-listing"
          element={<ProtectedRoute component={TemplateListing} />}
        />
        <Route
          path="/use-template"
          element={<ProtectedRoute component={UseTemplate} />}
        />
        <Route
          path="/edit-template"
          element={<ProtectedRoute component={EditTemplate} />}
        />

        <Route
          path="/setting"
          element={<ProtectedRoute component={Setting} />}
        />

        <Route
          path="/subscription-plan"
          element={<ProtectedRoute component={SubscriptionPlan} />}
        />

        <Route
          path="/general-card-listing"
          element={<ProtectedRoute component={GeneralCardListing} />}
        />
        <Route
          path="/general-card-detail/:id"
          element={<ProtectedRoute component={GeneralCardDetail} />}
        />
        <Route
          path="/saved-profile-listing"
          element={<ProtectedRoute component={SavedProfileList} />}
        />
        <Route
          path="/user-profile/:id"
          element={<ProtectedRoute component={UserProfileDetail} />}
        />
        <Route
          path="/request-suggestion"
          element={<ProtectedRoute component={RequestSuggestionNurse} />}
        />

        <Route
          path="/PDF-file"
          element={<ProtectedRoute component={PDFFile} />}
        />
        <Route
          path="/view-all-notifications"
          element={<ProtectedRoute component={ViewAllNotification} />}
        />
      </Routes>
    </>
  )
}

export default App
