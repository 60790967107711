import { Flex, Image, Skeleton, message } from "antd"
import {
  AuthDescription,
  NotificationWraper,
  Text,
  UserText
} from "../../styledComponents"
import DefaultImage from "../../assets/DefaultImage.png"
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import {
  deleteNotification,
  fetchNotification,
  unreadNotificationCount
} from "../../api/DataService"
import GlobalContext from "../../components/GlobalContext"

const UnreadNotification = ({ loadNotification }) => {
  const navigate = useNavigate()
  const { notificationCountGlobalHandler } = useContext(GlobalContext)
  const [unreadNotificationData, setUnreadNotificationData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    fetchNotification(0).then(({ data: res }) => {
      setUnreadNotificationData(res?.results)
      setIsLoading(false)
    })
  }, [loadNotification])

  const deleteNotificationHandler = id => {
    deleteNotification(id).then(({ data: res }) => {
      message.success("Notification deleted successfully")
      fetchNotification(0).then(({ data: res }) => {
        setUnreadNotificationData(res?.results)
      })
      unreadNotificationCount().then(({ data: res }) => {
        if (res?.success) {
          notificationCountGlobalHandler(res?.data?.unread_count)
        }
      })
    })
  }
  return (
    <NotificationWraper style={{ height: "70vh", overflow: "auto" }}>
      {unreadNotificationData?.length ? (
        unreadNotificationData?.map((notification, index) => (
          <Flex gap={5} className="mb-3 p-2 bg-white" align="start" key={index}>
            <Image
              src={notification?.about_user?.profile_picture || DefaultImage}
              alt="User Avatar"
              className="rounded-circle mx-1"
              style={{ height: "36px", width: "36px" }}
            />
            <Flex
              vertical
              className="w-100"
              onClick={e => {
                notification?.about_user &&
                  navigate(
                    `/preference-card-detail/${notification.pref_card}`,
                    {
                      state: "personal"
                    }
                  )
              }}
            >
              <Flex justify="space-between" className="w-100">
                {notification.about_user && (
                  <>
                    <Text>
                      {notification.about_user.name}{" "}
                      <span className="text-muted">
                        ({notification.about_user.role})
                      </span>
                    </Text>
                    <span className="text-muted">
                      {notification.created_at_readable}
                    </span>
                  </>
                )}
              </Flex>
              <Flex align="center" className="w-100 gap-2" wrap="wrap">
                <Text>"{notification.title}"</Text>
                {!notification.about_user && (
                  <span className="text-muted ms-auto">
                    {notification.created_at_readable}
                  </span>
                )}
              </Flex>
              <UserText className="text-muted">{notification.message}</UserText>
            </Flex>
            <DeleteOutlined
              className="mt-1"
              style={{ color: "#D92D20" }}
              onClick={() => deleteNotificationHandler(notification.id)}
            />
            {!notification.read && (
              <span
                className="fs-3"
                style={{ color: "#12B76A", position: "relative", top: -10 }}
              >
                &#x2022;
              </span>
            )}
          </Flex>
        ))
      ) : isLoading ? (
        <>
          <Skeleton
            avatar
            paragraph={{
              rows: 2
            }}
          />
          <Skeleton
            avatar
            paragraph={{
              rows: 2
            }}
          />
          <Skeleton
            avatar
            paragraph={{
              rows: 2
            }}
          />
        </>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <InboxOutlined className="fs-1 text-muted" />
          <AuthDescription>No Notification Found</AuthDescription>
        </div>
      )}
    </NotificationWraper>
  )
}

export default UnreadNotification
