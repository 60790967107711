import { Button, Modal } from "antd"
import React, { useState } from "react"
import { UserText } from "../styledComponents"
import { ReloadOutlined } from "@ant-design/icons"

const UpgradeConfirmModal = ({
  isOpen,
  setIsOpen,
  headingText,
  descText,
  confirmHandler
}) => {
  const [isRefreshPageModalOpen, setIsRefreshPageModalOpen] = useState(false)

  return (
    <>
      <Modal
        open={isRefreshPageModalOpen}
        footer={null}
        width={"410px"}
        height={"280px"}
        closeIcon={false}
        centered
        className="text-center"
      >
        <h5 className="text-center">Refresh Page To View Updated Plan</h5>
        <div onClick={() => window.location.reload()} style={{ cursor: "pointer" }}>
          <ReloadOutlined className="fs-4 mt-2"/>
          <h6 className="text-center">Refresh</h6>
        </div>
      </Modal>
      <Modal
        open={isOpen}
        footer={null}
        width={"410px"}
        height={"280px"}
        closeIcon={false}
        centered
      >
        <h5 className="text-center">{headingText}</h5>
        <UserText className="mb-3">{descText}</UserText>
        <div className="d-flex gap-2">
          <Button
            style={{ flexBasis: "50%" }}
            type="default"
            onClick={() => setIsOpen(false)}
          >
            No
          </Button>
          <Button
            style={{ flexBasis: "50%" }}
            type="primary"
            onClick={() => confirmHandler(setIsRefreshPageModalOpen)}
          >
            Yes, Upgrade
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default UpgradeConfirmModal
