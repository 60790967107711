import React, { useEffect, useState } from "react"
import styled from "styled-components"
import NavItem from "./NavItem"
import { Image, Tooltip } from "antd"
import { fetchAdsBanner } from "../api/DataService"

function SidebarNav({ toggleSidebar, sidebarToggle, navItems }) {
  const [isHover, setIsHover] = useState(sidebarToggle || false)
  const [isExtraHover, setIsExtraHover] = useState(false)
  const [selectedHasExtra, setSelectedHasExtra] = useState(false)
  const [adsBanner, setAdsBanner] = useState({})
  const [hideAds, setHideAds] = useState(
    localStorage.getItem("hideVerticalAds") || false
  )
  useEffect(() => {
    fetchAdsBanner().then(({ data: res }) => {
      setAdsBanner(
        res?.results?.reduce((acc, item) => {
          acc[item.identifier] = item.image
          return acc
        }, {})
      )
    })
  }, [])

  useEffect(() => {
    if (selectedHasExtra) {
      setIsHover(false)
    }
  }, [selectedHasExtra])

  let activeStyle = {
    textDecoration: "underline"
  }

  let activeClassName = "underline"
  const handleToggleSidebar = () => {
    toggleSidebar()
  }

  const handleHideAds = () => {
    setHideAds(true)
    localStorage.setItem("hideVerticalAds", true)
  }

  return (
    <Container isExpaned="true">
      <SideMainContainer>
        <NavContainer>
          <NavList>
            {navItems?.map((items, index) => {
              if (!items) {
                return
              }

              return (
                <div key={index}>
                  <SidebarTypesHeading>
                    <NavItem
                      extraCallback={setSelectedHasExtra}
                      isHover={sidebarToggle}
                      text={items?.heading.itemName}
                      icon={items?.heading.itemIcon}
                      to={items?.heading.navigateTo}
                      active={items?.heading.active}
                      pathsToMatch={items?.heading.pathsToMatch}
                      isHeading={true}
                    />
                  </SidebarTypesHeading>
                  {items?.itemList?.map((subItems, subIndex) => {
                    return (
                      <div key={subIndex}>
                        {!sidebarToggle ? (
                          <Tooltip placement="right" title={subItems.itemName}>
                            <NavItem
                              extraCallback={setSelectedHasExtra}
                              isHover={sidebarToggle}
                              text={subItems.itemName}
                              icon={subItems.itemIcon}
                              to={subItems.navigateTo}
                              active={subItems.active}
                              pathsToMatch={subItems.pathsToMatch}
                            />
                          </Tooltip>
                        ) : (
                          <>
                            <NavItem
                              extraCallback={setSelectedHasExtra}
                              isHover={sidebarToggle}
                              text={subItems.itemName}
                              icon={subItems.itemIcon}
                              to={subItems.navigateTo}
                              active={subItems.active}
                              pathsToMatch={subItems.pathsToMatch}
                            />
                          </>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
            {!hideAds && (
              <div
                className="m-auto"
                style={{
                  position: "relative",
                  width: "200px",
                  height: "200px"
                }}
              >
                <Image
                  src={adsBanner?.vertical}
                  width={200}
                  height={200}
                  style={{ margin: "auto" }}
                  className="object-fit-cover"
                  preview={false}
                />
                <div
                  onClick={handleHideAds}
                  className="cross-button border-0"
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: -12,
                    right: -5
                  }}
                >
                  X
                </div>
              </div>
            )}
          </NavList>
        </NavContainer>
      </SideMainContainer>
    </Container>
  )
}
const Container = styled.div`
  width: 80px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  min-width: ${props => (props.isExpaned ? "240px" : "")};
`
const LogoContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;

  align-items: center;
  border-bottom: 1px solid #e4e7ec;
  padding: 5px 20px;
`
const SideMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 60px);
  border-right: 1px solid #e4eaf0;
  background-color: white;
`
const Logo = styled.img`
  height: 55.52220916748047px;
  width: 165.99986267089844px;
  object-fit: contain;
`

const NavContainer = styled.nav`
  width: 100%;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const NavList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  height: calc(100vh - 60px);
  padding: 30px 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ::-webkit-scrollbar {
    width: 0px;
  }
`

const NavListItem = styled.li`
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoutContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;

  margin-left: 12px;
`
const SidebarTypesHeading = styled.h2`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 38%);
  font-weight: 700;
`

export default SidebarNav
