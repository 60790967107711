import { Button, Divider, Form, Input, Select, Space } from "antd"
import React, { useState } from "react"
import QuantityInput from "./QuantityInput"
import AddNewFieldBtn from "./AddNewFieldBtn"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import styled from "styled-components"

const DynamicFieldPrefCard = ({
  form,
  name: fieldName,
  label,
  itemLabel,
  itemName,
  itemPlaceholder,
  itemOptions,
  setSelectedCategory,
  setIsCreateItemModalOpen,
  hideCreateItem = false //hide create item button for nurse request suggestion feature
}) => {
  const [newItems, setNewItems] = useState([])

  const handleAdd = add => {
    const newFieldKey = form.getFieldValue(fieldName).length // Use current length as the key for new item
    add({ [itemName]: undefined, quantity: 1 })
    setNewItems([...newItems, newFieldKey])
  }

  return (
    <Form.List
      name={fieldName}
      label={label}
      initialValue={[{ [itemName]: undefined, quantity: 1 }]}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => {
            const fieldValue = form.getFieldValue(fieldName)?.[0][itemName]
            const isDisabled =
              fieldValue !== undefined && !newItems.includes(key)
            return (
              <StyledSpace
                key={key}
                style={{
                  display: "flex",
                  flex: 1
                }}
                align={index === 0 ? "center" : "start"}
              >
                <Form.Item
                  {...restField}
                  name={[name, itemName]}
                  label={index === 0 ? itemLabel : ""}
                  style={{ flexBasis: "70%" }}
                >
                  <Select
                    placeholder={itemPlaceholder}
                    options={itemOptions || []}
                    className="flex-grow-1"
                    disabled={hideCreateItem && isDisabled}
                    dropdownRender={menu => {
                      return hideCreateItem ? (
                        menu
                      ) : (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0"
                            }}
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            style={{ color: "#2968DC" }}
                            className="w-100 text-start"
                            onClick={() => {
                              setSelectedCategory(itemLabel)
                              setIsCreateItemModalOpen(true)
                            }}
                          >
                            Create New Item
                          </Button>
                        </>
                      )
                    }}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "quantity"]}
                  label={index === 0 ? "Quantity" : ""}
                  style={{ flexBasis: "30%" }}
                >
                  <QuantityInput
                    value={restField.value?.quantity || 1}
                    onChange={newValue =>
                      form.setFields([
                        {
                          name: [name, "quantity"],
                          value: newValue
                        }
                      ])
                    }
                  />
                </Form.Item>
                <DeleteOutlined
                  onClick={() => {
                    remove(name)
                    setNewItems(newItems.filter(itemKey => itemKey !== key))
                    if (fields.length <= 1) {
                      handleAdd(add)
                    }
                  }}
                  className="text-danger mt-2"
                />
              </StyledSpace>
            )
          })}
          <Form.Item className="m-0">
            <AddNewFieldBtn add={() => handleAdd(add)} />
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

const StyledSpace = styled(Space)`
  & .ant-space-item:first-child {
    flex-basis: 50%;
    flex: 1;
  }
`

export default DynamicFieldPrefCard
