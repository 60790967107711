import React, { useEffect, useState } from "react"
import { Breadcrumb, Button, Divider, Input, Pagination, Table } from "antd"
import {
  LeftOutlined,
  DownloadOutlined,
  EditOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons"
import { useLocation, useNavigate } from "react-router-dom"
import {
  requestSuggestion,
  reviewSuggestion,
  suggestionRequestList
} from "../../api/DataService"
import { suggestionRequestColumns } from "../../constants/Headers"

const SuggestionRequestSurgeon = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state || {}
  const [searchText, setSearchText] = useState("")
  const [listData, setListData] = useState([])
  const [loader, setLoader] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedPendingRowKeys, setSelectedPendingRowKeys] = useState([])

  useEffect(() => {
    let timeoutId = null
    const delay = 500

    const fetchData = () => {
      setLoader(true)
      suggestionRequestList(state.id, searchText).then(({ data: res }) => {
        setSelectedPendingRowKeys([])
        setSelectedRowKeys([])
        setListData(res.data)
        setLoader(false)
      })
    }

    const debounceFetchData = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(fetchData, delay)
    }

    debounceFetchData()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchText])

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const pendingKeys = selectedRows.filter(item => item.status === "pending")
      const pendingKeysIds = pendingKeys.map(item => item.id)
      setSelectedPendingRowKeys(pendingKeysIds)
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  const approveRequest = record => {
    const formValues = [
      {
        id: record.id,
        status: "approved"
      }
    ]
    reviewSuggestion(formValues).then(() => {
      suggestionRequestList(state.id, searchText).then(({ data: res }) => {
        setListData(res.data)
      })
    })
  }

  const rejectRequest = record => {
    const formValues = [
      {
        id: record.id,
        status: "rejected"
      }
    ]
    reviewSuggestion(formValues).then(() => {
      suggestionRequestList(state.id, searchText).then(({ data: res }) => {
        setListData(res.data)
      })
    })
  }

  const approveAllRequest = () => {
    const formValues = selectedPendingRowKeys.map(id => ({
      id,
      status: "approved"
    }))
    selectedPendingRowKeys.length &&
    reviewSuggestion(formValues).then(() => {
      suggestionRequestList(state.id, searchText).then(({ data: res }) => {
        setListData(res.data)
      })
    })
  }

  const rejectAllRequest = () => {
    const formValues = selectedPendingRowKeys.map(id => ({
      id,
      status: "rejected"
    }))
    selectedPendingRowKeys.length &&
    reviewSuggestion(formValues).then(() => {
      suggestionRequestList(state.id, searchText).then(({ data: res }) => {
        setListData(res.data)
      })
    })
  }

  return (
    <div className="w-100" style={{ margin: 0 }}>
      <Breadcrumb className="m-2 mx-3" style={{ margin: 0 }}>
        <Breadcrumb.Item>Preference Cards</Breadcrumb.Item>
        <Breadcrumb.Item>{state.procedure_name}</Breadcrumb.Item>
        <Breadcrumb.Item>Suggestions</Breadcrumb.Item>
      </Breadcrumb>
      <Divider style={{ margin: 0 }} />
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center m-3 gap-2">
            <LeftOutlined onClick={() => navigate(-1)} />
            <h4 style={{ margin: 0 }}>Suggestion Requests</h4>
          </div>
        </div>
      </div>
      <div className="d-flex gap-3 m-3 justify-content-between">
        <Input
          placeholder="Search Requests..."
          prefix={<SearchOutlined />}
          onChange={e => setSearchText(e.target.value)}
          allowClear
          style={{ width: "478px" }}
        />
        <div className="d-flex gap-2">
          <Button
            type="default"
            icon={<CheckOutlined />}
            style={{ color: "#00914B" }}
            onClick={approveAllRequest}
          >
            Approve All
          </Button>
          <Button
            type="default"
            icon={<CloseOutlined />}
            style={{ color: "#D92D20" }}
            onClick={rejectAllRequest}
          >
            Reject All
          </Button>
        </div>
      </div>
      <div className="m-3">
        <Table
          columns={suggestionRequestColumns(approveRequest, rejectRequest)}
          dataSource={listData}
          pagination={false}
          loading={loader}
          rowSelection={rowSelection}
          rowKey="id"
        />
        <Pagination
          className="text-end mt-3"
          total={listData?.length}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultPageSize={20}
          defaultCurrent={1}
          pageSize={10}
        />
      </div>
    </div>
  )
}

export default SuggestionRequestSurgeon
