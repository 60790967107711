export const Countries = [
  {
    id: 1,
    value: 'AF',
    label: 'Afghanistan',
    phoneCode: 93,
  },
  {
    id: 2,
    value: 'AX',
    label: 'Åland Islands',
    phoneCode: 358,
  },  
  {
    id: 3,
    value: 'AL',
    label: 'Albania',
    phoneCode: 355,
  },
  {
    id: 4,
    value: 'DZ',
    label: 'Algeria',
    phoneCode: 213,
  },
  {
    id: 5,
    value: 'AS',
    label: 'American Samoa',
    phoneCode: 1684,
  },
  {
    id: 6,
    value: 'AD',
    label: 'Andorra',
    phoneCode: 376,
  },
  {
    id: 7,
    value: 'AO',
    label: 'Angola',
    phoneCode: 244,
  },
  {
    id: 8,
    value: 'AI',
    label: 'Anguilla',
    phoneCode: 1264,
  },
  {
    id: 9,
    value: 'AQ',
    label: 'Antarctica',
    phoneCode: 0,
  },
  {
    id: 10,
    value: 'AG',
    label: 'Antigua And Barbuda',
    phoneCode: 1268,
  },
  {
    id: 11,
    value: 'AR',
    label: 'Argentina',
    phoneCode: 54,
  },
  {
    id: 12,
    value: 'AM',
    label: 'Armenia',
    phoneCode: 374,
  },
  {
    id: 13,
    value: 'AW',
    label: 'Aruba',
    phoneCode: 297,
  },
  {
    id: 14,
    value: 'AC',
    label: 'Ascension Island',
    phoneCode: 247,
  },
  {
    id: 15,
    value: 'AU',
    label: 'Australia',
    phoneCode: 61,
  },
  {
    id: 16,
    value: 'AT',
    label: 'Austria',
    phoneCode: 43,
  },
  {
    id: 17,
    value: 'AZ',
    label: 'Azerbaijan',
    phoneCode: 994,
  },
  {
    id: 18,
    value: 'BS',
    label: 'Bahamas',
    phoneCode: 1242,
  },
  {
    id: 19,
    value: 'BH',
    label: 'Bahrain',
    phoneCode: 973,
  },
  {
    id: 20,
    value: 'BD',
    label: 'Bangladesh',
    phoneCode: 880,
  },
  {
    id: 21,
    value: 'BB',
    label: 'Barbados',
    phoneCode: 1246,
  },
  {
    id: 22,
    value: 'BY',
    label: 'Belarus',
    phoneCode: 375,
  },
  {
    id: 23,
    value: 'BE',
    label: 'Belgium',
    phoneCode: 32,
  },
  {
    id: 24,
    value: 'BZ',
    label: 'Belize',
    phoneCode: 501,
  },
  {
    id: 25,
    value: 'BJ',
    label: 'Benin',
    phoneCode: 229,
  },
  {
    id: 26,
    value: 'BM',
    label: 'Bermuda',
    phoneCode: 1441,
  },
  {
    id: 27,
    value: 'BT',
    label: 'Bhutan',
    phoneCode: 975,
  },
  {
    id: 28,
    value: 'BO',
    label: 'Bolivia',
    phoneCode: 591,
  },
  {
    id: 29,
    value: 'BQ',
    label: 'Bonaire, Sint Eustatius and Saba',
    phoneCode: 599,
  },
  {
    id: 30,
    value: 'BA',
    label: 'Bosnia and Herzegovina',
    phoneCode: 387,
  },
  {
    id: 31,
    value: 'BW',
    label: 'Botswana',
    phoneCode: 267,
  },
  {
    id: 32,
    value: 'BR',
    label: 'Brazil',
    phoneCode: 55,
  },
  {
    id: 33,
    value: 'BV',
    label: 'Bouvet Island',
    phoneCode: 47,
  },
  {
    id: 34,
    value: 'IO',
    label: 'British Indian Ocean Territory',
    phoneCode: 246,
  },
  {
    id: 35,
    value: 'BN',
    label: 'Brunei',
    phoneCode: 673,
  },
  {
    id: 36,
    value: 'BG',
    label: 'Bulgaria',
    phoneCode: 359,
  },
  {
    id: 37,
    value: 'BF',
    label: 'Burkina Faso',
    phoneCode: 226,
  },
  {
    id: 38,
    value: 'BI',
    label: 'Burundi',
    phoneCode: 257,
  },
  {
    id: 39,
    value: 'KH',
    label: 'Cambodia',
    phoneCode: 855,
  },
  {
    id: 40,
    value: 'CM',
    label: 'Cameroon',
    phoneCode: 237,
  },
  {
    id: 41,
    value: 'CA',
    label: 'Canada',
    phoneCode: 1,
  },
  {
    id: 42,
    value: 'CV',
    label: 'Cape Verde',
    phoneCode: 238,
  },
  {
    id: 43,
    value: 'KY',
    label: 'Cayman Islands',
    phoneCode: 1345,
  },
  {
    id: 44,
    value: 'CF',
    label: 'Central African Republic',
    phoneCode: 236,
  },
  {
    id: 45,
    value: 'TD',
    label: 'Chad',
    phoneCode: 235,
  },
  {
    id: 46,
    value: 'CL',
    label: 'Chile',
    phoneCode: 56,
  },
  {
    id: 47,
    value: 'CN',
    label: 'China',
    phoneCode: 86,
  },
  {
    id: 48,
    value: 'CX',
    label: 'Christmas Island',
    phoneCode: 61,
  },
  {
    id: 49,
    value: 'CC',
    label: 'Cocos (Keeling) Islands',
    phoneCode: 61,
  },
  {
    id: 50,
    value: 'CO',
    label: 'Colombia',
    phoneCode: 57,
  },
  {
    id: 51,
    value: 'KM',
    label: 'Comoros',
    phoneCode: 269,
  },
  {
    id: 52,
    value: 'CG',
    label: 'Republic Of The Congo',
    phoneCode: 242,
  },
  {
    id: 53,
    value: 'CD',
    label: 'Democratic Republic Of The Congo',
    phoneCode: 243,
  },
  {
    id: 54,
    value: 'CK',
    label: 'Cook Islands',
    phoneCode: 682,
  },
  {
    id: 55,
    value: 'CR',
    label: 'Costa Rica',
    phoneCode: 506,
  },
  {
    id: 56,
    value: 'CI',
    label: "Cote D''Ivoire (Ivory Coast)",
    phoneCode: 225,
  },
  {
    id: 57,
    value: 'HR',
    label: 'Croatia (Hrvatska)',
    phoneCode: 385,
  },
  {
    id: 58,
    value: 'CU',
    label: 'Cuba',
    phoneCode: 53,
  },
  {
    id: 59,
    value: 'CW',
    label: 'Curaçao',
    phoneCode: 599,
  },
  {
    id: 60,
    value: 'CY',
    label: 'Cyprus',
    phoneCode: 357,
  },
  {
    id: 61,
    value: 'CZ',
    label: 'Czech Republic',
    phoneCode: 420,
  },
  {
    id: 62,
    value: 'DK',
    label: 'Denmark',
    phoneCode: 45,
  },
  {
    id: 63,
    value: 'DJ',
    label: 'Djibouti',
    phoneCode: 253,
  },
  {
    id: 64,
    value: 'DM',
    label: 'Dominica',
    phoneCode: 1767,
  },
  {
    id: 65,
    value: 'DO',
    label: 'Dominican Republic',
    phoneCode: 1809,
  },
  {
    id: 66,
    value: 'TP',
    label: 'East Timor',
    phoneCode: 670,
  },
  {
    id: 67,
    value: 'EC',
    label: 'Ecuador',
    phoneCode: 593,
  },
  {
    id: 68,
    value: 'EG',
    label: 'Egypt',
    phoneCode: 20,
  },
  {
    id: 69,
    value: 'SV',
    label: 'El Salvador',
    phoneCode: 503,
  },
  {
    id: 70,
    value: 'GQ',
    label: 'Equatorial Guinea',
    phoneCode: 240,
  },
  {
    id: 71,
    value: 'ER',
    label: 'Eritrea',
    phoneCode: 291,
  },
  {
    id: 72,
    value: 'EE',
    label: 'Estonia',
    phoneCode: 372,
  },
  {
    id: 73,
    value: 'ET',
    label: 'Ethiopia',
    phoneCode: 251,
  },
  {
    id: 74,
    value: 'XA',
    label: 'External Territories of Australia',
    phoneCode: 61,
  },
  {
    id: 75,
    value: 'FK',
    label: 'Falkland Islands',
    phoneCode: 500,
  },
  {
    id: 76,
    value: 'FO',
    label: 'Faroe Islands',
    phoneCode: 298,
  },
  {
    id: 77,
    value: 'FJ',
    label: 'Fiji Islands',
    phoneCode: 679,
  },
  {
    id: 78,
    value: 'FI',
    label: 'Finland',
    phoneCode: 358,
  },
  {
    id: 79,
    value: 'FR',
    label: 'France',
    phoneCode: 33,
  },
  {
    id: 80,
    value: 'GF',
    label: 'French Guiana',
    phoneCode: 594,
  },
  {
    id: 81,
    value: 'PF',
    label: 'French Polynesia',
    phoneCode: 689,
  },
  {
    id: 82,
    value: 'TF',
    label: 'French Southern Territories',
    phoneCode: 262,
  },
  {
    id: 83,
    value: 'GA',
    label: 'Gabon',
    phoneCode: 241,
  },
  {
    id: 84,
    value: 'GM',
    label: 'Gambia',
    phoneCode: 220,
  },
  {
    id: 85,
    value: 'GE',
    label: 'Georgia',
    phoneCode: 995,
  },
  {
    id: 86,
    value: 'DE',
    label: 'Germany',
    phoneCode: 49,
  },
  {
    id: 87,
    value: 'GH',
    label: 'Ghana',
    phoneCode: 233,
  },
  {
    id: 88,
    value: 'GI',
    label: 'Gibraltar',
    phoneCode: 350,
  },
  {
    id: 89,
    value: 'GR',
    label: 'Greece',
    phoneCode: 30,
  },
  {
    id: 90,
    value: 'GL',
    label: 'Greenland',
    phoneCode: 299,
  },
  {
    id: 91,
    value: 'GD',
    label: 'Grenada',
    phoneCode: 1473,
  },
  {
    id: 92,
    value: 'GP',
    label: 'Guadeloupe',
    phoneCode: 590,
  },
  {
    id: 93,
    value: 'GU',
    label: 'Guam',
    phoneCode: 1671,
  },
  {
    id: 94,
    value: 'GT',
    label: 'Guatemala',
    phoneCode: 502,
  },
  {
    id: 95,
    value: 'GG',
    label: 'Guernsey and Alderney',
    phoneCode: 44,
  },
  {
    id: 96,
    value: 'GN',
    label: 'Guinea',
    phoneCode: 224,
  },
  {
    id: 97,
    value: 'GW',
    label: 'Guinea-Bissau',
    phoneCode: 245,
  },
  {
    id: 98,
    value: 'GY',
    label: 'Guyana',
    phoneCode: 592,
  },
  {
    id: 99,
    value: 'HT',
    label: 'Haiti',
    phoneCode: 509,
  },
  {
    id: 100,
    value: 'HM',
    label: 'Heard and McDonald Islands',
    phoneCode: 672,
  },
  {
    id: 101,
    value: 'HN',
    label: 'Honduras',
    phoneCode: 504,
  },
  {
    id: 102,
    value: 'HK',
    label: 'Hong Kong S.A.R.',
    phoneCode: 852,
  },
  {
    id: 103,
    value: 'HU',
    label: 'Hungary',
    phoneCode: 36,
  },
  {
    id: 104,
    value: 'IS',
    label: 'Iceland',
    phoneCode: 354,
  },
  {
    id: 105,
    value: 'IN',
    label: 'India',
    phoneCode: 91,
  },
  {
    id: 106,
    value: 'ID',
    label: 'Indonesia',
    phoneCode: 62,
  },
  {
    id: 107,
    value: 'IR',
    label: 'Iran',
    phoneCode: 98,
  },
  {
    id: 108,
    value: 'IQ',
    label: 'Iraq',
    phoneCode: 964,
  },
  {
    id: 109,
    value: 'IE',
    label: 'Ireland',
    phoneCode: 353,
  },
  {
    id: 110,
    value: 'IL',
    label: 'Israel',
    phoneCode: 972,
  },
  {
    id: 111,
    value: 'IT',
    label: 'Italy',
    phoneCode: 39,
  },
  {
    id: 112,
    value: 'JM',
    label: 'Jamaica',
    phoneCode: 1876,
  },
  {
    id: 113,
    value: 'JP',
    label: 'Japan',
    phoneCode: 81,
  },
  {
    id: 114,
    value: 'JE',
    label: 'Jersey',
    phoneCode: 44,
  },
  {
    id: 115,
    value: 'JO',
    label: 'Jordan',
    phoneCode: 962,
  },
  {
    id: 116,
    value: 'KZ',
    label: 'Kazakhstan',
    phoneCode: 7,
  },
  {
    id: 117,
    value: 'KE',
    label: 'Kenya',
    phoneCode: 254,
  },
  {
    id: 118,
    value: 'KI',
    label: 'Kiribati',
    phoneCode: 686,
  },
  {
    id: 119,
    value: 'XK',
    label: 'Kosovo',
    phoneCode: 383,
  },
  {
    id: 120,
    value: 'KP',
    label: 'Korea North',
    phoneCode: 850,
  },
  {
    id: 121,
    value: 'KR',
    label: 'Korea South',
    phoneCode: 82,
  },
  {
    id: 122,
    value: 'KW',
    label: 'Kuwait',
    phoneCode: 965,
  },
  {
    id: 123,
    value: 'KG',
    label: 'Kyrgyzstan',
    phoneCode: 996,
  },
  {
    id: 124,
    value: 'LA',
    label: 'Laos',
    phoneCode: 856,
  },
  {
    id: 125,
    value: 'LV',
    label: 'Latvia',
    phoneCode: 371,
  },
  {
    id: 126,
    value: 'LB',
    label: 'Lebanon',
    phoneCode: 961,
  },
  {
    id: 127,
    value: 'LS',
    label: 'Lesotho',
    phoneCode: 266,
  },
  {
    id: 128,
    value: 'LR',
    label: 'Liberia',
    phoneCode: 231,
  },
  {
    id: 129,
    value: 'LY',
    label: 'Libya',
    phoneCode: 218,
  },
  {
    id: 130,
    value: 'LI',
    label: 'Liechtenstein',
    phoneCode: 423,
  },
  {
    id: 131,
    value: 'LT',
    label: 'Lithuania',
    phoneCode: 370,
  },
  {
    id: 132,
    value: 'LU',
    label: 'Luxembourg',
    phoneCode: 352,
  },
  {
    id: 133,
    value: 'MO',
    label: 'Macau S.A.R.',
    phoneCode: 853,
  },
  {
    id: 134,
    value: 'MG',
    label: 'Madagascar',
    phoneCode: 261,
  },
  {
    id: 135,
    value: 'MW',
    label: 'Malawi',
    phoneCode: 265,
  },
  {
    id: 136,
    value: 'MK',
    label: 'Macedonia',
    phoneCode: 389,
  },
  {
    id: 137,
    value: 'MY',
    label: 'Malaysia',
    phoneCode: 60,
  },
  {
    id: 138,
    value: 'MV',
    label: 'Maldives',
    phoneCode: 960,
  },
  {
    id: 139,
    value: 'ML',
    label: 'Mali',
    phoneCode: 223,
  },
  {
    id: 140,
    value: 'MT',
    label: 'Malta',
    phoneCode: 356,
  },
  {
    id: 141,
    value: 'IM',
    label: 'Man (Isle of)',
    phoneCode: 44,
  },
  {
    id: 142,
    value: 'MH',
    label: 'Marshall Islands',
    phoneCode: 692,
  },
  {
    id: 143,
    value: 'MQ',
    label: 'Martinique',
    phoneCode: 596,
  },
  {
    id: 144,
    value: 'MR',
    label: 'Mauritania',
    phoneCode: 222,
  },
  {
    id: 145,
    value: 'MU',
    label: 'Mauritius',
    phoneCode: 230,
  },
  {
    id: 146,
    value: 'YT',
    label: 'Mayotte',
    phoneCode: 262,
  },
  {
    id: 147,
    value: 'MX',
    label: 'Mexico',
    phoneCode: 52,
  },
  {
    id: 148,
    value: 'FM',
    label: 'Micronesia',
    phoneCode: 691,
  },
  {
    id: 149,
    value: 'MD',
    label: 'Moldova',
    phoneCode: 373,
  },
  {
    id: 150,
    value: 'MC',
    label: 'Monaco',
    phoneCode: 377,
  },
  {
    id: 151,
    value: 'MN',
    label: 'Mongolia',
    phoneCode: 976,
  },
  {
    id: 152,
    value: 'ME',
    label: 'Montenegro',
    phoneCode: 382,
  },
  {
    id: 153,
    value: 'MS',
    label: 'Montserrat',
    phoneCode: 1664,
  },
  {
    id: 154,
    value: 'MA',
    label: 'Morocco',
    phoneCode: 212,
  },
  {
    id: 155,
    value: 'MZ',
    label: 'Mozambique',
    phoneCode: 258,
  },
  {
    id: 156,
    value: 'MM',
    label: 'Myanmar',
    phoneCode: 95,
  },
  {
    id: 157,
    value: 'NA',
    label: 'Namibia',
    phoneCode: 264,
  },
  {
    id: 158,
    value: 'NR',
    label: 'Nauru',
    phoneCode: 674,
  },
  {
    id: 159,
    value: 'NP',
    label: 'Nepal',
    phoneCode: 977,
  },
  {
    id: 160,
    value: 'AN',
    label: 'Netherlands Antilles',
    phoneCode: 599,
  },
  {
    id: 161,
    value: 'NL',
    label: 'Netherlands',
    phoneCode: 31,
  },
  {
    id: 162,
    value: 'NC',
    label: 'New Caledonia',
    phoneCode: 687,
  },
  {
    id: 163,
    value: 'NZ',
    label: 'New Zealand',
    phoneCode: 64,
  },
  {
    id: 164,
    value: 'NI',
    label: 'Nicaragua',
    phoneCode: 505,
  },
  {
    id: 165,
    value: 'NE',
    label: 'Niger',
    phoneCode: 227,
  },
  {
    id: 166,
    value: 'NG',
    label: 'Nigeria',
    phoneCode: 234,
  },
  {
    id: 167,
    value: 'NU',
    label: 'Niue',
    phoneCode: 683,
  },
  {
    id: 168,
    value: 'NF',
    label: 'Norfolk Island',
    phoneCode: 672,
  },
  {
    id: 169,
    value: 'MP',
    label: 'Northern Mariana Islands',
    phoneCode: 1670,
  },
  {
    id: 170,
    value: 'NO',
    label: 'Norway',
    phoneCode: 47,
  },
  {
    id: 171,
    value: 'OM',
    label: 'Oman',
    phoneCode: 968,
  },
  {
    id: 172,
    value: 'PK',
    label: 'Pakistan',
    phoneCode: 92,
  },
  {
    id: 173,
    value: 'PW',
    label: 'Palau',
    phoneCode: 680,
  },
  {
    id: 174,
    value: 'PS',
    label: 'Palestinian Territory Occupied',
    phoneCode: 970,
  },
  {
    id: 175,
    value: 'PA',
    label: 'Panama',
    phoneCode: 507,
  },
  {
    id: 176,
    value: 'PG',
    label: 'Papua new Guinea',
    phoneCode: 675,
  },
  {
    id: 177,
    value: 'PY',
    label: 'Paraguay',
    phoneCode: 595,
  },
  {
    id: 178,
    value: 'PE',
    label: 'Peru',
    phoneCode: 51,
  },
  {
    id: 179,
    value: 'PH',
    label: 'Philippines',
    phoneCode: 63,
  },
  {
    id: 180,
    value: 'PN',
    label: 'Pitcairn Island',
    phoneCode: 64,
  },
  {
    id: 181,
    value: 'PL',
    label: 'Poland',
    phoneCode: 48,
  },
  {
    id: 182,
    value: 'PT',
    label: 'Portugal',
    phoneCode: 351,
  },
  {
    id: 183,
    value: 'PR',
    label: 'Puerto Rico',
    phoneCode: 1787,
  },
  {
    id: 184,
    value: 'QA',
    label: 'Qatar',
    phoneCode: 974,
  },
  {
    id: 185,
    value: 'RE',
    label: 'Reunion',
    phoneCode: 262,
  },
  {
    id: 186,
    value: 'RO',
    label: 'Romania',
    phoneCode: 40,
  },
  {
    id: 187,
    value: 'RU',
    label: 'Russia',
    phoneCode: 7,
  },
  {
    id: 188,
    value: 'RW',
    label: 'Rwanda',
    phoneCode: 250,
  },
  {
    id: 189,
    value: 'BL',
    label: 'Saint Barthélemy',
    phoneCode: 590,
  },
  {
    id: 190,
    value: 'SH',
    label: 'Saint Helena',
    phoneCode: 290,
  },
  {
    id: 191,
    value: 'KN',
    label: 'Saint Kitts And Nevis',
    phoneCode: 1869,
  },
  {
    id: 192,
    value: 'LC',
    label: 'Saint Lucia',
    phoneCode: 1758,
  },
  {
    id: 193,
    value: 'MF',
    label: 'Saint Martin (French part)',
    phoneCode: 590,
  },
  {
    id: 194,
    value: 'PM',
    label: 'Saint Pierre and Miquelon',
    phoneCode: 508,
  },
  {
    id: 195,
    value: 'VC',
    label: 'Saint Vincent And The Grenadines',
    phoneCode: 1784,
  },
  {
    id: 196,
    value: 'WS',
    label: 'Samoa',
    phoneCode: 685,
  },
  {
    id: 197,
    value: 'SM',
    label: 'San Marino',
    phoneCode: 378,
  },
  {
    id: 198,
    value: 'ST',
    label: 'Sao Tome and Principe',
    phoneCode: 239,
  },
  {
    id: 199,
    value: 'SA',
    label: 'Saudi Arabia',
    phoneCode: 966,
  },
  {
    id: 200,
    value: 'SN',
    label: 'Senegal',
    phoneCode: 221,
  },
  {
    id: 201,
    value: 'RS',
    label: 'Serbia',
    phoneCode: 381,
  },
  {
    id: 202,
    value: 'SC',
    label: 'Seychelles',
    phoneCode: 248,
  },
  {
    id: 203,
    value: 'SL',
    label: 'Sierra Leone',
    phoneCode: 232,
  },
  {
    id: 204,
    value: 'SG',
    label: 'Singapore',
    phoneCode: 65,
  },
  {
    id: 205,
    value: 'SX',
    label: 'Sint Maarten',
    phoneCode: 1721,
  },
  {
    id: 206,
    value: 'SK',
    label: 'Slovakia',
    phoneCode: 421,
  },
  {
    id: 207,
    value: 'SI',
    label: 'Slovenia',
    phoneCode: 386,
  },
  {
    id: 208,
    value: 'XG',
    label: 'Smaller Territories of the UK',
    phoneCode: 44,
  },
  {
    id: 209,
    value: 'SB',
    label: 'Solomon Islands',
    phoneCode: 677,
  },
  {
    id: 210,
    value: 'SO',
    label: 'Somalia',
    phoneCode: 252,
  },
  {
    id: 211,
    value: 'ZA',
    label: 'South Africa',
    phoneCode: 27,
  },
  {
    id: 212,
    value: 'GS',
    label: 'South Georgia',
    phoneCode: 500,
  },
  {
    id: 213,
    value: 'SS',
    label: 'South Sudan',
    phoneCode: 211,
  },
  {
    id: 214,
    value: 'ES',
    label: 'Spain',
    phoneCode: 34,
  },
  {
    id: 215,
    value: 'LK',
    label: 'Sri Lanka',
    phoneCode: 94,
  },
  {
    id: 216,
    value: 'SD',
    label: 'Sudan',
    phoneCode: 249,
  },
  {
    id: 217,
    value: 'SR',
    label: 'Suriname',
    phoneCode: 597,
  },
  {
    id: 218,
    value: 'SJ',
    label: 'Svalbard And Jan Mayen Islands',
    phoneCode: 47,
  },
  {
    id: 219,
    value: 'SZ',
    label: 'Swaziland',
    phoneCode: 268,
  },
  {
    id: 220,
    value: 'SE',
    label: 'Sweden',
    phoneCode: 46,
  },
  {
    id: 221,
    value: 'CH',
    label: 'Switzerland',
    phoneCode: 41,
  },
  {
    id: 222,
    value: 'SY',
    label: 'Syria',
    phoneCode: 963,
  },
  {
    id: 223,
    value: 'TW',
    label: 'Taiwan',
    phoneCode: 886,
  },
  {
    id: 224,
    value: 'TJ',
    label: 'Tajikistan',
    phoneCode: 992,
  },
  {
    id: 225,
    value: 'TZ',
    label: 'Tanzania',
    phoneCode: 255,
  },
  {
    id: 226,
    value: 'TH',
    label: 'Thailand',
    phoneCode: 66,
  },
  {
    id: 227,
    value: 'TL',
    label: 'Timor-Leste',
    phoneCode: 670,
  },
  {
    id: 228,
    value: 'TG',
    label: 'Togo',
    phoneCode: 228,
  },
  {
    id: 229,
    value: 'TK',
    label: 'Tokelau',
    phoneCode: 690,
  },
  {
    id: 230,
    value: 'TO',
    label: 'Tonga',
    phoneCode: 676,
  },
  {
    id: 231,
    value: 'TT',
    label: 'Trinidad And Tobago',
    phoneCode: 1868,
  },
  {
    id: 232,
    value: 'TA',
    label: 'Tristan da Cunha',
    phoneCode: 290,
  },
  {
    id: 233,
    value: 'TN',
    label: 'Tunisia',
    phoneCode: 216,
  },
  {
    id: 234,
    value: 'TR',
    label: 'Turkey',
    phoneCode: 90,
  },
  {
    id: 235,
    value: 'TM',
    label: 'Turkmenistan',
    phoneCode: 993,
  },
  {
    id: 236,
    value: 'TC',
    label: 'Turks And Caicos Islands',
    phoneCode: 1649,
  },
  {
    id: 237,
    value: 'TV',
    label: 'Tuvalu',
    phoneCode: 688,
  },
  {
    id: 238,
    value: 'UG',
    label: 'Uganda',
    phoneCode: 256,
  },
  {
    id: 239,
    value: 'UA',
    label: 'Ukraine',
    phoneCode: 380,
  },
  {
    id: 240,
    value: 'AE',
    label: 'United Arab Emirates',
    phoneCode: 971,
  },
  {
    id: 241,
    value: 'GB',
    label: 'United Kingdom',
    phoneCode: 44,
  },
  {
    id: 242,
    value: 'US',
    label: 'United States',
    phoneCode: 1,
  },
  {
    id: 243,
    value: 'UM',
    label: 'United States Minor Outlying Islands',
    phoneCode: 1,
  },
  {
    id: 244,
    value: 'UY',
    label: 'Uruguay',
    phoneCode: 598,
  },
  {
    id: 245,
    value: 'UZ',
    label: 'Uzbekistan',
    phoneCode: 998,
  },
  {
    id: 246,
    value: 'VU',
    label: 'Vanuatu',
    phoneCode: 678,
  },
  {
    id: 247,
    value: 'VA',
    label: 'Vatican City State (Holy See)',
    phoneCode: 379,
  },
  {
    id: 248,
    value: 'VE',
    label: 'Venezuela',
    phoneCode: 58,
  },
  {
    id: 249,
    value: 'VN',
    label: 'Vietnam',
    phoneCode: 84,
  },
  {
    id: 250,
    value: 'VG',
    label: 'Virgin Islands (British)',
    phoneCode: 1284,
  },
  {
    id: 251,
    value: 'VI',
    label: 'Virgin Islands (US)',
    phoneCode: 1340,
  },
  {
    id: 252,
    value: 'WF',
    label: 'Wallis And Futuna Islands',
    phoneCode: 681,
  },
  {
    id: 253,
    value: 'EH',
    label: 'Western Sahara',
    phoneCode: 212,
  },
  {
    id: 254,
    value: 'YE',
    label: 'Yemen',
    phoneCode: 967,
  },
  {
    id: 255,
    value: 'YU',
    label: 'Yugoslavia',
    phoneCode: 38,
  },
  {
    id: 256,
    value: 'ZM',
    label: 'Zambia',
    phoneCode: 260,
  },
  {
    id: 257,
    value: 'ZW',
    label: 'Zimbabwe',
    phoneCode: 263,
  },
];

export const getCountryCode = value => {
  const object = Countries.find(item => value === item.value);
  return `${object?.phoneCode}`;
};

export const getCountryLabelAndCode = (value, phoneCode) => {
  const country = Countries.find(country => country.phoneCode == phoneCode && country.value === value);
  if (country) {
    return `${country.label}_${country.value}_${country.phoneCode}`;
  } else {
    return null;
  }
};
