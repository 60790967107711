import { Button, Form, Input, message } from "antd"
import React from "react"
import { UserText } from "../../../styledComponents"
import { changePassword } from "../../../api/DataService"

const PasswordSecurity = () => {
  const [form] = Form.useForm()
  const handleSubmit = values => {
    if (values.new_password1 !== values.new_password2) {
      message.error("New password and confirm password should be same")
      return
    }
    if (values.old_password === values.new_password1) {
      message.error("Old password and new password should not be same")
      return
    }

    changePassword(values).then(({ data: res, error }) => {
      if (res?.status === 200) {
        message.success(res?.message)
        return
      }

      if (error) {
        Object.entries(error).forEach(([key, value]) => {
          if (key === "message") {
            message.error(value)
          }
        })
      }
    })
  }
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          old_password: "",
          new_password1: "",
          new_password2: ""
        }}
      >
        <div
          className="d-flex flex-column gap-3 p-4"
          style={{ backgroundColor: "#F7F8F9" }}
        >
          <div className="d-flex mb-4">
            <div style={{ flexBasis: "40%" }}>
              <h5>Reset Password</h5>
              <UserText>You can reset your account password</UserText>
            </div>
            <div
              style={{ flexBasis: "50%" }}
              className="d-flex flex-column gap-3"
            >
              <Form.Item
                name="old_password"
                label="Old Password"
                style={{ margin: 0 }}
                rules={[
                  { required: true, message: "Please input old password!" }
                ]}
              >
                <Input.Password placeholder="Enter old password" />
              </Form.Item>
              <Form.Item
                name="new_password1"
                label="New Password"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Please input new password!"
                  }
                ]}
              >
                <Input.Password placeholder="Enter new password" />
              </Form.Item>
              <Form.Item
                name="new_password2"
                label="Confirm New Password"
                style={{ margin: 0 }}
                rules={[
                  { required: true, message: "Please input confirm password!" }
                ]}
              >
                <Input.Password placeholder="Re-enter new password" />
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item
          className="d-flex justify-content-end m-2"
          style={{ margin: 0 }}
        >
          <Button className="ms-2" type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default PasswordSecurity
