export const BASE_URL = "https://eg-app-46443-staging.botics.co";
//export const BASE_URL = "https://f9c7-116-58-60-28.ngrok-free.app"

export const apiEndPoints = {
    LOGIN: "/api/v1/login/",
    SIGNUP: "/api/v1/signup/",
    FORGET_PASSWORD: "/api/v1/forgot-password/",
    RESET_PASSWORD: "/api/v1/reset-password/",
    CHANGE_PASSWORD: "/api/v1/change-password/",

    USER_LISTING: (search, role, currentPage) => `/api/v1/user/?search=${search}&role=${role}&page=${currentPage}`,
    SINGLE_USER: (id) => `/api/v1/user/${id}/`,
    ADD_USER: "/api/v1/user/",
    EDIT_USER: (id) => `/api/v1/user/${id}/`,
    DELETE_USER: (id) => `/api/v1/user/${id}/`,

    ITEM_LISTING: (search, category, currentPage) => `/api/v1/item/?search=${search}&category=${category}&page=${currentPage}`,
    ADD_ITEM: "/api/v1/item/",
    EDIT_ITEM: (id) => `/api/v1/item/${id}/`,
    DELETE_ITEM: `/api/v1/item/delete/`,

    MY_PREFERENCE_CARD_LISTING: (search, currentPage) => `/api/v1/preference-card/?search=${search}&page=${currentPage}`,
    SINGLE_PREFERENCE_CARD: (id) => `/api/v1/preference-card/${id}/`,
    ADD_MY_PREFERENCE_CARD: "/api/v1/preference-card/",
    EDIT_MY_PREFERENCE_CARD: (id) => `/api/v1/preference-card/${id}/`,
    DELETE_MY_PREFERENCE_CARD: `/api/v1/preference-card/delete/`,
    // EXPORT_PREFERENCE_CARD: (id) => `/api/v1/preference-card/${id}/export/`,
    // DUPLICATE_PREFERENCE_CARD: (id) => `/api/v1/preference-card/${id}/duplicate/`,
    PREFERENCE_CARD_LISTING: (search, currentPage) => `/api/v1/preference-card/general/?search=${search}&page=${currentPage}`,
    FETCH_PREFERENCE_CARD_BULK: "/api/v1/preference-card/bulk-details/",


    TEMPLATE_LISTING: (search) => `/api/v1/preference-card/template/?search=${search}`,
    ADD_TEMPLATE: (id) => `/api/v1/use-template/${id}/`,

    FETCH_CATEGORIES: "/api/v1/lookup/categories/",
    FETCH_ITEMS: "/api/v1/lookup/items/",
    FETCH_SURGEON_LIST: "/api/v1/lookup/surgeons/",


    SAVE_PROFILE: "/api/v1/bookmark-surgeon/",
    SAVED_PROFILE_LISTING: (search) => `/api/v1/bookmark-surgeon/?search=${search}`,
    REMOVE_SAVED_PROFILE: (id) => `/api/v1/bookmark-surgeon/remove/?surgeon=${id}`,
    USER_PROFILE: (id) => `/api/v1/surgeon/${id}/preference-cards/`,
    REQUEST_SUGGESTION: "/api/v1/suggest-item/",
    SUGGESTION_REQUEST_LISTING: (id, search) => `/api/v1/preference-card/${id}/suggestions/?search=${search}`,
    REVIEW_SUGGESTION: `/api/v1/review/suggestions/`,

    ASSIGN_FREE_PACKAGE: "/api/v1/assign-free-package/",
    DUPLICATE_PREF_CARD: (id) => `/api/v1/duplicate/preference-card/${id}/`,
    FETCH_ALL_NOTIFICATION: `/api/v1/notification/`,
    FETCH_NOTIFICATION: (read) => `/api/v1/notification/?read=${read}`,
    DELETE_NOTIFICATION: (id) => `/api/v1/notification/${id}/`,
    DELETE_ALL_NOTIFICATION: "/api/v1/notification/delete_all/",
    MARK_ALL_AS_READ_NOTIFICATION: "/api/v1/notification/mark_all_as_read/",
    UNREAD_NOTIFICATION_COUNT: "/api/v1/notification/unread_count/",
    UPDATE_NOTIFICATION_SETTINGS: (id) => `/api/v1/notification/update_settings/`,
    FETCH_NOTIFICATION_SETTINGS: "/api/v1/notification/fetch_settings/",

    FETCH_SUBSCRIPTION: "/modules/subscription/get_subscription_plans/",
    BUY_SUBSCRIPTION: "/modules/subscription/buy_subscription_plan/",
    CUSTOMER_PORTAL: "/modules/subscription/customer_portal/",
    CANCEL_SUBSCRIPTION: "/modules/subscription/cancel_subscription_plan/",
    FETCH_SUBSCRIPTION_PLAN: "/modules/subscription/user_subscription/",

    FETCH_ADS_BANNER: "/api/v1/advertisement/",
}