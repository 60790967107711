import React from "react"
import { PlusOutlined } from "@ant-design/icons"

const AddNewFieldBtn = ({ add }) => {
  return (
    <div
      className="d-flex gap-2"
      style={{
        color: "#2968DC",
        cursor: "pointer",
        position: "absolute",
        top: -10
      }}
      onClick={() => add()}
    >
      <PlusOutlined />
      Add new
    </div>
  )
}

export default AddNewFieldBtn
