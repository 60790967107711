import { Button, Modal } from "antd"
import React from "react"
import { DeleteOutlined } from "@ant-design/icons"
import { UserText } from "../styledComponents"

const DeleteModal = ({isOpen, setIsOpen, deleteHandler, headingText, descText}) => {
  return (
    <Modal open={isOpen} footer={null} width={"416px"} height={"280px"} closeIcon={false} centered>
      <div style={{background: "#FBEEED", width: "80px", height: "80px"}} className="d-flex justify-content-center p-3 rounded-circle mx-auto mb-3">
        <DeleteOutlined style={{color: "#D92D20", fontSize: "30px"}}/>
      </div>
      <h5 className="text-center">{headingText}</h5>
      <UserText className="mb-3">{descText}</UserText>
      <div className="d-flex gap-2">
        <Button style={{flexBasis: "50%"}} type="default" onClick={() => setIsOpen(false)}>No</Button>
        <Button style={{flexBasis: "50%"}} type="primary" onClick={() => deleteHandler()}>Yes, Delete</Button>
      </div>
    </Modal>
  )
}

export default DeleteModal
