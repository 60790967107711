import { Image, Space } from "antd"
import { ReactComponent as EditIcon } from "../assets/EditIcon.svg"
import { ReactComponent as DeleteIcon } from "../assets/DeleteIcon.svg"
import styled from "styled-components"
import { convertToTitleCaseWithSpaces } from "../util/commonUtil"
import { render } from "react-dom"
import { Link, useNavigate } from "react-router-dom"
import {
  CopyOutlined,
  DownloadOutlined,
  CheckOutlined,
  CloseOutlined,
  ArrowRightOutlined,
  MinusOutlined,
  PlusOutlined,
  LeftOutlined
} from "@ant-design/icons"
import DefaulImage from "../assets/DefaultImage.png"

function formatDate(date) {
  const parts = date.split("/")
  return `${parts[1]}/${parts[0]}/${parts[2]}`
}

export const userListingColumns = (handleEdit, handleDelete) => [
  {
    title: "Username",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email"
  },
  {
    title: "Contact",
    dataIndex: "phone_number",
    key: "phone_number",
    render: (_, record) => {
      const phone_number = !record?.phone_number?.split("_")[2]
        ? record?.phone_number
        : `(${record?.phone_number?.split("_")[1]}) ${record?.phone_number?.split("_")[2]}`

      return (
        <div className="d-flex gap-2 align-items-center">
          <span>{phone_number}</span>
        </div>
      )
    }
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    render: (_, record) => (
      <CapsuleTab bgColor="#F5F8FE" color="#1D57C5">
        {convertToTitleCaseWithSpaces(record.role)}
      </CapsuleTab>
    )
  },
  {
    title: "Designation",
    dataIndex: "designation",
    key: "designation"
  },
  {
    title: "Status",
    dataIndex: "is_active",
    key: "is_active",
    render: (_, record) => (
      <CapsuleTab
        bgColor={record.is_active ? "#E9F6E8" : "#EEF1F4"}
        color={record.is_active ? "#00914B" : "#1B2B4B"}
      >
        {record.is_active ? "Active" : "Inactive"}
      </CapsuleTab>
    )
  },
  {
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Button
          onClick={event => {
            event.stopPropagation()
            handleEdit && handleEdit(record)
          }}
        >
          <EditIcon />
        </Button>
        <Button
          onClick={event => {
            event.stopPropagation()
            handleDelete && handleDelete(record)
          }}
        >
          <DeleteIcon />
        </Button>
      </Space>
    )
  }
]

export const itemListingColumns = (handleEdit, handleDelete) => [
  {
    title: "Item Name",
    dataIndex: "name",
    key: "name",
    render: (_, record) => (
      <div className="d-flex gap-2 align-items-center">
        {record.item_image && (
          <Image
            className="rounded-circle"
            src={record.item_image}
            alt="profile"
            preview={false}
            width={36}
            height={36}
          />
        )}
        <span>{record.name}</span>
      </div>
    )
  },
  {
    title: "Item Type",
    dataIndex: "category_name",
    key: "category_name"
  },
  {
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Button
          onClick={event => {
            event.stopPropagation()
            handleEdit && handleEdit(record)
          }}
        >
          <EditIcon />
        </Button>
        <Button
          onClick={event => {
            event.stopPropagation()
            handleDelete && handleDelete(record)
          }}
        >
          <DeleteIcon />
        </Button>
      </Space>
    )
  }
]

export const preferenceCardListingColumns = (
  navigate,
  handleEdit,
  exportPDF,
  duplicateRecord,
  handleDelete
) => {
  return [
    {
      title: "Procedure Name",
      dataIndex: "procedure_name",
      key: "procedure_name",
      render: (_, record) => (
        <div
          onClick={() =>
            navigate(`/preference-card-detail/${record.id}`, {
              state: "personal"
            })
          }
          className="text-decoration-none"
          style={{ cursor: "pointer" }}
        >
          {record.procedure_name}
        </div>
      )
    },
    {
      title: "Creation Date",
      dataIndex: "create_at",
      key: "create_at",
      render: (_, record) => <div>{formatDate(record.create_at)}</div>
    },
    {
      title: "Total Items",
      dataIndex: "total_items",
      key: "total_items"
    },
    {
      title: "Suggestions",
      dataIndex: "total_suggestions",
      key: "total_suggestions"
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={event => {
              event.stopPropagation()
              handleEdit && handleEdit(record)
            }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={event => {
              event.stopPropagation()
              exportPDF && exportPDF(record)
            }}
          >
            <DownloadOutlined />
          </Button>
          <Button
            onClick={event => {
              event.stopPropagation()
              duplicateRecord && duplicateRecord(record)
            }}
          >
            <CopyOutlined />
          </Button>
          <Button
            onClick={event => {
              event.stopPropagation()
              handleDelete && handleDelete(record)
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      )
    }
  ]
}

export const generalPreferenceCardListingColumns = (navigate, exportPDF) => {
  return [
    {
      title: "Surgeon Name",
      dataIndex: "surgeon_name",
      key: "surgeon_name",
      render: (_, record) => (
        <div
          onClick={() =>
            navigate(`/preference-card-detail/${record.id}`, {
              state: "general"
            })
          }
          className="text-decoration-none"
          style={{ cursor: "pointer" }}
        >
          {record.surgeon_name}
        </div>
      )
    },
    {
      title: "Procedure Name",
      dataIndex: "procedure_name",
      key: "procedure_name"
    },
    {
      title: "Creation Date",
      dataIndex: "create_at",
      key: "create_at",
      render: (_, record) => <div>{formatDate(record.create_at)}</div>
    },
    {
      title: "Total Items",
      dataIndex: "total_items",
      key: "total_items"
    },
    {
      title: "Suggestions",
      dataIndex: "total_suggestions",
      key: "total_suggestions"
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={event => {
              event.stopPropagation()
              exportPDF && exportPDF(record)
            }}
          >
            <DownloadOutlined />
          </Button>
        </Space>
      )
    }
  ]
}

export const detailColumns = columnName => [
  {
    title: "Name",
    dataIndex: columnName,
    key: columnName,
    width: "50%"
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity"
  }
]

export const detailDescriptionColumns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description"
  }
]

export const userProfilePrefListingColumns = (
  navigate,
  exportPDF,
  surgeonID
) => {
  return [
    {
      title: "Procedure Name",
      dataIndex: "procedure_name",
      key: "procedure_name",
      render: (_, record) => (
        <div
          onClick={() =>
            navigate(`/general-card-detail/${record.id}`, { state: surgeonID })
          }
          className="text-decoration-none"
          style={{ cursor: "pointer" }}
        >
          {record.procedure_name}
        </div>
      )
    },
    {
      title: "Creation Date",
      dataIndex: "create_at",
      key: "create_at",
      render: (_, record) => <div>{formatDate(record.create_at)}</div>
    },
    {
      title: "Total Items",
      dataIndex: "total_items",
      key: "total_items"
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={event => {
              event.stopPropagation()
              exportPDF && exportPDF(record)
            }}
          >
            <DownloadOutlined />
          </Button>
        </Space>
      )
    }
  ]
}

export const generalCardListingNurseColumns = (navigate, exportPDF) => {
  return [
    {
      title: "Surgeon Name",
      dataIndex: "surgeon_name",
      key: "surgeon_name",
      render: (_, record) => (
        <div
          onClick={() =>
            navigate(`/general-card-detail/${record.id}`, {
              state: record.created_by
            })
          }
          className="text-decoration-none"
          style={{ cursor: "pointer" }}
        >
          {record.surgeon_name}
        </div>
      )
    },
    {
      title: "Procedure Name",
      dataIndex: "procedure_name",
      key: "procedure_name"
    },
    {
      title: "Creation Date",
      dataIndex: "create_at",
      key: "create_at",
      render: (_, record) => <div>{formatDate(record.create_at)}</div>
    },
    {
      title: "Total Items",
      dataIndex: "total_items",
      key: "total_items"
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={event => {
              event.stopPropagation()
              exportPDF && exportPDF(record)
            }}
          >
            <DownloadOutlined />
          </Button>
        </Space>
      )
    }
  ]
}

export const suggestionRequestColumns = (approveRequest, rejectRequest) => {
  return [
    {
      title: "Procedure Name",
      dataIndex: "procedure_name",
      key: "procedure_name"
    },
    {
      title: "Suggestion Type",
      dataIndex: "suggestion_type",
      key: "suggestion_type"
    },
    {
      title: "Item",
      dataIndex: "item_name",
      key: "item_name"
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record) => (
        <div className="d-flex gap-2 align-items-center">
          {record.suggestion_type == "Quantity Change" && (
            <span>
              {record.prev_quantity} <ArrowRightOutlined />
            </span>
          )}
          {record.suggestion_type == "Item Add" && (
            <span>
              <PlusOutlined />
            </span>
          )}
          {record.suggestion_type == "Item Remove" && (
            <span>
              <MinusOutlined />
            </span>
          )}
          <span>{record.quantity}</span>
        </div>
      )
    },
    {
      title: "Suggested By",
      dataIndex: "suggestor_name",
      key: "suggestor_name",
      render: (_, record) => (
        <div className="d-flex gap-2 align-items-center">
          <Image
            className="rounded-circle"
            src={record.suggestor_image || DefaulImage}
            alt="profile"
            preview={false}
            width={36}
            height={36}
          />
          <span>{record.suggestor_name}</span>
        </div>
      )
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          {record.status == "pending" ? (
            <Space size="middle">
              <Button
                onClick={event => {
                  event.stopPropagation()
                  approveRequest && approveRequest(record)
                }}
                style={{
                  color: "#00914B",
                  border: "1px solid #00914B",
                  width: "90px"
                }}
                className="p-1 rounded-2 d-flex align-items-center justify-content-center gap-1"
              >
                <CheckOutlined /> Approve
              </Button>
              <Button
                onClick={event => {
                  event.stopPropagation()
                  rejectRequest && rejectRequest(record)
                }}
                style={{
                  color: "#D92D20",
                  border: "1px solid #D92D20",
                  width: "90px"
                }}
                className="p-1 rounded-2 d-flex align-items-center justify-content-center gap-1"
              >
                <CloseOutlined /> Reject
              </Button>
            </Space>
          ) : (
            <div>{convertToTitleCaseWithSpaces(record.status)}</div>
          )}
        </>
      )
    }
  ]
}

const Button = styled.button`
  border: none;
  background: none;
`

const CapsuleTab = styled.div`
  display: inline-block;
  background-color: ${props => props.bgColor || "transparent"};
  color: ${props => props.color || "#000000"};
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`
