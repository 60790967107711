import React, { useEffect, useState } from "react"
import { Text, UserText } from "../../../styledComponents"
import { CheckCircleFilled, RiseOutlined } from "@ant-design/icons"
import { Button, Divider, message } from "antd"
import UpgradePlanModal from "../../../components/UpgradePlanModal"
import {
  cancelSubscription,
  customerPortal,
  fetchSubscriptionPlan
} from "../../../api/DataService"
import { convertToTitleCaseWithSpaces } from "../../../util/commonUtil"
import UpgradeConfirmModal from "../../../components/UpgradeConfirmModal"
import CancelSubscriptionModal from "../../../components/CancelSubscriptionModal"

const SubscriptionPlan = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [subscriptionID, setSubscriptionID] = useState("")
  const [subscriptionPlan, setSubscriptionPlan] = useState([])

  const customerPortalHandler = () => {
    customerPortal().then(({ data: res, error }) => {
      if (res?.url) {
        window.open(res?.url, "_blank")
      }
      if (error) message.error(error?.message)
    })
  }

  const cancelSubscriptionHandler = () => {
    cancelSubscription(subscriptionID).then(({ data: res, error }) => {
      if (error) {
        message.error(error.message)
        return
      }
      message.success("Subscription Cancelled Successfully")
      window.location.reload()
    })
  }

  useEffect(() => {
    fetchSubscriptionPlan().then(({ data: res }) => {
      setSubscriptionPlan(res?.result)
      setSubscriptionID(res?.result?.user_subscription?.subscription_id)
    })
  }, [])

  return (
    <>
      <UpgradePlanModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        headingText="Upgrade Plan"
        descText="Are you sure you want to upgrade your plan?"
      />
      <CancelSubscriptionModal
        isOpen={isCancelModalOpen}
        setIsOpen={setIsCancelModalOpen}
        headingText="Cancel Subscription"
        deleteHandler={cancelSubscriptionHandler}
      />
      <div className="w-100">
        <h3 className="m-3">Subscription Plans</h3>
        <div className="d-flex flex-column gap-3 p-4">
          <div className="d-flex mb-4">
            <div style={{ flexBasis: "40%" }}>
              <h5>Subscribed Plan</h5>
              <UserText>You can cancel subscription anytime</UserText>
            </div>
            <div
              style={{ flexBasis: "50%" }}
              className="d-flex flex-column gap-3 justify-content-center"
            >
              <div
                className="p-3 rounded-2"
                style={{ border: "2px solid #1D57C5" }}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="d-flex gap-3 align-items-center">
                      <h5>
                        {convertToTitleCaseWithSpaces(
                          subscriptionPlan?.user_package?.type
                        )}
                      </h5>{" "}
                      <span className="border border-1 px-1 py-0 rounded-2">
                        Monthly
                      </span>
                    </span>
                    <span>
                      {subscriptionPlan?.user_package?.users_limit} Seats
                    </span>
                  </div>
                  <span className="d-flex align-items-baseline">
                    <h1>${subscriptionPlan?.user_package?.price}</h1>
                    {/* <sub>per User</sub> */}
                  </span>
                </div>
                <div className="d-flex align-items-baseline justify-content-between">
                  <div>
                    <Text className="mb-3 mt-5">
                      <CheckCircleFilled style={{ color: "#2968DC" }} />{" "}
                      Unlimited preference cards
                    </Text>
                    <Text className="mb-3">
                      <CheckCircleFilled style={{ color: "#2968DC" }} /> Renews
                      every month
                    </Text>
                  </div>
                  <div>
                    <Text className="mb-3">
                      <CheckCircleFilled style={{ color: "#2968DC" }} /> Manage
                      Access of seats
                    </Text>
                    <Text className="mb-3">
                      <CheckCircleFilled style={{ color: "#2968DC" }} />{" "}
                      Collaboration Feature
                    </Text>
                  </div>
                </div>
                <Divider />
                <div
                  style={{ color: "#2968DC", cursor: "pointer" }}
                  className="text-end"
                  onClick={() => setIsOpen(true)}
                >
                  Upgrade plan <RiseOutlined />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <UserText className="fw-bold">Credit Balance: </UserText>
                <UserText className="fw-bold">
                  ${subscriptionPlan?.credit_balance}
                </UserText>
              </div>
            </div>
          </div>
          <div className="d-flex mb-4">
            <div style={{ flexBasis: "40%" }}>
              <h5>Card Details</h5>
              <UserText>Select payment method</UserText>
            </div>
            <div
              style={{ flexBasis: "50%" }}
              className="d-flex flex-column gap-3 align-items-end"
            >
              <Button className="w-50" onClick={() => customerPortalHandler()}>
                Manage Payment Methods
              </Button>
            </div>
          </div>
          <div className="d-flex mb-4">
            <div style={{ flexBasis: "40%" }}></div>
            <div
              style={{ flexBasis: "50%" }}
              className="d-flex flex-column gap-3 align-items-end"
            >
              <Button
                danger
                className="w-50"
                onClick={() => setIsCancelModalOpen(true)}
              >
                Cancel Subscription
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionPlan
